import React, { useState, createRef, useEffect, useCallback, useRef, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import HelpIcon from "@mui/icons-material/Help";
import { FormLabelTooltip } from "cms/components/back-office/contentForms/ContentFormStyled";
import { SketchPicker } from "react-color";
import ContentFormContext from "cms/components/back-office/contentForms/ContentFormContext";

const ColorForm = props => {
  const { content, onContentChange, formSubmitted, required, regexValidation, tooltip, onClick, ...others } = props;

  const { id, label, value } = content;

  const { displayVertical } = useContext(ContentFormContext);

  const [displayPicker, setDisplayPicker] = useState(false);

  const labelRef = createRef();
  const pickerRef = createRef();
  const sketchPickerRef = useRef();

  const setPickerPosition = useCallback(() => {
    if (labelRef.current && pickerRef.current) {
      const position = labelRef.current.getBoundingClientRect();
      if (displayVertical) {
        pickerRef.current.style.right = `${window.innerWidth - position.x + 8}px`;
        pickerRef.current.style.top = position.y < 350 ? `${position.y}px` : `${position.y - 280}px`;
      } else {
        pickerRef.current.style.left = `${position.x}px`;
        pickerRef.current.style.bottom = `${window.innerHeight - position.y + 8}px`;
      }
    }
  }, [labelRef, pickerRef, displayVertical]);

  useEffect(() => {
    setPickerPosition();
  }, [setPickerPosition]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", setPickerPosition, true);
    }
    return () => {
      window.removeEventListener("scroll", setPickerPosition);
    };
  }, [setPickerPosition]);

  const handleValidateColorPicker = color => {
    onContentChange({
      ...content,
      value: color.source === "rgb" ? `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` : color.hex
    });
  };

  const handleClickInput = e => {
    if (typeof onClick === "function") {
      onClick(e);
    }
    setDisplayPicker(true);
  };

  const handleInputChange = e => {
    onContentChange({
      ...content,
      value: e.target.value
    });
  };

  const showRequiredError = required && formSubmitted && !value;
  const showRegexValidationError = regexValidation && formSubmitted && value && !value.match(regexValidation);

  useEffect(() => {
    const checkIfClickedOutsideSketchPicker = e => {
      if (displayPicker && sketchPickerRef.current && !sketchPickerRef.current.contains(e.target)) {
        setDisplayPicker(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutsideSketchPicker);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsideSketchPicker);
    };
  }, [displayPicker]);

  return (
    <FormControl key={id} error={showRequiredError || showRegexValidationError}>
      <InputLabel sx={{ pointerEvents: "auto" }} ref={labelRef}>
        {label}
        {tooltip && (
          <FormLabelTooltip title={tooltip}>
            <HelpIcon />
          </FormLabelTooltip>
        )}
      </InputLabel>
      <Input value={value} onChange={handleInputChange} onClick={handleClickInput} {...others} />
      <Box sx={{ position: "fixed", zIndex: "1800" }} ref={pickerRef}>
        {displayPicker && (
          <div ref={sketchPickerRef}>
            <SketchPicker width={250} color={value} onChangeComplete={handleValidateColorPicker} />
          </div>
        )}
      </Box>
      {showRequiredError && <FormHelperText error>Champ obligatoire</FormHelperText>}
      {showRegexValidationError && <FormHelperText error>{label} invalide</FormHelperText>}
    </FormControl>
  );
};

ColorForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
  required: PropTypes.bool,
  regexValidation: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func
};

ColorForm.defaultProps = {
  formSubmitted: false,
  required: false,
  regexValidation: null,
  tooltip: null,
  onClick: null
};

export default ColorForm;

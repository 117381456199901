var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { createPages, createSiteForm } from "cms/services/admin/AdminSitesService";
import { useForm } from "react-hook-form";
var CreateSiteForm = function (_a) {
    var _b, _c;
    var refetchSites = _a.refetchSites;
    var _d = useForm({
        defaultValues: {
            siteName: "Default Site Name",
            host: "Default Host"
        },
        mode: "onSubmit"
    }), register = _d.register, handleSubmit = _d.handleSubmit, errors = _d.formState.errors;
    var onSubmit = function (data) {
        createSiteForm(data.siteName, data.host).then(function (siteResponse) {
            return createPages(siteResponse.data).then(function (pageResponse) {
                if (pageResponse.data) {
                    refetchSites();
                }
            });
        });
    };
    return (_jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsxs(Grid, { container: true, spacing: 2, justifyContent: "center", alignItems: "center", children: [_jsx(Grid, { item: true, xs: 8, children: _jsx(Typography, { variant: "h3", align: "center", sx: { color: "primary.main" }, children: "Cr\u00E9er un site" }) }), _jsx(Grid, { item: true, xs: 8, children: _jsx(TextField, __assign({ fullWidth: true, label: "Nom", variant: "outlined" }, register("siteName", {
                        required: {
                            message: "Nom du site requis",
                            value: true
                        },
                        pattern: {
                            message: "ne peut contenir de caractères spéciaux",
                            value: /^[a-zA-Z ]+$/gi
                        }
                    }), { error: !!errors.siteName, helperText: (_b = errors.siteName) === null || _b === void 0 ? void 0 : _b.message })) }), _jsx(Grid, { item: true, xs: 8, children: _jsx(TextField, __assign({ fullWidth: true, label: "Host", variant: "outlined" }, register("host", {
                        required: {
                            message: "Host requis",
                            value: true
                        },
                        pattern: {
                            message: "ne peut contenir de caractères spéciaux",
                            value: /^[a-zA-Z ]+$/gi
                        }
                    }), { error: !!errors.host, helperText: (_c = errors.host) === null || _c === void 0 ? void 0 : _c.message })) }), _jsx(Grid, { item: true, xs: 8, sx: { mt: 2 }, children: _jsx(Button, { type: "submit", variant: "contained", fullWidth: true, children: "Envoyer" }) })] }) }));
};
export default CreateSiteForm;

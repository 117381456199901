var COOKIE_DISABLE_BANNER = "cookieDisableBanner";
var COOKIE_CONSENT_EXPIRES_NAME = "cookieConsentExpires";
export var COOKIE_CONSENT_GOOGLE_RECAPTCHA_NAME = "cookieConsentGoogleRecaptcha";
var CookiesConsentService = /** @class */ (function () {
    function CookiesConsentService() {
    }
    var _a;
    _a = CookiesConsentService;
    CookiesConsentService.isDisableCookiesBanner = function () {
        var _b, _c, _d;
        if (typeof localStorage !== "undefined") {
            if (((_b = localStorage.getItem(COOKIE_DISABLE_BANNER)) === null || _b === void 0 ? void 0 : _b.toString()) === "true") {
                var expiresDate = new Date(parseInt(((_c = localStorage.getItem(COOKIE_CONSENT_EXPIRES_NAME)) === null || _c === void 0 ? void 0 : _c.toString()) || "0", 10));
                if (expiresDate && Date.now() > expiresDate.getTime()) {
                    localStorage.removeItem(COOKIE_DISABLE_BANNER);
                    localStorage.removeItem(COOKIE_CONSENT_EXPIRES_NAME);
                    return false;
                }
                return true;
            }
            else if (((_d = localStorage.getItem(COOKIE_DISABLE_BANNER)) === null || _d === void 0 ? void 0 : _d.toString()) === "false") {
                return false;
            }
        }
        return false;
    };
    CookiesConsentService.disableCookiesBanner = function () {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(COOKIE_DISABLE_BANNER, "true");
            localStorage.setItem(COOKIE_CONSENT_EXPIRES_NAME, (Date.now() + _a.getConsentMaxAge() * 1000).toString());
        }
    };
    CookiesConsentService.getCookieConsent = function (cookieName) {
        var _b, _c;
        if (typeof localStorage !== "undefined") {
            if (((_b = localStorage.getItem(cookieName)) === null || _b === void 0 ? void 0 : _b.toString()) === "true") {
                return true;
            }
            else if (((_c = localStorage.getItem(cookieName)) === null || _c === void 0 ? void 0 : _c.toString()) === "false") {
                return false;
            }
            return false;
        }
        return null;
    };
    CookiesConsentService.acceptCookieConsent = function (cookieName) {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(cookieName, "true");
        }
    };
    CookiesConsentService.refuseCookieConsent = function (cookieName) {
        if (typeof localStorage !== "undefined") {
            localStorage.setItem(cookieName, "false");
        }
    };
    CookiesConsentService.getConsentMaxAge = function () {
        return 13 * 30 * 24 * 60 * 60; // 13 monthes
    };
    return CookiesConsentService;
}());
export default CookiesConsentService;

import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "enums/contentsCustomTypes.enum";
import ContactTemplate from "templates/contact/ContactTemplate";
export default {
  key: "CONTACT",
  label: "Contact",
  templateImage: "/template-contact.png",
  Component: ContactTemplate,
  initialContents: [
    {
      label: "Sous-titre",
      key: "subtitle",
      type: contentsTypes.EDITABLE_INPUT,
      value: "Des questions ?"
    },
    {
      label: "Lien",
      key: "contactLink",
      type: customTypes.SIMPLE_LINK,
      value: "",
      children: [
        {
          key: "title",
          label: "Titre du lien",
          type: contentsTypes.INPUT,
          value: "Mentions légales"
        }
      ]
    }
  ]
};

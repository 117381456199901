var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
// Theme/Style. ----------------------------------------------------------------
import { useTheme } from "@mui/material/styles";
var PresentationRow = function (props) {
    var id = props.id, title = props.title, description = props.description, image = props.image, dynamicPartAfterDescription = props.dynamicPartAfterDescription, others = __rest(props, ["id", "title", "description", "image", "dynamicPartAfterDescription"]);
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    // Render. -------------------------------------------------------------------
    return (_createElement(Grid, __assign({ container: true, direction: isMobile ? "row" : "row-reverse", justifyContent: "space-around", alignItems: "center", sx: { px: 2 } }, others, { key: id }),
        _jsx(Grid, __assign({ item: true, xs: 8, sm: 6, md: 3 }, { children: image }), void 0),
        _jsxs(Grid, __assign({ item: true, xs: 12, md: 6, sx: { py: 2 } }, { children: [title && (_jsx(Typography, __assign({ variant: "h0", component: "h1", sx: { mb: 2, textAlign: { xs: "center", md: "left" } } }, { children: title }), void 0)), description && (_jsx(Box, __assign({ sx: { mb: 2, color: "greyColor.main", textAlign: { xs: "center", md: "left" } } }, { children: description }), void 0)), _jsx(Grid, __assign({ item: true, xs: 12, sx: { textAlign: { xs: "center", md: "left" } } }, { children: dynamicPartAfterDescription }), void 0)] }), void 0)));
};
export default PresentationRow;

import React from "react";
import PropTypes from "prop-types";

const DynamicTemplate = props => {
  const { page } = props;

  const { contents } = page;

  const { dynamicPart } = contents;

  return <>{dynamicPart}</>;
};

DynamicTemplate.propTypes = {
  page: PropTypes.shape()
};

DynamicTemplate.defaultProps = {
  page: {}
};

export default DynamicTemplate;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CssBaseline from "@mui/material/CssBaseline";
// add class name generator to nanage duplicate jss<nu> by adding prefix to replace jss.
import { createGenerateClassName, StylesProvider } from "@mui/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import axios from "axios";
// @ts-ignore
import clientAdmin from "clientAdmin";
// @ts-ignore
import clientFront from "clientFront";
import { AdminContextProvider } from "cms/components/back-office/contexts/AdminContext";
import { AdminEditorContextProvider } from "cms/components/back-office/contexts/AdminEditorContextProvider";
import { AdminPageChangeContextProvider } from "cms/components/back-office/contexts/AdminPageChangeContextProvider";
import { AdminPageContextProvider } from "cms/components/back-office/contexts/AdminPageContextProvider";
import { AdminPageVersionContextProvider } from "cms/components/back-office/contexts/AdminPageVersionContextProvider";
import { AdminWebsiteContextProvider } from "cms/components/back-office/contexts/AdminWebsiteContextProvider";
import AdminWebsite from "cms/components/back-office/Layout/AdminWebsite";
import LayoutBack from "cms/components/back-office/Layout/LayoutBack";
import LoadingPage from "cms/components/back-office/Layout/LoadingPage";
import NoLayout from "cms/components/back-office/Layout/NoLayout";
import ManageUsersPage from "cms/components/back-office/pages/ManageUsersPage";
import AuthService from "cms/services/gedServices/AuthService";
import FileService from "cms/services/gedServices/FileService";
import FolderService from "cms/services/gedServices/FolderService";
import NodeService from "cms/services/gedServices/NodeService";
import ThemeBackOfficeProvider from "cms/theme/ThemeBackOfficeProvider";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import AuthServiceInterface from "da-ged-web/interfaces/AuthServiceInterface";
import FileServiceInterface from "da-ged-web/interfaces/FileServiceInterface";
import FolderServiceInterface from "da-ged-web/interfaces/FolderServiceInterface";
import NodeServiceInterface from "da-ged-web/interfaces/NodeServiceInterface";
// Since keycloak-js@26, problem with import in eslint
// eslint-disable-next-line import/no-unresolved
// @ts-ignore
import Keycloak from "keycloak-js";
import merge from "lodash.merge";
import ReactDOM from "react-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CookiesContextProvider } from "../shared/cookies/CookiesContext";
var keycloak = new Keycloak("/keycloak.json");
var generateClassName = createGenerateClassName({
    productionPrefix: "bo_cms"
});
clientAdmin();
clientFront();
AuthServiceInterface.setImplementation(AuthService);
NodeServiceInterface.setImplementation(NodeService);
FileServiceInterface.setImplementation(FileService);
FolderServiceInterface.setImplementation(FolderService);
axios.interceptors.request.use(function (config) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, _b;
    return __generator(this, function (_c) {
        if (!((_a = config.url) === null || _a === void 0 ? void 0 : _a.startsWith("/")) && keycloak.authServerUrl && !((_b = config.url) === null || _b === void 0 ? void 0 : _b.includes(keycloak.authServerUrl))) {
            return [2 /*return*/, config];
        }
        config.headers.authorization = "Bearer ".concat(keycloak.token);
        return [2 /*return*/, config];
    });
}); }, function (error) { return Promise.reject(error); });
var hookQueryClientOptions = CmsHooks.getHook(HOOKS.clientAdmin_queryClientOptions) || {};
var queryClientOptions = merge({}, {
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: function (failureCount, error) {
                var status = ((error === null || error === void 0 ? void 0 : error.response) || {}).status;
                if (status && status >= 400 && status < 500) {
                    return false;
                }
                return failureCount < 3;
            }
        }
    },
    queryCache: new QueryCache()
}, hookQueryClientOptions);
var queryClient = new QueryClient(queryClientOptions);
var Bo = function () {
    var projectRoutes = CmsHooks.getHook(HOOKS.Bo_Routes);
    var adminWebsitePath = CmsHooks.getHook(HOOKS.Bo_AdminWebsitePath);
    var adminUsersPath = CmsHooks.getHook(HOOKS.Bo_AdminUsersPath);
    return (_jsx(StylesProvider, { generateClassName: generateClassName, children: _jsx(ReactKeycloakProvider, { authClient: keycloak, initOptions: {
                onLoad: "login-required",
                checkLoginIframe: false,
                pkceMethod: "S256"
            }, LoadingComponent: _jsx(LoadingPage, { title: "Authentification" }), children: _jsx(Router, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(AdminContextProvider, { children: _jsx(ThemeBackOfficeProvider, { children: _jsx(CookiesContextProvider, { children: _jsx(AdminWebsiteContextProvider, { children: _jsx(AdminPageContextProvider, { children: _jsx(AdminPageChangeContextProvider, { children: _jsx(AdminPageVersionContextProvider, { children: _jsxs(AdminEditorContextProvider, { children: [_jsx(CssBaseline, {}), _jsxs(LayoutBack, { children: [_jsx(Route, { path: "/noLayout", children: _jsx(NoLayout, {}) }), _jsx(Route, { path: adminUsersPath, exact: true, children: _jsx(ManageUsersPage, {}) }), _jsx(Route, { path: adminWebsitePath, exact: true, children: _jsx(AdminWebsite, {}) }), projectRoutes] })] }) }) }) }) }) }) }) }) }) }) }) }));
};
export default Bo;
ReactDOM.render(_jsx(Bo, {}), document.getElementById("root"));

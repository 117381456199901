import axios from "axios";

export const get = async (name, id, options) => {
  const { data } = await axios.get(`/api/${name}/${id}`, options);
  return data;
};

export const find = async (name, options = {}) => {
  const { params = {} } = options;
  const { offset } = params;
  const { data } = await axios.get(`/api/${name}`, {
    ...options,
    params: {
      offset: 0,
      ...params
    }
  });
  if (offset !== undefined) {
    // returns pagination infos
    return data;
  }
  // returns data only
  return data.data;
};

export default name =>
  class CRUD {
    static get = (id, options) => get(name, id, options);

    static find = (options, url) => find(name, options, url);

    static create = object => axios.post(`/api/${name}`, object).then(r => r.data);

    static update = object => axios.patch(`/api/${name}/${object.id}`, object).then(r => r.data);

    static delete = object => axios.delete(`/api/${name}/${object.id}`).then(r => r.data);
  };

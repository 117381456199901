import React, { useContext } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ColorForm from "cms/components/back-office/contentForms/ColorForm";
import SelectForm from "cms/components/back-office/contentForms/SelectForm";
import ContentFormContext from "cms/components/back-office/contentForms/ContentFormContext";
import { FormFieldset } from "cms/components/back-office/contentForms/ContentFormStyled";
import IconAutocomplete from "../../shared/autocomplete/select/IconAutocomplete";

const IconForm = props => {
  const { content, onContentChange } = props;

  const { id, children } = content;

  const { displayVertical } = useContext(ContentFormContext);

  const typeChild = children.find(c => c.key === "type");
  const iconChild = children.find(c => c.key === "icon");
  const colorChild = children.find(c => c.key === "color");
  const backgroundColorChild = children.find(c => c.key === "background");

  const handleChildChange = oldChild => newChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === oldChild) {
          return newChild;
        }
        return child;
      })
    });
  };

  return (
    <FormFieldset key={id}>
      <legend>Icône</legend>
      <Grid container spacing={1}>
        <Grid item xs={displayVertical ? 12 : 6}>
          <SelectForm content={typeChild} onContentChange={handleChildChange(typeChild)} />
        </Grid>
        <Grid item xs={displayVertical ? 12 : 6}>
          <IconAutocomplete onContentChange={handleChildChange(iconChild)} content={iconChild} type={typeChild.value} />
        </Grid>
        <Grid item xs={displayVertical ? 12 : 6}>
          <ColorForm content={colorChild} onContentChange={handleChildChange(colorChild)} />
        </Grid>
        <Grid item xs={displayVertical ? 12 : 6}>
          <ColorForm content={backgroundColorChild} onContentChange={handleChildChange(backgroundColorChild)} />
        </Grid>
      </Grid>
    </FormFieldset>
  );
};

IconForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default IconForm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import React from "react";
import { PrincipalArticleCard } from "templates/articles/ArticleCard";
import ArticleSubpage from "templates/articles/ArticleSubpage";
var ArticleHeader = function (props) {
    var articles = props.articles;
    var temp_articles = React.useMemo(function () { return __spreadArray([], articles, true); }, [articles]);
    var firstArticle = React.useMemo(function () { return temp_articles === null || temp_articles === void 0 ? void 0 : temp_articles.shift(); }, [temp_articles]);
    return (_jsxs(_Fragment, { children: [_jsx(PrincipalArticleCard, { page: firstArticle }, void 0), _jsx(Grid, __assign({ item: true, md: 6, container: true, sx: { display: { xs: "none", md: "flex" }, ml: 1.5 } }, { children: _jsx(ArticleSubpage, { articles: temp_articles }, void 0) }), void 0)] }, void 0));
};
export default ArticleHeader;

import contentsTypes from "cms/enums/contentsTypes.enum";
import AgendaPreview from "../components/Agenda/AgendaPreview";
import customTypes from "../enums/contentsCustomTypes.enum";
export var Agenda = {
    label: "Agenda",
    type: customTypes.AGENDA,
    icon: "calendar-day",
    editionModal: true,
    editionModalPosition: "top",
    Preview: AgendaPreview,
    children: [
        {
            label: "Titre",
            key: "title",
            type: contentsTypes.INPUT,
            value: "Événements à venir"
        },
        {
            label: "Lien vers le fichier ICS",
            key: "icsUrl",
            type: contentsTypes.URL,
            value: ""
        },
        {
            label: "Voir tout les événements",
            key: "seeMoreUrl",
            type: contentsTypes.LINK,
            value: ""
        }
    ]
};

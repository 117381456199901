import TimelinePreview from "cms/components/back-office/contentsPreview/TimelinePreview";
import TimelineIcon from "cms/components/shared/icons/TimelineIcon";
import types from "cms/enums/contentsTypes.enum";
export var timeline = {
    label: "Historique",
    type: types.TIMELINE,
    editionModalPosition: "top",
    IconComponent: TimelineIcon,
    Preview: TimelinePreview,
    dynamicChildKey: "steps",
    children: [
        {
            label: "Étape",
            type: types.STEP_V2,
            key: "steps"
        }
    ]
};

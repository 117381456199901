var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
var stepStyled = {
    ml: 1,
    borderLeft: "4px solid",
    borderColor: "secondary.dark",
    position: "relative",
    pl: 3,
    pb: 4
};
var bubbleStyled = {
    position: "absolute",
    left: "-12px",
    top: "-1px",
    display: "block",
    height: "20px",
    width: "20px",
    bgcolor: "secondary.dark",
    color: "white",
    borderRadius: "50%"
};
var TimelineStep = function (props) {
    var id = props.id, _a = props.title, title = _a === void 0 ? null : _a, _b = props.content, content = _b === void 0 ? null : _b;
    return (_jsxs(Box, __assign({ sx: stepStyled }, { children: [_jsx(Box, { component: "span", sx: bubbleStyled }, void 0), _jsx(Box, __assign({ sx: {
                    fontWeight: 600,
                    pb: 0.5
                } }, { children: title }), void 0), content] }), id));
};
export default TimelineStep;

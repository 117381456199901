import React from "react";
import Box from "@mui/material/Box";
import Grid from "cms/components/front-office/contents/Grid";

const getItems = size => {
  return new Array(size).fill("").map((v, i) => (
    <Box key={i} sx={{ border: "1px solid #999", p: 2, textAlign: "center" }}>
      Zone de contenu libre {i + 1}
    </Box>
  ));
};

const GridPreview = () => (
  <div>
    <Box mb={3}>
      <h4>Grille 2 colonnes</h4>
      <Grid size="6" items={getItems(2)} />
    </Box>
    <Box mb={3}>
      <h4>Grille 3 colonnes</h4>
      <Grid size="4" items={getItems(3)} />
    </Box>
    <Box mb={3}>
      <h4>Grille 4 colonnes</h4>
      <Grid size="3" items={getItems(4)} />
    </Box>
    <Box mb={3}>
      <h4>Grille 6 colonnes</h4>
      <Grid size="2" items={getItems(6)} />
    </Box>
    <Box mb={3}>
      <h4>Grille 4 colonnes, 3 éléments alignés au centre</h4>
      <Grid size="3" items={getItems(3)} justifyContent="center" />
    </Box>
    <Box mb={3}>
      <h4>Grille 4 colonnes, 3 éléments alignés à droite</h4>
      <Grid size="3" items={getItems(3)} justifyContent="flex-end" />
    </Box>
  </div>
);

export default GridPreview;

import types from "cms/enums/contentsTypes.enum";
export var link = {
    label: "Lien",
    type: types.LINK,
    children: [
        {
            label: "Url",
            key: "url",
            type: types.URL,
            value: ""
        },
        {
            label: "Page",
            key: "page",
            type: types.PAGE,
            value: ""
        },
        {
            key: "title",
            label: "Titre du lien",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Ouvrir dans un nouvel onglet",
            key: "external",
            type: types.CHECKBOX,
            value: "false"
        }
    ]
};

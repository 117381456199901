var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import Icon from "cms/components/front-office/contents/Icon";
var iconStyled = {
    p: 2,
    bgcolor: "primary.main",
    color: "primary.main",
    fontSize: "1.5rem",
    alignItems: "center",
    display: { xs: "none", md: "flex" }
};
var InfoBlock = function (props) {
    var _a = props.icon, icon = _a === void 0 ? null : _a, _b = props.text, text = _b === void 0 ? null : _b;
    return (_jsxs(Grid, __assign({ container: true }, { children: [icon && (_jsx(Grid, __assign({ item: true, sx: iconStyled }, { children: _jsx(Icon, __assign({}, icon, { title: "Information" }), void 0) }), void 0)), _jsx(Grid, __assign({ item: true, xs: true, sx: {
                    bgcolor: "#fff",
                    p: { md: 4 }
                } }, { children: text }), void 0)] }), void 0));
};
export default InfoBlock;

export var PageRole;
(function (PageRole) {
    PageRole["CREATE_PAGE"] = "create_page";
    PageRole["READ_PAGE"] = "read_page";
    PageRole["UPDATE_PAGE"] = "update_page";
    PageRole["DELETE_PAGE"] = "delete_page";
})(PageRole || (PageRole = {}));
export var PageVersionRole;
(function (PageVersionRole) {
    PageVersionRole["CREATE_PAGE_VERSION"] = "create_page_version";
    PageVersionRole["READ_PAGE_VERSION"] = "read_page_version";
    PageVersionRole["UPDATE_PAGE_VERSION"] = "update_page_version";
    PageVersionRole["UPDATE_PAGE_VERSION_STATUS"] = "update_page_version_status";
    PageVersionRole["DELETE_PAGE_VERSION"] = "delete_page_version";
    PageVersionRole["TECH_PAGE_VERSION"] = "tech_page_version";
})(PageVersionRole || (PageVersionRole = {}));
export var SectionRole;
(function (SectionRole) {
    SectionRole["CREATE_SECTION"] = "create_section";
    SectionRole["READ_SECTION"] = "read_section";
    SectionRole["UPDATE_SECTION"] = "update_section";
    SectionRole["DELETE_SECTION"] = "delete_section";
})(SectionRole || (SectionRole = {}));
export var SectionVersionRole;
(function (SectionVersionRole) {
    SectionVersionRole["CREATE_SECTION_VERSION"] = "create_section_version";
    SectionVersionRole["READ_SECTION_VERSION"] = "read_section_version";
    SectionVersionRole["UPDATE_SECTION_VERSION"] = "update_section_version";
    SectionVersionRole["UPDATE_SECTION_VERSION_STATUS"] = "update_section_version_status";
    SectionVersionRole["DELETE_SECTION_VERSION"] = "delete_section_version";
})(SectionVersionRole || (SectionVersionRole = {}));
export var SiteRole;
(function (SiteRole) {
    SiteRole["CREATE_SITE"] = "create_site";
    SiteRole["READ_SITE"] = "read_site";
    SiteRole["UPDATE_SITE"] = "update_site";
    SiteRole["DELETE_SITE"] = "delete_site";
    SiteRole["IMPORT_SITE"] = "import_site";
    SiteRole["EXPORT_SITE"] = "export_site";
    SiteRole["DUPLICATE_SITE"] = "duplicate_site";
})(SiteRole || (SiteRole = {}));

import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "../enums/contentsCustomTypes.enum";
export var slider = {
    label: "Slider",
    type: customTypes.SLIDER2,
    icon: "presentation",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "première diaporama",
            key: "slider1",
            type: contentsTypes.PAGE,
            value: ""
        },
        {
            label: "deuxième diaporama",
            key: "slider2",
            type: contentsTypes.PAGE,
            value: ""
        },
        {
            label: "troisième diaporama",
            key: "slider3",
            type: contentsTypes.PAGE,
            value: ""
        }
    ]
};

import types from "cms/enums/contentsTypes.enum";
import videosOrigins from "cms/enums/videosOrigins";
export var video = {
    type: types.VIDEO,
    editionModal: true,
    editionModalPosition: "top",
    label: "Vidéo",
    icon: "play-circle",
    requiredByKey: ["src"],
    children: [
        {
            key: "source",
            type: types.SELECT,
            value: videosOrigins.YOUTUBE,
            label: "Source",
            options: [
                { label: "Dailymotion", value: videosOrigins.DAILYMOTION },
                { label: "Youtube", value: videosOrigins.YOUTUBE },
                { label: "URL", value: videosOrigins.URL }
            ]
        },
        {
            label: "Url",
            type: types.URL,
            key: "src",
            value: ""
        }
    ]
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import AdminContext from "cms/components/back-office/contexts/AdminContext";
import UserForm from "cms/components/back-office/UserForm";
import useEnvironment from "cms/hooks/useEnvironment";
import UserService from "cms/services/admin/UserService";
import React from "react";
var CreateUser = function (props) {
    var _a = props.groups, groups = _a === void 0 ? [] : _a, _b = props.rolesByGroup, rolesByGroup = _b === void 0 ? {} : _b, onValidate = props.onValidate, onCancel = props.onCancel;
    var _c = React.useContext(AdminContext), displaySuccess = _c.displaySuccess, displayError = _c.displayError;
    var environment = useEnvironment();
    var handleValidateUser = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var id, userGroups, body;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = values.id, userGroups = values.groups, body = __rest(values, ["id", "groups"]);
                    return [4 /*yield*/, UserService.createUser(body)
                            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        displaySuccess("Utilisateur a été créé");
                                        return [4 /*yield*/, UserService.getUsers({
                                                params: {
                                                    username: values.username,
                                                    exact: true,
                                                    max: 1
                                                }
                                            }).then(function (user) { return __awaiter(void 0, void 0, void 0, function () {
                                                var userId, addGroupsPromises_1, backOfficeUrl;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            userId = user[0] && user[0].id;
                                                            if (!Array.isArray(userGroups)) return [3 /*break*/, 2];
                                                            addGroupsPromises_1 = [];
                                                            userGroups.forEach(function (group) {
                                                                addGroupsPromises_1.push(UserService.addUserGroup(userId, group.id).catch(function () {
                                                                    return displayError("Erreur lors d'assignation d'un groupe");
                                                                }));
                                                            });
                                                            return [4 /*yield*/, Promise.all(addGroupsPromises_1)];
                                                        case 1:
                                                            _a.sent();
                                                            _a.label = 2;
                                                        case 2:
                                                            backOfficeUrl = window.location.origin;
                                                            UserService.sendResetPasswordEmail(userId, {
                                                                params: {
                                                                    client_id: environment === null || environment === void 0 ? void 0 : environment.keycloak_bo_client_id,
                                                                    redirect_uri: backOfficeUrl
                                                                }
                                                            }).catch(function () { return displayError("Erreur lors de l'envoi du mail de mise à jour du mot de passe"); });
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })];
                                    case 1:
                                        _a.sent();
                                        onValidate();
                                        return [2 /*return*/];
                                }
                            });
                        }); })
                            .catch(function () { return displayError("Erreur lors de la création de l'utilisateur"); })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: _jsx(UserForm, { initialFormValues: {
                id: "",
                username: "",
                lastName: "",
                firstName: "",
                email: "",
                enabled: true,
                groups: []
            }, groups: groups, rolesByGroup: rolesByGroup, onSubmit: handleValidateUser, onValidate: onValidate, onCancel: onCancel }, void 0) }, void 0));
};
export default CreateUser;

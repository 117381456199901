import React, { Fragment } from "react";
import PropTypes from "prop-types";
import contentsTypes from "cms/enums/contentsTypes.enum";
import AdminListForm from "cms/components/back-office/contentForms/AdminListForm";
import InputForm from "cms/components/back-office/contentForms/InputForm";
import { FormTitle } from "cms/components/back-office/contentForms/ContentFormStyled";
import ContentFormContext from "cms/components/back-office/contentForms/ContentFormContext";

const TabsForm = props => {
  const { content, onContentChange } = props;
  const { label, children, type } = content;
  const tabsTypes = [contentsTypes.TAB, contentsTypes.TAB_CARDS];

  const objectsChildren = children.filter(c => tabsTypes.includes(c.type)) || [];

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map(c => (c === oldChild ? newChild : c))
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: newChildren
    });
  };

  return (
    <Fragment>
      <FormTitle>{label}</FormTitle>
      <ContentFormContext.Provider value={{ displayVertical: false }}>
        <AdminListForm
          inline
          contents={objectsChildren}
          elementsType={type === contentsTypes.TABS ? contentsTypes.TAB : contentsTypes.TAB_CARDS}
          elementsKey="tabs"
          onContentsChange={newContents => handleChildrenChange(objectsChildren, newContents)}
        >
          {objectsChildren.map(currentObject => (
            <InputForm
              key={currentObject.id}
              content={{
                ...currentObject.children.find(c => c.key === "title")
              }}
              onContentChange={newContent =>
                handleChildChange(currentObject, {
                  ...currentObject,
                  children: (currentObject.children || []).map(c => (c.key === "title" ? newContent : c))
                })
              }
            />
          ))}
        </AdminListForm>
      </ContentFormContext.Provider>
    </Fragment>
  );
};

TabsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default TabsForm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Footer from "projects/EVIDENCEB/components/Footer/Footer";
import Header from "projects/EVIDENCEB/components/Header/Header";
import React from "react";
import ZoomContext from "./ZoomContext";
var Layout = function (props) {
    var children = props.children;
    var _a = React.useState(1), zoom = _a[0], setZoom = _a[1];
    var handleZoom = function () {
        var defaultFontSize = 16;
        var fontSize = parseFloat(getComputedStyle(document.body).fontSize);
        var newZoom = fontSize / defaultFontSize;
        setZoom(newZoom);
    };
    React.useEffect(function () {
        handleZoom();
        window.addEventListener("wheel", handleZoom);
    }, []);
    var _b = React.useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var contentLinkRef = React.useRef(null);
    var contentRef = React.useRef(null);
    var footerLinkRef = React.useRef(null);
    var footerRef = React.useRef(null);
    var focusComponent = function (linkRef, componentRef) {
        var _a, _b, _c;
        (_a = linkRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        (_b = componentRef.current) === null || _b === void 0 ? void 0 : _b.setAttribute("tabIndex", -1);
        (_c = componentRef.current) === null || _c === void 0 ? void 0 : _c.focus();
    };
    return (_jsxs(ZoomContext.Provider, __assign({ value: { zoom: zoom } }, { children: [_jsxs(Container, __assign({ maxWidth: "lg" }, { children: [_jsxs(Box, __assign({ sx: {
                            height: "60px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "20px",
                            transform: isVisible ? "none" : "translateY(-100%)",
                            transition: "transform 0.3s ease",
                            mt: isVisible ? "0px" : "-60px"
                        }, onFocus: function () { return setIsVisible(true); } }, { children: [_jsxs(Link, __assign({ component: "button", variant: "body1", ref: contentLinkRef, onClick: function () { return focusComponent(contentLinkRef, contentRef); }, onBlur: function () { return setIsVisible(false); } }, { children: [" ", "Contenu", " "] }), void 0), _jsx(Link, __assign({ component: "button", variant: "body1", ref: footerLinkRef, onClick: function () { return focusComponent(footerLinkRef, footerRef); }, onBlur: function () { return setIsVisible(false); } }, { children: "Pied de page" }), void 0)] }), void 0), _jsx(Header, {}, void 0), _jsx(Box, __assign({ sx: { mt: { xs: 9.625, lg: 0 } }, ref: contentRef }, { children: children }), void 0)] }), void 0), _jsx(Box, __assign({ ref: footerRef }, { children: _jsx(Footer, {}, void 0) }), void 0)] }), void 0));
};
export default Layout;

import axios from "axios";
import generateCRUD from "cms/services/admin/generateCRUD";
import { generateContentId, adminProperties, replacePagesByIds } from "cms/utils/adminContentsUtils";
import { removeProperties } from "cms/utils/commonUtils";
import FileService from "cms/services/gedServices/FileService";
import contentsTypes from "cms/enums/contentsTypes.enum";

export const pageVersionImageKey = "versionImage";

const name = "page-versions";

const PageVersionsCRUD = generateCRUD(name);

const prepareVersion = (version, updatedIds = {}) => {
  const { contents = [], description, path, shortDescription, template, title, image, tags } = version;
  const cleanContents = contents.map(c => removeProperties(c, adminProperties));
  const contentsWithPagesIds = cleanContents.map(content => replacePagesByIds(content, updatedIds));
  const contentsWithoutVersionImage = contentsWithPagesIds.filter(content => content.key !== pageVersionImageKey);
  if (image) {
    contentsWithoutVersionImage.push(
      generateContentId({
        key: pageVersionImageKey,
        value: JSON.stringify({
          url: image.url,
          id: image.id,
          name: image.name,
          mimeType: image.mimeType,
          size: image.size,
          publicUrl: image.publicUrl,
          publicUrlBySize: image.publicUrlBySize
        }),
        type: contentsTypes.FILE_IMAGE
      })
    );
  }

  return {
    contents: contentsWithoutVersionImage,
    description,
    path,
    shortDescription,
    template,
    title,
    image: image && {
      url: image.url,
      alt: image.alt,
      title: image.title
    },
    tags
  };
};

export default class AdminPageVersionsService extends PageVersionsCRUD {
  static getPageVersions = pageId => axios.get("/getPageVersions", { params: { pageId } }).then(r => r.data);

  static publishImages = async version => {
    const { contents, image } = version;

    const publishingPromises = [];
    const newVersion = version;

    const imageContent = contents.find(content => content.key === pageVersionImageKey);
    if (imageContent) {
      const imageFile = JSON.parse(imageContent.value);
      if (imageFile.id) {
        const versionImagePromise = FileService.publish({ file: imageFile, pageVersion: version }).then(response => {
          const { data: publishedImage } = response;
          newVersion.image = {
            ...image,
            ...publishedImage,
            url: publishedImage.publicUrl
          };
        });
        publishingPromises.push(versionImagePromise);
      }
    }
    const publishContentFiles = content => {
      const { type, children, value, key } = content;
      try {
        if ([contentsTypes.FILE, contentsTypes.FILE_IMAGE].includes(type) && key !== pageVersionImageKey && value) {
          const file = JSON.parse(value);
          if (file.id) {
            const contentFilePromise = FileService.publish({ file, pageVersion: version }).then(response => {
              const { data: publishedFile } = response;
              // eslint-disable-next-line no-param-reassign
              content.value = JSON.stringify({
                ...file,
                ...publishedFile,
                url: publishedFile.publicUrl
              });
              return publishedFile;
            });
            publishingPromises.push(contentFilePromise);
          }
        } else if (children && Array.isArray(children)) {
          children.forEach(publishContentFiles);
        }
      } catch (e) {
        //
      }
    };
    newVersion.contents.forEach(publishContentFiles);

    return Promise.all(publishingPromises).then(responses => {
      if (responses.length) {
        return AdminPageVersionsService.update(newVersion, false);
      }
      return version;
    });
  };

  static create = (version, publishImages = true) => {
    const { contents = [], page } = version;
    const updatedIds = {};
    const contentsWithIds = contents.map(c => generateContentId(c, updatedIds));

    const cleanVersion = prepareVersion({ ...version, contents: contentsWithIds }, updatedIds);

    return PageVersionsCRUD.create({ ...cleanVersion, pageId: page.id }).then(v => {
      if (publishImages) {
        return AdminPageVersionsService.publishImages(v);
      }
      return v;
    });
  };

  static update = version => {
    const { id, updated, versionName } = version;

    const cleanVersion = prepareVersion(version);

    return PageVersionsCRUD.update({
      ...cleanVersion,
      id,
      updated,
      versionName
    });
  };

  static updateStatus = (pageVersion, status) =>
    axios.patch(`/api/${name}/${pageVersion.id}/status`, { updated: pageVersion.updated, status }).then(r => r.data);
}

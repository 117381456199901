import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "enums/contentsCustomTypes.enum";
import ArticleDetailTemplate from "templates/article-detail/ArticleDetailTemplate";

export default {
  key: "ARTICLE_DETAIL",
  label: "Article",
  templateImage: "/template-article.png",
  useTags: true,
  Component: ArticleDetailTemplate,
  initialContents: [
    {
      key: "dynamicPart",
      type: contentsTypes.DYNAMIC,
      value: ""
    },
    {
      key: "subpageSearch",
      type: customTypes.SUBPAGE_SEARCH,
      value: "",
      children: [
        {
          key: "title",
          type: contentsTypes.INPUT,
          value: "Articles récents"
        },
        {
          key: "perPage",
          type: contentsTypes.INPUT_NUMBER,
          value: "2"
        }
      ]
    }
  ]
};

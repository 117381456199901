import React from "react";
import PropTypes from "prop-types";
import SubpageForm from "cms/components/back-office/contentForms/SubpageForm";
import contentsTypes from "cms/enums/contentsTypes.enum";

const SubpageDynamicForm = props => {
  const { content, onContentChange } = props;
  const { children } = content;

  const subPageChild = children.find(c => c.type === contentsTypes.PAGE);

  const { id } = content;

  const handleSubPageChange = newSubpageChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === subPageChild) {
          return newSubpageChild;
        }
        return child;
      })
    });
  };

  return (
    <div key={id}>
      <SubpageForm content={subPageChild} onContentChange={handleSubPageChange} />
    </div>
  );
};

SubpageDynamicForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default SubpageDynamicForm;

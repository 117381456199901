import React from "react";
import { useQueryClient } from "react-query";
import { useKeycloak } from "@react-keycloak/web";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { Box, Popover } from "@mui/material";
import AdminContentModal from "cms/components/back-office/admin/AdminContentModal";
import AdminContext from "cms/components/back-office/contexts/AdminContext";
import AdminWebsiteContext from "cms/components/back-office/contexts/AdminWebsiteContext";
import AdminFooterForm from "cms/components/back-office/contentForms/AdminFooterForm";
import AdminMenuForm from "cms/components/back-office/contentForms/AdminMenu/AdminMenuForm";
import AdminPageForm from "cms/components/back-office/AdminPageForm";
import AdminSection from "cms/components/back-office/AdminSection";
import AvailableContentsPreview from "cms/components/back-office/contentsPreview/AvailableContentsPreview";
import GEDModal from "cms/components/back-office/GEDModal";
import Modal from "cms/components/back-office/Modal";
import ModalConfirm from "cms/components/back-office/ModalConfirm";
import PagesTable from "cms/components/back-office/PagesTable";
import AdminPagesService from "cms/services/admin/AdminPagesService";
import AdminPageVersionsService from "cms/services/admin/AdminPageVersionsService";
import AdminSitesService from "cms/services/admin/AdminSitesService";
import { SideBarContext } from "cms/components/back-office/Layout/SideBar";
import Icon from "cms/components/front-office/contents/Icon";
import { PageVersionStatus } from "cms/types/cms/page/version/page-version.status.enum";
import { removeProperties } from "cms/utils/commonUtils";
import { adminProperties } from "cms/utils/adminContentsUtils";
import sectionsTypes from "cms/enums/sectionsTypes";
import useEnvironment from "cms/hooks/useEnvironment";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import AdminPageContext from "cms/components/back-office/contexts/AdminPageContext";
import AdminPageVersionContext from "cms/components/back-office/contexts/AdminPageVersionContext";
import { getQueryParams } from "cms/utils/urlUtils";
import AdminPageChangeContext from "cms/components/back-office/contexts/AdminPageChangeContext";
import { queryParamPageKey } from "cms/utils/queryParamsKeys";
import ImportExportModal from "cms/components/back-office/ImportExportModal";
import DuplicateSiteModal from "cms/components/back-office/DuplicateSiteModal";
import { PageRole, PageVersionRole, SiteRole } from "cms/types/cms/common/roles.enum";
import dayjs from "dayjs";
import AdminVersionsPrincipal from "./AdminVersionsPrincipal";

export const SiteGroup = {
  name: "site",
  order: 2
};

export const PageGroup = {
  name: "page",
  order: 3
};

export const MediaGroup = {
  name: "media",
  order: 4
};

export const ContentsGroup = {
  name: "contents",
  order: 5
};

export const DataGroup = {
  name: "data",
  order: 6
};

const defaultModal = {
  content: null,
  size: null,
  title: null
};

const AdminWebsite = () => {
  const [pageVersion, setPageVersion] = React.useState();

  const [modal, setModal] = React.useState(defaultModal);
  const [adminContent, setAdminContent] = React.useState(null);
  const [selectSiteAnchor, setSelectSiteAnchor] = React.useState(null);

  const { allSites, currentSite, setCurrentSite, refreshSite } = React.useContext(AdminWebsiteContext);
  const { setPageChanges } = React.useContext(AdminPageChangeContext);
  const { currentPage, setCurrentPage, goToHomePage } = React.useContext(AdminPageContext);
  const {
    currentPageVersion,
    setCurrentPageVersion: setCurrentPageVersionContext,
    refreshPageVersions
  } = React.useContext(AdminPageVersionContext);

  const queryClient = useQueryClient();

  const currentSiteTheme = React.useMemo(() => {
    const projectTheme = CmsHooks.getHook(HOOKS.THEME);
    const getTheme = CmsHooks.getHook(HOOKS.GET_THEME);
    return (typeof getTheme === "function" && getTheme({ site: currentSite })) || projectTheme || createTheme();
  }, [currentSite]);

  const queryParams = getQueryParams();
  const { [queryParamPageKey]: queryParamPage } = queryParams;

  React.useEffect(() => {
    if (currentPageVersion) {
      setPageChanges([]);
    }

    if (!queryParamPage) {
      goToHomePage();
    }
  }, [currentPageVersion, currentSite, goToHomePage, queryParamPage, setPageChanges]);

  const excludedTemplatesForUpdate = React.useMemo(
    () => CmsHooks.getHook(HOOKS.TEMPLATES_excludedTemplatesForPageUpdate) || [],
    []
  );

  const lockedTemplate = React.useMemo(
    () => pageVersion && Boolean(excludedTemplatesForUpdate.find(template => template.key === pageVersion?.template)),
    [excludedTemplatesForUpdate, pageVersion]
  );

  const environment = useEnvironment();

  const { keycloak } = useKeycloak();

  const canUpdateSite = keycloak.hasResourceRole(SiteRole.UPDATE_SITE, environment?.keycloak_client_cms);
  const canCreatePage =
    keycloak.hasResourceRole(PageRole.CREATE_PAGE, environment?.keycloak_client_cms) &&
    keycloak.hasResourceRole(PageVersionRole.CREATE_PAGE_VERSION, environment?.keycloak_client_cms);

  const canUpdateVersion =
    keycloak.hasResourceRole(PageVersionRole.UPDATE_PAGE_VERSION, environment?.keycloak_client_cms) && !lockedTemplate;

  const canImportSite = keycloak.hasResourceRole(SiteRole.IMPORT_SITE, environment?.keycloak_client_cms);
  const canExportSite = keycloak.hasResourceRole(SiteRole.EXPORT_SITE, environment?.keycloak_client_cms);
  const canDuplicateSite = keycloak.hasResourceRole(SiteRole.DUPLICATE_SITE, environment?.keycloak_client_cms);

  const canAccessToGed = keycloak.hasResourceRole("ged_reader", environment?.keycloak_client_ged);

  const closeModal = React.useCallback(() => {
    setModal(defaultModal);
  }, []);

  const closeAdminContentModal = React.useCallback(() => {
    setAdminContent(null);
  }, []);

  const { displaySuccess, displayError } = React.useContext(AdminContext);

  const {
    addItems: addSideBarItems,
    removeItems: removeSideBarItems,
    addGroups: addSideBarGroups,
    removeGroups: removeSideBarGroups,
    setExpanded: setSidebarExpanded
  } = React.useContext(SideBarContext);

  React.useEffect(() => {
    const groups = [SiteGroup, PageGroup, MediaGroup, ContentsGroup, DataGroup];
    addSideBarGroups(groups);
    return () => {
      removeSideBarGroups(groups);
    };
  }, [addSideBarGroups, removeSideBarGroups]);

  React.useEffect(() => {
    setPageVersion(currentPageVersion);
  }, [currentPageVersion]);

  const pageRef = React.useRef(null);

  React.useEffect(() => {
    if (pageRef && pageRef.current) pageRef.current.scrollTop = 0;
  }, [currentPage]);

  const resetUpdatedDateOfContents = React.useCallback((contents, updatedAt) => {
    if (!contents) return contents;
    return contents.map(content => ({
      ...content,
      updatedAt,
      children: resetUpdatedDateOfContents(content.children, updatedAt)
    }));
  }, []);

  const setCurrentPageVersion = React.useCallback(
    (version = {}) =>
      refreshPageVersions().then(versions => {
        const newVersion = versions.find(v => v.id === version.id);
        newVersion.contents = resetUpdatedDateOfContents(newVersion.contents, dayjs());
        setCurrentPageVersionContext(newVersion);
      }),
    [refreshPageVersions, resetUpdatedDateOfContents, setCurrentPageVersionContext]
  );

  const handleSaveVersion = React.useCallback(
    p => {
      const promises = [];
      promises.push(AdminPagesService.update(p.page));
      if (p.status === PageVersionStatus.DRAFT) {
        promises.push(AdminPageVersionsService.update(p));

        return Promise.all(promises)
          .then(responses => {
            const [, version] = responses;
            return version;
          })
          .catch(() => {
            setCurrentPageVersion(p);
            displayError("Erreur lors de la mise à jour du brouillon");
          });
      }
      promises.push(AdminPageVersionsService.create(p));
      return Promise.all(promises)
        .then(responses => {
          const [, version] = responses;
          return version;
        })
        .catch(() => {
          setCurrentPageVersion(currentPageVersion);
          displayError("Erreur lors de la création du brouillon");
        });
    },
    [displayError, currentPageVersion, setCurrentPageVersion]
  );

  const updateSite = React.useCallback(
    settings => {
      const { children } = settings || {};
      const titleChild = children.find(c => c.key === "title") || {};
      const newSettings = { ...settings, children: settings.children.filter(c => c.key !== "title") };
      AdminSitesService.update({
        id: currentSite.id,
        title: titleChild.value,
        contents: currentSite.contents.map(c =>
          c.key === "settings" ? removeProperties(newSettings, adminProperties) : c
        )
      })
        .then(() => {
          refreshSite();
          closeModal();
          displaySuccess("Le site a été mis à jour.");
        })
        .catch(() => {
          closeModal();
          displayError("Une erreur est survenue.");
        });
    },
    [closeModal, currentSite.contents, currentSite.id, displayError, displaySuccess, refreshSite]
  );

  const handleSiteSettingsChange = React.useCallback(
    settings => {
      setModal({
        content: (
          <ModalConfirm
            title={`Paramétres du site : ${currentSite.name}`}
            text="Attention, cette action sera effective immédiatement sur le site"
            confirmText="Mettre à jour le site"
            onConfirm={() => {
              updateSite(settings);
            }}
            onCancel={() => {
              closeModal();
              // eslint-disable-next-line no-use-before-define
              handleClickSiteSettings();
            }}
          />
        ),
        size: "sm"
      });
    },
    // eslint-disable-next-line no-use-before-define
    [closeModal, currentSite.name, handleClickSiteSettings, updateSite]
  );

  const handleValidateAdminContentModal = React.useCallback(
    settings => {
      closeAdminContentModal();
      handleSiteSettingsChange(settings);
    },
    [closeAdminContentModal, handleSiteSettingsChange]
  );

  const handleClickSiteSettings = React.useCallback(() => {
    const { title, contents = [] } = currentSite;
    const siteSettings = (contents && contents.find(c => c.key === "settings")) || {};
    setAdminContent({
      ...siteSettings,
      children: [...(siteSettings.children || []), { key: "title", value: title }]
    });
  }, [currentSite]);

  const handleClickPages = React.useCallback(() => {
    setModal({
      content: (
        <div style={{ height: "65vh", overflow: "hidden" }}>
          <PagesTable
            onPageClick={(event, page) => {
              setCurrentPage(page);
              closeModal();
            }}
          />
        </div>
      ),
      size: "md",
      title: "Les pages du site"
    });
  }, [closeModal, setCurrentPage]);

  const handleClickAdminMenu = React.useCallback(() => {
    setModal({
      content: (
        <AdminSection
          title="Gestion du menu"
          type={sectionsTypes.MENU}
          onSectionChanged={response => {
            if (response?.sectionId) {
              queryClient.setQueryData(["menu", { siteId: currentSite.id }], response);
            }
          }}
        >
          {({ section, onSectionChange }) => (
            <AdminMenuForm
              menu={section}
              maxDepth={1}
              currentDepth={0}
              onMenuChange={onSectionChange}
              canUpdate={canUpdateVersion}
              itemKey="menus"
              subItemKey="subMenus"
            />
          )}
        </AdminSection>
      ),
      size: "md"
    });
  }, [canUpdateVersion, currentSite.id, queryClient]);

  const handleClickAdminFooter = React.useCallback(() => {
    setModal({
      content: (
        <AdminSection
          title="Gestion du footer"
          type={sectionsTypes.FOOTER}
          onSectionChanged={response => {
            if (response?.sectionId) {
              queryClient.setQueryData(["footer", { siteId: currentSite.id }], response);
            }
          }}
        >
          {({ section, onSectionChange }) => (
            <AdminFooterForm footer={section} onFooterChange={onSectionChange} canUpdate={canUpdateVersion} />
          )}
        </AdminSection>
      ),
      size: "lg"
    });
  }, [canUpdateVersion, currentSite.id, queryClient]);

  const createPage = React.useCallback(
    async (version, site) => {
      const { page } = version;
      const { name, parentId } = page;

      const newPage = {
        name,
        parentId,
        siteId: site?.id || currentSite.id
      };

      let createdPage = null;
      try {
        createdPage = await AdminPagesService.create(newPage);

        const newPageVersion = {
          ...version,
          page: createdPage
        };
        try {
          await AdminPageVersionsService.create(newPageVersion);
          closeModal();
        } catch (e) {
          // Rollback page
          AdminPagesService.delete(createdPage);
          throw e;
        }
      } catch (e) {
        createdPage = null;
        console.error(e);
      }
      if (createdPage) {
        if (site) {
          setCurrentSite(site, createdPage);
        } else {
          setCurrentPage(createdPage);
        }
      }
    },
    [closeModal, currentSite.id, setCurrentPage, setCurrentSite]
  );

  const handleClickAddPage = React.useCallback(() => {
    setModal({
      content: <AdminPageForm onValidate={createPage} onCancel={closeModal} />,
      size: "md",
      title: "Créer une nouvelle page"
    });
  }, [closeModal, createPage]);

  const handleClickDuplicatePage = React.useCallback(() => {
    setModal({
      content: <AdminPageForm version={pageVersion} onValidate={createPage} onCancel={closeModal} isDuplicateMod />,
      size: "md",
      title: "Dupliquer la page"
    });
  }, [pageVersion, closeModal, createPage]);

  const handleClickAdminPage = React.useCallback(() => {
    setModal({
      content: (
        <AdminPageForm
          version={pageVersion}
          onValidate={p => {
            handleSaveVersion(p).then(setCurrentPageVersion);
            closeModal();
          }}
          onCancel={closeModal}
        />
      ),
      size: "md",
      title: "Mettre à jour la page"
    });
  }, [pageVersion, closeModal, handleSaveVersion, setCurrentPageVersion]);

  const handleClickMedias = React.useCallback(() => {
    setModal({
      content: <GEDModal onCancel={closeModal} />,
      size: "lg"
    });
  }, [closeModal]);

  const handleClickPreviewContents = React.useCallback(() => {
    setModal({
      content: (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={currentSiteTheme}>
            <AvailableContentsPreview />
          </ThemeProvider>
        </StyledEngineProvider>
      ),
      size: "md"
    });
  }, [currentSiteTheme]);

  const handleClickImportExport = React.useCallback(() => {
    setModal({
      content: <ImportExportModal onCancel={closeModal} />,
      size: "md",
      title: "Importer/Exporter un site"
    });
  }, [closeModal]);

  const handleClickDuplicateSite = React.useCallback(() => {
    setModal({
      content: <DuplicateSiteModal onCancel={closeModal} />,
      size: "md",
      title: "Dupliquer un site"
    });
  }, [closeModal]);

  const handleClickChangeSite = React.useCallback(e => {
    setSelectSiteAnchor(e.currentTarget);
  }, []);

  const handleClickSelectSite = React.useCallback(
    site => e => {
      e.stopPropagation();
      setCurrentSite(site);
      setSidebarExpanded(false);
      setSelectSiteAnchor(null);
    },
    [setCurrentSite, setSidebarExpanded]
  );

  React.useEffect(() => {
    const sideBarItems = [
      {
        icon: <Icon icon="compass" />,
        label: "Pages du site",
        title: "Pages du site",
        onClick: handleClickPages,
        group: SiteGroup.name,
        order: 3
      },
      {
        icon: <Icon icon="bars" />,
        label: "Gérer le menu du site",
        title: "Gérer le menu du site",
        onClick: handleClickAdminMenu,
        group: SiteGroup.name,
        order: 4
      },
      {
        icon: <Icon icon="line-columns" />,
        label: "Gérer le footer du site",
        title: "Gérer le footer du site",
        onClick: handleClickAdminFooter,
        group: SiteGroup.name,
        order: 5
      },
      {
        icon: <Icon icon="layer-group" />,
        label: "Visualiser les composants",
        title: "Visualiser les composants",
        onClick: handleClickPreviewContents,
        group: ContentsGroup.name,
        order: 1
      }
    ];

    if (canUpdateSite) {
      sideBarItems.push({
        icon: <Icon icon="cog" />,
        label: "Paramètres du site",
        title: "Paramètres du site",
        onClick: handleClickSiteSettings,
        group: SiteGroup.name,
        order: 2
      });
    }
    if (canImportSite || canExportSite) {
      sideBarItems.push({
        icon: <Icon icon="file-import" />,
        label: "Importer/Exporter un site",
        title: "Importer/Exporter un site",
        onClick: handleClickImportExport,
        group: DataGroup.name,
        order: 1
      });
    }
    if (canDuplicateSite) {
      sideBarItems.push({
        icon: <Icon icon="fa-regular fa-clone" />,
        label: "Dupliquer un site",
        title: "Dupliquer un site",
        onClick: handleClickDuplicateSite,
        group: DataGroup.name,
        order: 2
      });
    }
    if (canCreatePage) {
      sideBarItems.push({
        icon: <Icon icon="file-plus" />,
        label: "Créer une nouvelle page",
        title: "Créer une nouvelle page",
        onClick: handleClickAddPage,
        group: PageGroup.name,
        order: 1
      });
      sideBarItems.push({
        icon: <Icon icon="fa-regular fa-copy" />,
        label: "Dupliquer la page",
        title: "Dupliquer la page",
        onClick: handleClickDuplicatePage,
        group: PageGroup.name,
        order: 3
      });
    }

    if (canUpdateVersion) {
      sideBarItems.push({
        icon: <Icon icon="file-alt" />,
        label: "Paramètres de la page",
        title: "Paramètres de la page",
        onClick: handleClickAdminPage,
        group: PageGroup.name,
        order: 2
      });
    }

    if (canAccessToGed) {
      sideBarItems.push({
        icon: <Icon icon="photo-video" />,
        label: "Médiathèque",
        title: "Médiathèque",
        onClick: handleClickMedias,
        group: MediaGroup.name,
        order: 1
      });
    }

    if (Array.isArray(allSites) && allSites.length > 1) {
      sideBarItems.push({
        icon: (
          <>
            <Icon icon="globe-americas" />
          </>
        ),
        label: currentSite.name,
        title: `Changer de site (${currentSite.name})`,
        onClick: handleClickChangeSite,
        group: SiteGroup.name,
        order: 1
      });
    }

    addSideBarItems(sideBarItems);
    return () => {
      removeSideBarItems(sideBarItems);
    };
  }, [
    addSideBarItems,
    allSites,
    canAccessToGed,
    canCreatePage,
    canUpdateSite,
    canUpdateVersion,
    canImportSite,
    canExportSite,
    canDuplicateSite,
    currentSite,
    handleClickAddPage,
    handleClickDuplicatePage,
    handleClickAdminFooter,
    handleClickAdminMenu,
    handleClickAdminPage,
    handleClickChangeSite,
    handleClickImportExport,
    handleClickMedias,
    handleClickPages,
    handleClickPreviewContents,
    handleClickSelectSite,
    handleClickSiteSettings,
    handleClickDuplicateSite,
    removeSideBarItems,
    selectSiteAnchor
  ]);

  if (!pageVersion) {
    return null;
  }

  return (
    <>
      <Modal
        open={!!modal.content}
        size={modal.size}
        title={modal.title}
        onClose={closeModal}
        children={modal.content ? modal.content : <></>}
      />
      <Popover
        open={Boolean(selectSiteAnchor)}
        anchorEl={selectSiteAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={e => {
          e.stopPropagation();
          setSelectSiteAnchor(null);
        }}
      >
        {allSites.map(site => (
          <Box
            key={site.id}
            onClick={handleClickSelectSite(site)}
            sx={{
              p: "8px",
              cursor: "pointer",
              bgcolor: currentSite.id === site.id ? "secondary.main" : "",
              color: currentSite.id === site.id ? "primary.light" : "",
              "&:hover": {
                bgcolor: "secondary.main"
              }
            }}
          >
            {site.name}
          </Box>
        ))}
      </Popover>
      <AdminContentModal
        currentSiteTheme={currentSiteTheme}
        open={!!adminContent}
        content={adminContent}
        onValidate={handleValidateAdminContentModal}
        onClose={closeAdminContentModal}
        disableEnforceFocus
        title={`Paramétres du site : ${currentSite.name}`}
      />
      <AdminVersionsPrincipal
        pageVersion={pageVersion}
        setCurrentPageVersion={setCurrentPageVersion}
        setPageVersion={setPageVersion}
        handleSaveVersion={handleSaveVersion}
        setModal={setModal}
        closeModal={closeModal}
        canUpdateVersion={canUpdateVersion}
        lockedTemplate={lockedTemplate}
        pageRef={pageRef}
        handleClickAdminPage={handleClickAdminPage}
        currentSiteTheme={currentSiteTheme}
      />
    </>
  );
};

export default AdminWebsite;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Link, Popover } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/styles/useTheme";
import { styled } from "@mui/system";
import { useKeycloak } from "@react-keycloak/web";
import AdminVersions from "cms/components/back-office/AdminVersions";
import AdminVersionsItemAction from "cms/components/back-office/AdminVersionsItemAction";
import AdminContext from "cms/components/back-office/contexts/AdminContext";
import AdminPageChangeContext from "cms/components/back-office/contexts/AdminPageChangeContext";
import AdminPageContext from "cms/components/back-office/contexts/AdminPageContext";
import AdminPageVersionContext from "cms/components/back-office/contexts/AdminPageVersionContext";
import AdminWebsiteContext from "cms/components/back-office/contexts/AdminWebsiteContext";
import RenderPage from "cms/components/back-office/Layout/RenderPage";
import ModalConfirm from "cms/components/back-office/ModalConfirm";
import Icon from "cms/components/front-office/contents/Icon";
import useEnvironment from "cms/hooks/useEnvironment";
import AdminPagesService from "cms/services/admin/AdminPagesService";
import AdminPageVersionsService from "cms/services/admin/AdminPageVersionsService";
import AdminSitesService from "cms/services/admin/AdminSitesService";
import { PageRole, PageVersionRole } from "cms/types/cms/common/roles.enum";
import { PageVersionStatus } from "cms/types/cms/page/version/page-version.status.enum";
import { adminProperties } from "cms/utils/adminContentsUtils";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import { removeProperties } from "cms/utils/commonUtils";
import React from "react";
import uuidv4 from "uuid/v4";
var responsiveViews = [
    {
        key: "mobile",
        label: "Mobile",
        width: 414,
        height: 736,
        icon: "mobile"
    },
    {
        key: "tablet portrait",
        label: "Tablette (portrait)",
        width: 748,
        height: 1024,
        icon: "tablet"
    },
    {
        key: "tablet landscape",
        label: "Tablette (paysage)",
        width: 1024,
        height: 748,
        icon: "tablet",
        style: { transform: "rotate(-90deg)" }
    }
];
export var SiteGroup = {
    name: "site",
    order: 2
};
export var PageGroup = {
    name: "page",
    order: 3
};
export var MediaGroup = {
    name: "media",
    order: 4
};
export var ContentsGroup = {
    name: "contents",
    order: 5
};
export var DataGroup = {
    name: "data",
    order: 6
};
var Warning = styled("span")({
    color: "#f44336",
    fontWeight: 600
});
var isHomePage = function (version) {
    if (!version) {
        return false;
    }
    var page = version.page;
    return page && page.parentId === null && version.path === "/";
};
var AdminVersionsPrincipal = function (props) {
    var _a, _b;
    var currentSiteTheme = props.currentSiteTheme, pageVersion = props.pageVersion, setCurrentPageVersion = props.setCurrentPageVersion, setPageVersion = props.setPageVersion, handleSaveVersion = props.handleSaveVersion, setModal = props.setModal, closeModal = props.closeModal, canUpdateVersion = props.canUpdateVersion, lockedTemplate = props.lockedTemplate, pageRef = props.pageRef, handleClickAdminPage = props.handleClickAdminPage;
    var key = React.useState(uuidv4())[0];
    var _c = React.useState(true), editionModeState = _c[0], setEditionModeState = _c[1];
    var _d = React.useState(null), responsiveView = _d[0], setResponsiveView = _d[1];
    var _e = React.useState(null), responsiveViewSelectorAnchorEl = _e[0], setResponsiveViewSelectorAnchorEl = _e[1];
    var editionMode = editionModeState && canUpdateVersion;
    var excludedTemplatesForDelete = React.useMemo(function () { return CmsHooks.getHook(HOOKS.TEMPLATES_excludedTemplatesForPageDelete) || []; }, []);
    var lockedTemplateForDelete = React.useMemo(function () { return pageVersion && Boolean(excludedTemplatesForDelete.find(function (template) { return template.key === (pageVersion === null || pageVersion === void 0 ? void 0 : pageVersion.template); })); }, [excludedTemplatesForDelete, pageVersion]);
    var chosenDevice = React.useMemo(function () { return responsiveViews.find(function (res) { return res.key === (responsiveView && responsiveView.key); }); }, [responsiveView]);
    var themeBackOffice = useTheme();
    var keycloak = useKeycloak().keycloak;
    var environment = useEnvironment();
    var _f = React.useContext(AdminWebsiteContext), currentSite = _f.currentSite, refreshSite = _f.refreshSite;
    var _g = React.useContext(AdminPageChangeContext), pageChanges = _g.pageChanges, setPageChanges = _g.setPageChanges;
    var _h = React.useContext(AdminPageVersionContext), versionHasChanged = _h.versionHasChanged, currentPageVersion = _h.currentPageVersion, pageVersions = _h.pageVersions;
    var _j = React.useContext(AdminContext), displaySuccess = _j.displaySuccess, displayError = _j.displayError;
    var goToHomePage = React.useContext(AdminPageContext).goToHomePage;
    var canPublishVersion = keycloak.hasResourceRole(PageVersionRole.UPDATE_PAGE_VERSION_STATUS, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) &&
        !lockedTemplate;
    var canUnpublish = React.useMemo(function () { return !isHomePage(pageVersion); }, [pageVersion]) &&
        keycloak.hasResourceRole(PageVersionRole.UPDATE_PAGE_VERSION_STATUS, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) &&
        !lockedTemplate;
    var canDeleteVersion = keycloak.hasResourceRole(PageVersionRole.DELETE_PAGE_VERSION, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) && !lockedTemplate;
    var canDeletePage = React.useMemo(function () {
        return !pageVersions.find(function (version) { return version.status === PageVersionStatus.PUBLISHED; }) &&
            keycloak.hasResourceRole(PageRole.DELETE_PAGE, environment === null || environment === void 0 ? void 0 : environment.keycloak_client_cms) &&
            !lockedTemplateForDelete;
    }, [keycloak, environment, lockedTemplateForDelete, pageVersions]);
    var handleUndo = React.useCallback(function () {
        var pageChange = __assign({}, pageChanges[pageChanges.length - 2]);
        setPageVersion(pageChange);
        var changes = __spreadArray([], pageChanges, true);
        changes.splice(changes.length - 2, 2);
        setPageChanges(changes);
    }, [pageChanges, setPageChanges, setPageVersion]);
    var handleClickResponsive = React.useCallback(function (event) {
        setResponsiveViewSelectorAnchorEl(event.currentTarget);
    }, []);
    var handleClickView = React.useCallback(function (view) { return function () {
        setResponsiveView(view);
        setResponsiveViewSelectorAnchorEl(null);
    }; }, []);
    var handleDeletePageVersion = function (version) { return AdminPageVersionsService.delete(version); };
    var handleDeletePage = React.useCallback(function () {
        var _a, _b;
        setModal({
            content: (_jsx(ModalConfirm, { title: "Supprimer \"" + ((_b = (_a = currentPageVersion) === null || _a === void 0 ? void 0 : _a.page) === null || _b === void 0 ? void 0 : _b.name) + "\"", text: _jsxs(Box, __assign({ pb: 2 }, { children: ["Attention cette action est irr\u00E9versible et entra\u00EEne :", _jsx("br", {}, void 0), _jsxs("ul", { children: [_jsxs("li", { children: ["La suppression de ", _jsx(Warning, { children: "cet article ainsi que de toutes ses versions" }, void 0)] }, void 0), _jsxs("li", { children: ["La suppression de ", _jsx(Warning, { children: "tous les sous-articles" }, void 0), " li\u00E9s \u00E0 cet article"] }, void 0)] }, void 0)] }), void 0), confirmText: "Supprimer", confirmButtonProps: { style: { backgroundColor: themeBackOffice.palette.error.main } }, onConfirm: function () {
                    var _a;
                    AdminPagesService.delete((_a = currentPageVersion) === null || _a === void 0 ? void 0 : _a.page).then(function () {
                        goToHomePage();
                    });
                    closeModal();
                }, onCancel: closeModal }, void 0)),
            size: "sm"
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeModal, currentPageVersion, goToHomePage, themeBackOffice.palette.error.main]);
    var updateVersionStatus = React.useCallback(function (_a) {
        var version = _a.version, status = _a.status;
        return AdminPageVersionsService.updateStatus(version, status).catch(function () {
            displayError("Erreur lors de la mise à jour du statut de la version");
        });
    }, [displayError]);
    var updateTemplateSettings = React.useCallback(function (newTemplateSettings) {
        if (!currentSite)
            return;
        AdminSitesService.update({
            id: currentSite.id,
            title: currentSite.title,
            contents: currentSite.contents.map(function (c) {
                return c.key === "templatesSettings" ? removeProperties(newTemplateSettings, adminProperties) : c;
            })
        })
            .then(function () {
            refreshSite();
            displaySuccess("Le gabarit a été mis à jour.");
        })
            .catch(function () {
            displayError("Une erreur est survenue.");
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSite, displayError, displaySuccess, refreshSite]);
    return (_jsx(AdminVersions, __assign({ principal: true, showVersion: !responsiveView, canDelete: canDeleteVersion, canPublish: canPublishVersion, canRename: canUpdateVersion, manualSave: canUpdateVersion, leftActions: _jsxs(Grid, __assign({ container: true, alignItems: "center", style: { height: "100%" } }, { children: [canUpdateVersion && (_jsxs(AdminVersionsItemAction, __assign({ onClick: function () {
                        setEditionModeState(!editionMode);
                        setResponsiveView(null);
                    }, title: editionMode ? "Mode prévisualisation" : "Mode édition" }, { children: [_jsx(Icon, { title: editionMode ? "Mode prévisualisation" : "Mode édition", icon: editionMode ? "eye" : "edit", type: "far", color: (_b = (_a = themeBackOffice === null || themeBackOffice === void 0 ? void 0 : themeBackOffice.palette) === null || _a === void 0 ? void 0 : _a.layout) === null || _b === void 0 ? void 0 : _b.icon }, void 0), _jsx(Box, __assign({ component: "span", sx: { ml: 2 } }, { children: editionMode ? "Mode prévisualisation" : "Mode édition" }), void 0)] }), void 0)), !editionMode && (_jsxs(_Fragment, { children: [_jsx(AdminVersionsItemAction, __assign({ title: "Ordinateur", onClick: handleClickView(null), selected: !responsiveView }, { children: _jsx(Icon, { icon: "laptop", type: "fal" }, void 0) }), void 0), _jsx(AdminVersionsItemAction, __assign({ title: "Responsive", onClick: handleClickResponsive, selected: !!responsiveView }, { children: (chosenDevice === null || chosenDevice === void 0 ? void 0 : chosenDevice.Icon) || (_jsx(Icon, { icon: "phone-laptop", type: "fal", color: themeBackOffice && themeBackOffice.palette.layout.icon }, void 0)) }), void 0), _jsx(Popover, __assign({ open: !!responsiveViewSelectorAnchorEl, anchorEl: responsiveViewSelectorAnchorEl, onClose: function () { return setResponsiveViewSelectorAnchorEl(null); }, PaperProps: {
                                style: {
                                    height: "56px",
                                    maxHeight: "none",
                                    borderRadius: 0,
                                    backgroundColor: themeBackOffice && themeBackOffice.palette.layout.background,
                                    borderTop: "1px solid " + (themeBackOffice && themeBackOffice.palette.layout.border)
                                }
                            }, anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            }, transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            } }, { children: _jsx(Grid, __assign({ container: true, style: { height: "100%" } }, { children: responsiveViews.map(function (view) { return (_jsx(AdminVersionsItemAction, __assign({ title: view.label, onClick: handleClickView(view), selected: view.key === (chosenDevice && chosenDevice.key) }, { children: _jsx(Icon, { icon: view.icon, type: "fal", color: themeBackOffice && themeBackOffice.palette.layout.icon, style: view.style }, void 0) }), uuidv4())); }) }), void 0) }), void 0)] }, void 0)), editionMode && (_jsx(AdminVersionsItemAction, __assign({ onClick: handleClickAdminPage, title: "Param\u00E8tres de la page" }, { children: _jsx(Icon, { icon: "file-alt", title: "Param\u00E8tres de la page", color: themeBackOffice && themeBackOffice.palette.layout.icon }, void 0) }), void 0)), !responsiveView && pageChanges.length > 1 && (_jsx(AdminVersionsItemAction, __assign({ onClick: handleUndo, title: "Annuler la derni\u00E8re action" }, { children: _jsx(Icon, { title: "Annuler la derni\u00E8re action", icon: "undo", color: themeBackOffice && themeBackOffice.palette.layout.icon, style: {
                            fontWeight: "bold",
                            fontSize: "1.18rem"
                        } }, void 0) }), void 0))] }), void 0), rightActions: canDeletePage && (_jsx(Link, __assign({ href: "#", color: "error", onClick: handleDeletePage }, { children: "Supprimer cette page" }), void 0)), versions: pageVersions, currentVersion: pageVersion, setCurrentVersion: setCurrentPageVersion, onVersionChange: setPageVersion, saveVersion: handleSaveVersion, canUnpublish: canUnpublish, updateStatus: updateVersionStatus, deleteVersion: handleDeletePageVersion, autoSaveDelay: (environment === null || environment === void 0 ? void 0 : environment.autosave_delay) || 0 }, { children: function (_a) {
            var handleVersionChange = _a.handleVersionChange;
            return (_jsx(Box, __assign({ sx: { position: "relative", flex: 1, height: "100%" } }, { children: _jsx(Box, __assign({ sx: { position: "absolute", top: 0, right: 0, bottom: 0, left: 0 } }, { children: editionMode || !responsiveView ? (_jsx(Box, __assign({ ref: pageRef, sx: { position: "absolute", top: 0, right: 0, bottom: 0, left: 0 } }, { children: _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsx(ThemeProvider, __assign({ theme: currentSiteTheme }, { children: _jsx(RenderPage, { page: pageVersion, handlePageChange: handleVersionChange, handleTemplateSettingsChange: updateTemplateSettings, editionMode: editionMode }, key) }), void 0) }), void 0) }), void 0)) : (_jsxs(Grid, __assign({ container: true, direction: "column", alignItems: "center", sx: { height: !versionHasChanged ? "100%" : "auto" } }, { children: [versionHasChanged && (_jsx(Grid, __assign({ item: true, sx: {
                                    bgcolor: "primary.main",
                                    color: "primary.textDisabled",
                                    p: 2,
                                    borderRadius: 2,
                                    my: 3,
                                    maxWidth: responsiveView.width + "px"
                                } }, { children: _jsxs(Grid, __assign({ container: true, alignItems: "center", wrap: "nowrap" }, { children: [_jsx(InfoOutlinedIcon, { sx: { mr: 1 } }, void 0), _jsxs(Typography, { children: ["Vous avez des modifications en attente. ", _jsx("b", { children: "Enregistrez-les" }, void 0), " pour les visualiser en vue adaptative."] }, void 0)] }), void 0) }), void 0)), _jsx(Grid, __assign({ container: true, alignItems: "center", justifyContent: "center", sx: { height: !versionHasChanged ? "100%" : "auto" } }, { children: _jsx("iframe", { title: "No layout", src: "/noLayout" + window.location.search, style: {
                                        resize: "both",
                                        height: responsiveView.height + "px",
                                        width: responsiveView.width + "px"
                                    } }, void 0) }), void 0)] }), void 0)) }), void 0) }), void 0));
        } }), void 0));
};
export default AdminVersionsPrincipal;

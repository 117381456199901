import contentsTypes from "cms/enums/contentsTypes.enum";
import ArticlesTemplate from "templates/articles/ArticlesTemplate";
export default {
    key: "ARTICLES",
    label: "Articles",
    templateImage: "/template-blog.png",
    Component: ArticlesTemplate,
    initialContents: [
        {
            label: "Sous-titre",
            key: "subtitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Découvrez les nouveautés"
        },
        {
            label: "Afficher le header",
            key: "displayHeader",
            type: contentsTypes.CHECKBOX,
            value: "true"
        }
    ]
};

import axios from "axios";

const configs = {
  getPageInfos: pathname => ({
    url: "/getPageInfos",
    params: { pathname }
  }),
  findPagesByTemplateKeys: templates => ({
    url: "/findPagesByTemplates",
    params: { templates }
  })
};

class PageService {
  static getPageInfos = pathname => {
    return axios(configs.getPageInfos(pathname)).then(response => response.data);
  };

  static getConfig(methodName, options) {
    const getter = configs[methodName];
    if (typeof getter === "function") {
      return getter(options);
    }
    return {};
  }
}

export default PageService;

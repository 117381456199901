var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ListboxComponent } from "./select/ListBoxComponent";
export var CustomSelect = function (_a) {
    var _b;
    var label = _a.label, labelIcon = _a.labelIcon, withIcon = _a.withIcon, type = _a.type, res = __rest(_a, ["label", "labelIcon", "withIcon", "type"]);
    var theme = useTheme();
    return (_jsx(Autocomplete, __assign({ id: "country-select-demo", sx: { width: "auto" }, autoHighlight: true, disableListWrap: true, ListboxComponent: function (props) { return _jsx(ListboxComponent, __assign({}, props, { type: type, withIcon: withIcon })); }, getOptionLabel: function (option) { return option[labelIcon]; }, renderOption: function (props, option, state) { return [props, option, state.index]; }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: label, inputProps: __assign({}, params.inputProps // disable autocomplete and autofill
            ) }))); }, renderGroup: function (params) { return params; } }, (_b = theme.components) === null || _b === void 0 ? void 0 : _b.MuiAutocomplete, res)));
};

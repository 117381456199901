// import React from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/system";

export const FormTitle = styled("h2")({
  textAlign: "center"
});

export const FormFieldset = styled("fieldset")(({ theme }) => ({
  marginBottom: "0.875rem",
  "& > legend": {
    color: theme.palette.secondary.text
  }
}));

export const FormLabelTooltip = styled(Tooltip)({
  verticalAlign: "sub",
  ml: 1
});

import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const CheckboxForm = props => {
  const { content, onContentChange } = props;

  const { value } = content;

  const handleCheckboxChange = e => {
    onContentChange({
      ...content,
      value: e.target.checked ? "true" : "false"
    });
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={value === "true"} onChange={handleCheckboxChange} disableRipple />}
      label={content.label}
    />
  );
};

CheckboxForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default CheckboxForm;

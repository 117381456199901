import React from "react";
import PropTypes from "prop-types";
import SVGIcon from "cms/components/shared/icons/SVGIcon";

const AccordionIcon = props => {
  const { color, backgroundColor } = props;

  return (
    <svg viewBox="0 0 44 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="13" width="43" height="11" rx="2" fill={backgroundColor} />
      <path d="M33.205 17L35.5 19.29L37.795 17L38.5 17.705L35.5 20.705L32.5 17.705L33.205 17Z" fill={color} />
      <rect width="44" height="11" rx="2" fill={backgroundColor} />
      <path d="M33.205 4L35.5 6.29L37.795 4L38.5 4.705L35.5 7.705L32.5 4.705L33.205 4Z" fill={color} />
    </svg>
  );
};

AccordionIcon.propTypes = {
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
};

export default SVGIcon(AccordionIcon);

import types from "cms/enums/contentsTypes.enum";
export var siteSettings = {
    label: "site settings",
    type: types.SITE_SETTINGS,
    value: "",
    children: [
        {
            label: "preTitle",
            key: "preTitle",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Copyright",
            key: "copyright",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Adresse",
            key: "adress",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Code Postal",
            key: "postalCode",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Ville",
            key: "city",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Icone adresse",
            key: "iconAdress",
            type: types.INPUT,
            value: "map-marker-alt"
        },
        {
            label: "Email",
            key: "mail",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Icone email",
            key: "iconMail",
            type: types.INPUT,
            value: "envelope"
        },
        {
            label: "Site web",
            key: "website",
            type: types.INPUT,
            value: ""
        },
        {
            label: "logo header",
            key: "logoHeader",
            type: types.IMAGE,
            children: [
                {
                    label: "file",
                    key: "file",
                    type: types.FILE_IMAGE,
                    value: ""
                },
                {
                    label: "Texte alternatif",
                    key: "alt",
                    type: types.INPUT,
                    value: ""
                },
                {
                    label: "Titre (visible au survol)",
                    key: "title",
                    type: types.INPUT,
                    value: ""
                }
            ]
        },
        {
            label: "logo favicon",
            key: "logoFavicon",
            type: types.FILE_IMAGE,
            value: ""
        },
        {
            label: "logo footer",
            key: "logoFooter",
            type: types.IMAGE,
            children: [
                {
                    label: "file",
                    key: "file",
                    type: types.FILE_IMAGE,
                    value: ""
                },
                {
                    label: "Texte alternatif",
                    key: "alt",
                    type: types.INPUT,
                    value: ""
                },
                {
                    label: "Titre (visible au survol)",
                    key: "title",
                    type: types.INPUT,
                    value: ""
                }
            ]
        },
        {
            label: "warning banner",
            key: "warningBanner",
            type: types.WARNING_BANNER,
            value: ""
        },
        {
            label: "analytics",
            key: "analytics",
            type: types.INPUT,
            value: ""
        },
        {
            label: "tagManager",
            key: "tagManager",
            type: types.INPUT,
            value: ""
        },
        {
            label: "social networks",
            key: "socialNetworks",
            type: types.SOCIAL_NETWORK,
            value: ""
        }
    ]
};

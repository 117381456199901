import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => {
  return (
    <Box
      sx={{
        "& .MuiCircularProgress-circle": {
          stroke: "#3787FF"
        }
      }}
    >
      <CircularProgress size={36} />
    </Box>
  );
};

export default Loader;

import types from "cms/enums/contentsTypes.enum";
export var galerieImage = {
    label: "Galerie d'images",
    type: types.IMAGES_GALLERY,
    icon: "images",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "images",
            key: "images",
            type: types.IMAGES,
            requiredByKey: ["file"],
            children: [
                {
                    label: "image",
                    key: "image",
                    type: types.IMAGE_AS_FILE
                }
            ]
        },
        {
            label: "Hauteur des images",
            key: "height",
            type: types.INPUT_NUMBER,
            value: "200"
        },
        {
            label: "Boucle infinie",
            key: "infinite",
            type: types.CHECKBOX,
            value: "true"
        },
        {
            label: "Diaporama automatique",
            key: "autoPlay",
            type: types.CHECKBOX,
            value: "true"
        },
        {
            label: "Intervalle entre les images (en millisecondes)",
            key: "slideInterval",
            type: types.INPUT_NUMBER,
            value: "3000"
        },
        {
            label: "Afficher les vignettes",
            key: "showThumbnails",
            type: types.CHECKBOX,
            value: "true"
        },
        {
            label: "Position des vignettes",
            key: "thumbnailPosition",
            type: types.SELECT,
            options: [
                { label: "En haut", value: "top" },
                { label: "À droite", value: "right" },
                { label: "En bas", value: "bottom" },
                { label: "À gauche", value: "left" }
            ],
            value: "bottom"
        },
        {
            label: "Afficher les boutons de navigation",
            key: "showNav",
            type: types.CHECKBOX,
            value: "true"
        },
        {
            label: "Afficher le numéro d'image",
            key: "showIndex",
            type: types.CHECKBOX,
            value: "false"
        },
        {
            label: "Afficher les balles",
            key: "showBullets",
            type: types.CHECKBOX,
            value: "true"
        },
        {
            label: "Afficher le bouton de lecture",
            key: "showPlayButton",
            type: types.CHECKBOX,
            value: "false"
        },
        {
            label: "Afficher le bouton d'affichage en plein écran",
            key: "showFullscreenButton",
            type: types.CHECKBOX,
            value: "false"
        }
    ]
};

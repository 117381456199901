var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from "react-query";
import { requestFactory } from "../helpers";
/**
 *
 * @template TData      Output type / request result
 * @template TVariables Input type / request variables
 * @template TError     (optional) Error type
 * @template TQueryKey  (optional) Query key type
 *
 * @param queryKeys    Query key
 * @param queryOptions (optional) Query options
 * @param ...rest      request parameters
 *
 * @return react-query object
 *
 * example:
 * ```ts
 * const { data, isLoading } = useQueryFetcher<
 *   MyQuery,
 *   MyQueryVariables
 * >({
 *   queryKeys: [MyQueryKey],
 *   variables: { some: 'value' , url: 'value', data: {}},
 * });
 * ```
 */
export var useQueryFetcher = function (_a) {
    var queryKeys = _a.queryKeys, queryOptions = _a.queryOptions, rest = __rest(_a, ["queryKeys", "queryOptions"]);
    return useQuery(queryKeys, requestFactory(rest), queryOptions);
};

import types from "cms/enums/contentsTypes.enum";
export var menuGroup = {
    label: "Groupe de liens",
    editionModal: true,
    type: types.MENU_GROUP,
    children: [
        {
            label: "title",
            key: "title",
            type: types.INPUT,
            value: ""
        },
        {
            label: "icon",
            key: "icon",
            type: types.ICON,
            value: ""
        }
    ]
};

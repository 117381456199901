import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Icon from "cms/components/front-office/contents/Icon";

const contactStyled = {
  display: "flex",
  alignItems: "center",
  fontSize: "0.85rem",
  "& > i": { mr: 0.75, fontSize: "1.2rem" },
  color: "primary.light",
  fontFamily: '"Roboto"',
  letterSpacing: "0.1em"
};

const ContactBlock = ({ icon, contact }) => (
  <Grid item sx={{ mb: 1 }}>
    <Typography sx={contactStyled}>
      <Icon icon={icon} />
      {contact}
    </Typography>
  </Grid>
);

export default ContactBlock;

import { jsx as _jsx } from "react/jsx-runtime";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { createBackOfficeTheme, getCurrentThemeType, saveBackOfficeTheme } from "cms/theme/themeBackOffice";
import { createContext, useEffect, useState } from "react";
export var ThemeContext = createContext({ setThemeType: function () { return null; }, themeBackOffice: null });
var ThemeBackOfficeProvider = function (props) {
    var _a = props.children, children = _a === void 0 ? null : _a;
    var themeType = getCurrentThemeType();
    var _b = useState(), theme = _b[0], setTheme = _b[1];
    useEffect(function () {
        setTheme(createBackOfficeTheme(themeType));
    }, [themeType]);
    useEffect(function () {
        setTheme(createBackOfficeTheme(getCurrentThemeType()));
    }, []);
    var handleChangeTheme = function (type) {
        saveBackOfficeTheme(type);
        setTheme(createBackOfficeTheme(type));
    };
    if (!theme)
        return null;
    return (_jsx(ThemeContext.Provider, { value: { setThemeType: handleChangeTheme, themeBackOffice: theme }, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: theme, children: children }) }) }));
};
export default ThemeBackOfficeProvider;

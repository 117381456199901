var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import MuiTextField from "@mui/material/TextField";
import wrappable from "cms/utils/wrappable";
var TextField = wrappable(function (props) {
    return (_jsx(MuiTextField, __assign({ InputLabelProps: {
            shrink: true
        } }, props), void 0));
});
export default TextField;

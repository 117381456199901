import React from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Settings";
import AdminContentModal from "cms/components/back-office/admin/AdminContentModal";
import { AdminActionsContext } from "cms/components/back-office/admin/AdminActions";

const EditContentAction = props => {
  const { content, onContentChange } = props;
  const [displayModalContent, setDisplayModalContent] = React.useState(false);
  const { showActions, hideActions } = React.useContext(AdminActionsContext);

  // handleClickEdit: display Modal or Drawer when click on edit. --------------
  const handleClickEdit = () => {
    setDisplayModalContent(true);
    showActions();
  };

  // handleValidate: used when submit. -----------------------------------------
  const handleValidate = () => {
    setDisplayModalContent(false);
    hideActions();
  };

  // handleContentChange: used to update form for preview. ---------------------
  const handleContentChange = React.useCallback(
    updatedContent => {
      onContentChange(updatedContent);
    },
    [onContentChange]
  );

  // handleClose: used to close modal or drawer. -------------------------------
  const handleClose = React.useCallback(() => {
    setDisplayModalContent(false);
  }, []);

  // Display admin modal or drawer. --------------------------------------------
  return (
    <>
      <EditIcon onClick={handleClickEdit} />
      <AdminContentModal
        drawer={true}
        open={displayModalContent}
        content={content}
        onValidate={handleValidate}
        onContentChange={handleContentChange}
        onClose={handleClose}
      />
    </>
  );
};

EditContentAction.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default EditContentAction;

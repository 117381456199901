export var HOOKS;
(function (HOOKS) {
    /* ************************ */
    /* ----- FRONT HOOKS ----- */
    /* ************************ */
    /**
     * Description: Sets project Layout component
     * Type: React.Component
     */
    HOOKS["App_Layout"] = "App_Layout";
    /**
     * Description: Sets project Layout
     * Type: Array of Route props (react-router-dom)
     */
    HOOKS["App_routes"] = "App_routes";
    /**
     * Description: Sets project 404 component
     * Type: React.Component
     */
    HOOKS["PageSwitcher_404"] = "PageSwitcher_404";
    /**
     * Description: Used to render project custom contentTypes
     * Type: function({type,value,id,index}) => React.node (element|string|number...)
     */
    HOOKS["templatePropsUtils_getDynamicComponent"] = "templatePropsUtils_getDynamicComponent";
    /**
     * Description: Liste of project templates
     * Type: Array of Object
     * {
     *    key = template key (String)
     *    label = Displayed name in admin panel (String)
     *    Component = Template component (React.Component)
     *    initialContents = Initial template contents, use when create a new Page (Array)
     * }
     */
    HOOKS["TEMPLATES"] = "TEMPLATES";
    /**
     * Description: Project theme
     * Type: Material-ui theme
     */
    HOOKS["THEME"] = "THEME";
    /**
     * Description: Project theme
     * Type: Material-ui theme
     */
    HOOKS["TRACKING_ACTIVE"] = "TRACKING_ACTIVE";
    /**
     * Description: Project theme
     * Type: Material-ui theme
     */
    HOOKS["CONSENT_CONFIG"] = "CONSENT_CONFIG";
    /**
     * Description: Allow you to set distinct site themes, as priority on THEME hook
     * Type: function({site}) => Material-ui theme
     */
    HOOKS["GET_THEME"] = "GET_THEME";
    /* ************************ */
    /* ----- SERVER HOOKS ----- */
    /* ************************ */
    /**
     * Description: Allow you to add data into <head>
     * Type: String | (function({nonce,site}) => String)
     */
    HOOKS["Html_head"] = "Html_head";
    /**
     * Description: Allow you to add data into <body>
     * Type: String | (function({nonce,site}) => String)
     */
    HOOKS["Html_body"] = "Html_body";
    /**
     * Description: Allow you to add custom node routes
     * Type: function(app)
     */
    HOOKS["NODE_ROUTES"] = "NODE_ROUTES";
    /**
     * Description: Allow you to add custom node routes
     * Type: function(app)
     */
    HOOKS["enhanceContentsUtil_transformContent"] = "enhanceContentsUtil_transformContent";
    /**
     * Description: React-query client global options
     * Type: QueryClientConfig
     */
    HOOKS["clientFront_queryClientOptions"] = "clientFront_queryClientOptions";
    /* ************************ */
    /* ----- ADMIN HOOKS ----- */
    /* ************************ */
    /**
     * Description: Allow you to add routes to back office
     * Type: React.component
     */
    HOOKS["Bo_Routes"] = "Bo_Routes";
    /**
     * Description: Path to administrate website (default "/")
     * Type: String
     */
    HOOKS["Bo_AdminWebsitePath"] = "Bo_AdminWebsitePath";
    /**
     * Description: Path to administrate users (default "/users")
     * Type: String
     */
    HOOKS["Bo_AdminUsersPath"] = "Bo_AdminUsersPath";
    /**
     * Description: Project custom types definition
     * Type: Array of objects(see adminContentsUtils file)
     */
    HOOKS["adminContentsUtils_contents"] = "adminContentsUtils_contents";
    /**
     * Description: Used to render project custom contentTypes administration component
     * Type: function({content,value,index,handleValueChange,handleContentChange}) => React.node
     */
    HOOKS["templateAdminUtils_getEditorComponent"] = "templateAdminUtils_getEditorComponent";
    /**
     * Description:
     * Type: function({type}) => Array of contentTypes (String)
     */
    HOOKS["templateAdminUtils_getDynamicAdminContentsTypes"] = "templateAdminUtils_getDynamicAdminContentsTypes";
    /**
     * Description: List of template not available when creates a new Page
     * Type: Array of Templates (see TEMPLATES hook)
     */
    HOOKS["TEMPLATES_excludedTemplatesForPageCreation"] = "TEMPLATES_excludedTemplatesForPageCreation";
    /**
     * Description: List of not editable templates
     * Type: Array of Templates (see TEMPLATES hook)
     */
    HOOKS["TEMPLATES_excludedTemplatesForPageUpdate"] = "TEMPLATES_excludedTemplatesForPageUpdate";
    /**
     * Description List of not deletable templates
     * Type Array of Templates (see TEMPLATES hook)
     */
    HOOKS["TEMPLATES_excludedTemplatesForPageDelete"] = "TEMPLATES_excludedTemplatesForPageDelete";
    /**
     * Description:  Used to render project custom contentTypes administration from component when in a modal
     * Type: function(type) => React.Component
     */
    HOOKS["ContentForm_getFormComponent"] = "ContentForm_getFormComponent";
    /**
     * Description: Used to redirect specific
     * Type: object (oldUrl  = newUrl)
     */
    HOOKS["Redirect_301"] = "Redirect_301";
    /**
     * Description: Used to prefetch react query data
     * Type: function({queryClient,url}) => Promise
     */
    HOOKS["SSR_Prefetch"] = "SSR_Prefetch";
    /**
     * Description: List of forbidden pathes for a page
     * Type: Array of string
     */
    HOOKS["AdminPageForm_ProtectedPaths"] = "AdminPageForm_ProtectedPaths";
    /**
     * Description: React-query client global options
     * Type: QueryClientConfig
     */
    HOOKS["clientAdmin_queryClientOptions"] = "clientAdmin_queryClientOptions";
    /**
     * Description: overwrite contentSecurityPolicy helmet configuration
     * Type: ContentSecurityPolicyOptions
     */
    HOOKS["helmetFront_contentSecurityPolicy"] = "helmetFront_contentSecurityPolicy";
    /**
     * Description: overwrite contentSecurityPolicy helmet configuration
     * Type: ContentSecurityPolicyOptions
     */
    HOOKS["helmetBack_contentSecurityPolicy"] = "helmetBack_contentSecurityPolicy";
})(HOOKS || (HOOKS = {}));
var hooks = {
    Bo_AdminWebsitePath: "/",
    Bo_AdminUsersPath: "/users"
};
export default {
    registerHook: function (key, value) {
        hooks[key] = value;
    },
    getHook: function (key) {
        return hooks[key];
    }
};

import contentsTypes from "cms/enums/contentsTypes.enum";
import FAQTemplate from "templates/faq/FAQTemplate";
export default {
    key: "FAQ",
    label: "FAQ",
    templateImage: "/template-faq.png",
    Component: FAQTemplate,
    initialContents: [
        {
            label: "Sous-titre",
            key: "subtitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: "Avez-vous des questions ?"
        },
        {
            key: "dynamicPart",
            type: contentsTypes.DYNAMIC,
            value: ""
        }
    ]
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import SubPageContext from "cms/components/front-office/contents/subPage/SubPageContext";
import { useContext } from "react";
var SubPage = function (props) {
    var _a = props.page, page = _a === void 0 ? null : _a, pageVersion = props.pageVersion, index = props.index, others = __rest(props, ["page", "pageVersion", "index"]);
    var _b = useContext(SubPageContext), subTemplate = _b.subTemplate, _c = _b.getSubTemplate, getSubTemplate = _c === void 0 ? function () { return function () { return null; }; } : _c;
    // Template will take value of subTemplate or call getSubTemplate function if subTemplate is null
    var Template = subTemplate;
    if (!subTemplate) {
        Template = getSubTemplate(page === null || page === void 0 ? void 0 : page.template, index);
    }
    Template = Template || (function () { return null; });
    return _jsx(Template, __assign({ page: page, pageVersion: pageVersion, index: index }, others), page === null || page === void 0 ? void 0 : page.id);
};
export default SubPage;

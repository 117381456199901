import { cardBox1 } from "./cardBox1.descriptor";
import { cardBox2 } from "./cardBox2.descriptor";
import { partners } from "./partners.descriptor";
import { PresentationBox } from "./presentationBox.descriptor";
import { PresentationRow } from "./presentationRow.descriptor";
import { LastArticles } from "./lastArticles.descriptor";
import { Agenda } from "./agenda.descriptor";
import { teamUnit } from "./teamUnit.descriptor";
import { subPage } from "./subPage.descriptor";
import { subPageSearch } from "./subPageSearch.descriptor";
import { newsletter } from "./newsletter.descriptor";
import { slider } from "./slider.descriptor";
import { divider } from "./divider.descriptor";
import { simpleLink } from "./simple.link.descriptor";
import { iframeDescriptor } from "../contents/iframe.descriptor";

export default [
  cardBox1,
  cardBox2,
  partners,
  PresentationBox,
  PresentationRow,
  LastArticles,
  teamUnit,
  subPage,
  subPageSearch,
  divider,
  Agenda,
  slider,
  newsletter,
  simpleLink,
  iframeDescriptor
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
// Components. -----------------------------------------------------------------
import Image from "cms/components/front-office/contents/Image";
import PresentationBox from "./PresentationBox";
// =============================================================================
// PresentationBoxPreview...
// =============================================================================
var PresentationBoxPreview = function () {
    // Render. -------------------------------------------------------------------
    return (_jsx(Box, __assign({ sx: { width: "500px" } }, { children: _jsx(PresentationBox, { title: "Titre de la Bo\u00EEte", subTitle1: "Sous titre 1", subTitle2: "Sous titre 2", description: "Zone de texte libre", position: "row", image: _jsx(Image, { file: { url: "/default-image.jpg" }, disableLazy: true }, void 0) }, void 0) }), void 0));
};
export default PresentationBoxPreview;

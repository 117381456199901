import React, { Fragment, useState, useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import useTheme from "@mui/styles/useTheme";
import { Box, Button, Grid } from "@mui/material";
import { PageVersionStatus } from "cms/types/cms/page/version/page-version.status.enum";
import AdminWebsiteContext from "cms/components/back-office/contexts/AdminWebsiteContext";
import ModalConfirm from "cms/components/back-office/ModalConfirm";
import PreventNavigation from "cms/components/back-office/PreventNavigation";
import RenameVersionForm from "cms/components/back-office/RenameVersionForm";
import SelectVersion from "cms/components/back-office/SelectVersion";
import Icon from "cms/components/front-office/contents/Icon";
import Loader from "cms/components/shared/Loader";
import Modal from "cms/components/back-office/Modal";
import AdminSitesService from "cms/services/admin/AdminSitesService";
import AdminPageVersionContext from "./contexts/AdminPageVersionContext";
import AdminEditorContext from "./contexts/AdminEditorContext";
import AdminPageChangeContext from "./contexts/AdminPageChangeContext";
const formatDate = date =>
  new Date(date).toLocaleDateString("FR-fr", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

const DefaultMessage = props => {
  const { title, version } = props;
  const { status } = version || {};

  const getPageName = () => {
    const name = version.page ? version.page.name : title;

    return (
      <>
        <Box sx={pageName} title={name}>
          {name}
        </Box>
        <Box component="span" sx={{ color: "layout.border" }}>
          /
        </Box>
      </>
    );
  };

  const getBlockVersion = (status, color, description) => (
    <Grid container direction="column" spacing={1}>
      <Grid item sx={statusLabelStyled}>
        {getPageName()}
        <Box component="span" sx={{ color: color }}>
          {status}
        </Box>
      </Grid>
      <Grid item sx={{ fontSize: "0.79rem", color: "layout.draft" }}>
        {description} {formatDate(version.updated)}
      </Grid>
    </Grid>
  );

  switch (status) {
    case PageVersionStatus.DRAFT:
      return getBlockVersion("Brouillon", "layout.draft", "Dernière mise à jour le");
    case PageVersionStatus.ARCHIVED:
      return getBlockVersion("Version archivée", "unpublished.textStatus", "Archivée le");
    case PageVersionStatus.PUBLISHED:
      return getBlockVersion("Version publiée", "success.main", "Publiée le");
    default:
      return null;
  }
};

DefaultMessage.propTypes = {
  title: PropTypes.string,
  version: PropTypes.shape()
};

DefaultMessage.defaultProps = {
  title: null,
  version: null
};

const statusLabelStyled = {
  display: "inline-flex",
  columnGap: "5px",
  fontSize: "1.05rem",
  color: "secondary.text",
  fontWeight: "bold"
};

const pageName = {
  overflow: "hidden",
  maxWidth: 200,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
};

const actionBarStyled = principal => ({
  position: principal ? "fixed" : "initial",
  top: 0,
  left: 50,
  right: 0,
  height: "80px",
  bgcolor: principal ? "layout.background" : "transparent",
  borderBottom: "1px solid",
  borderColor: "layout.border",
  zIndex: 350,
  pr: 4,
  transition: "background-color 1000ms ease, color 1000ms ease, border-color 1000ms ease"
});

const pendingVersionActionStyled = {
  mt: 0.5,
  fontSize: "0.79rem",
  color: "layout.draft",
  cursor: "pointer",
  textAlign: "center",
  "&:hover": {
    textDecoration: "underline"
  }
};

const unpublishedStyled = {
  color: "unpublished.text",
  bgcolor: "unpublished.main",
  "&:hover": {
    bgcolor: "unpublished.dark"
  }
};

const AdminVersions = props => {
  const {
    showVersion,
    leftActions,
    rightActions,
    versions,
    currentVersion,
    setCurrentVersion,
    onVersionChange,
    canRename: canRenameProp,
    saveVersion,
    canPublish: canPublishProp,
    canUnpublish: canUnpublishProp,
    updateStatus,
    canDelete: canDeleteProp,
    deleteVersion,
    children,
    autoSaveDelay,
    manualSave,
    principal,
    title
  } = props;

  const { id: currentVersionId } = currentVersion || {};

  const loadingPageVersion = React.useMemo(() => !versions.some(v => v.id === currentVersionId), [
    currentVersionId,
    versions
  ]);

  const { currentSite } = useContext(AdminWebsiteContext);
  const { pageChanges, setPageChanges } = useContext(AdminPageChangeContext);
  const { versionIsCanceled, setVersionIsCanceled, versionHasChanged, setVersionHasChanged } = useContext(
    AdminPageVersionContext
  );
  const { editorValueChange, setEditorValueChange } = useContext(AdminEditorContext);

  const theme = useTheme();

  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);
  const [versionMessage, setVersionMessage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isPendingVersion, setIsPendingVersion] = useState(false);

  const [modal, setModal] = useState({
    content: null,
    size: null
  });

  useEffect(() => {
    setVersionHasChanged(false);
    setVersionMessage(null);
    setIsPendingVersion(false);
  }, [currentVersionId, setVersionHasChanged]);

  useEffect(() => {
    if (pageChanges && pageChanges.length === 0) {
      if (!isSaving) {
        setVersionMessage(null);
      }
      setVersionHasChanged(false);
      setIsPendingVersion(false);
      setVersionIsCanceled(true);
    }
  }, [isSaving, pageChanges, setVersionHasChanged, setVersionIsCanceled]);

  const openModal = (content, size = null) => {
    setModal({ content, size });
  };

  const closeModal = () =>
    setModal({
      content: null,
      size: null
    });

  const handleClickRenameVersion = () => {
    openModal(
      <RenameVersionForm
        versionName={currentVersion.versionName}
        onValidate={name => {
          saveVersion({ ...currentVersion, versionName: name }).then(() => {
            setCurrentVersion(currentVersion);
            closeModal();
          });
        }}
        onCancel={closeModal}
      />,
      "sm"
    );
  };

  const handleClickDeleteVersion = () => {
    openModal(
      <ModalConfirm
        title="Supprimer cette version"
        text="Attention cette action est irréversible"
        confirmText="Supprimer"
        confirmButtonProps={{ sx: { bgcolor: "error.main" } }}
        onConfirm={() => {
          deleteVersion(currentVersion).then(() => {
            setVersionHasChanged(false);
            setCurrentVersion();
            closeModal();
          });
        }}
        onCancel={closeModal}
      />,
      "sm"
    );
  };

  const updateVersionStatus = ({ version, status }) => {
    AdminSitesService.resetSiteCache(currentSite);
    return updateStatus({ version, status }).then(() => setCurrentVersion(version));
  };

  const handleClickPublish = () => {
    const { status } = currentVersion;
    if (status === PageVersionStatus.DRAFT) {
      updateVersionStatus({ version: currentVersion, status: PageVersionStatus.PUBLISHED });
    } else {
      saveVersion(currentVersion).then(newVersion =>
        updateVersionStatus({ version: newVersion, status: PageVersionStatus.PUBLISHED })
      );
    }
  };

  const handleClickUnpublish = () => {
    updateVersionStatus({ version: currentVersion, status: PageVersionStatus.ARCHIVED });
  };

  const handleSaveVersion = useCallback(
    (version, refresh = true) => {
      setIsPendingVersion(false);
      if (manualSave) setPageChanges([]);

      if (!isSaving) {
        if (version.status === PageVersionStatus.DRAFT) {
          setVersionMessage("Enregistrement...");
        } else {
          setVersionMessage("Création d'un nouveau brouillon...");
        }
        setAutoSaveTimeout(null);
        setIsSaving(true);
        saveVersion(version).then(v => {
          if (refresh) {
            setCurrentVersion(v).then(() => {
              setIsSaving(false);
              setVersionHasChanged(false);
              setVersionMessage(null);
            });
          } else {
            setIsSaving(false);
            setVersionHasChanged(false);
            setVersionMessage(null);
          }
        });
      }
    },
    [saveVersion, setCurrentVersion, setPageChanges, isSaving, manualSave, setVersionHasChanged]
  );

  const handleVersionChange = useCallback(
    version => {
      if (version.id === currentVersionId && (!versionIsCanceled || (versionIsCanceled && !editorValueChange))) {
        if (manualSave) {
          setIsPendingVersion(true);
          setVersionMessage("Modifications en attente");
        } else setVersionMessage("Modifications en cours");

        setVersionHasChanged(true);
        onVersionChange(version);

        if (autoSaveDelay > 0 || !manualSave) {
          if (autoSaveTimeout) {
            clearTimeout(autoSaveTimeout);
          }
          if (autoSaveDelay && autoSaveDelay > 0) {
            const newTimeout = setTimeout(() => {
              handleSaveVersion(version);
            }, autoSaveDelay);
            setAutoSaveTimeout(newTimeout);
          }
        }
      }

      if (versionIsCanceled) setVersionIsCanceled(false);
      if (editorValueChange) setEditorValueChange(false);
    },
    [
      currentVersionId,
      onVersionChange,
      autoSaveTimeout,
      autoSaveDelay,
      handleSaveVersion,
      manualSave,
      versionIsCanceled,
      editorValueChange,
      setVersionIsCanceled,
      setEditorValueChange,
      setVersionHasChanged
    ]
  );

  const handleShowPrompt = () => {
    clearTimeout(autoSaveTimeout);
  };

  const handleClickNavigate = () => {
    setVersionHasChanged(false);
    setVersionMessage(null);
  };

  const handleCancel = () => {
    setVersionHasChanged(false);
    setVersionMessage(null);
    setIsPendingVersion(false);
    setVersionIsCanceled(true);
    setPageChanges([]);
    setCurrentVersion({
      ...currentVersion,
      contents: pageChanges[0]?.contents || currentVersion.contents,
      title: pageChanges[0]?.title || currentVersion.title,
      shortDescription: pageChanges[0]?.shortDescription || currentVersion?.shortDescription
    });
  };

  const { status } = currentVersion || {};

  const isDraft = status === PageVersionStatus.DRAFT;
  const isPublished = status === PageVersionStatus.PUBLISHED;

  const canRename = canRenameProp && isDraft;
  const canPublish = canPublishProp && typeof updateStatus === "function" && !versionHasChanged && !isPublished;
  const canUnpublish = canUnpublishProp && typeof updateStatus === "function" && isPublished;
  const canDelete = canDeleteProp && typeof deleteVersion === "function" && isDraft && versions.length > 1;

  return (
    <Fragment>
      <PreventNavigation
        blocked={versionHasChanged && !isSaving}
        onSave={() => handleSaveVersion(currentVersion, false)}
        onShowPrompt={handleShowPrompt}
        onNavigate={handleClickNavigate}
      />
      <Modal
        open={!!modal.content}
        size={modal.size}
        onClose={closeModal}
        children={modal.content ? modal.content : <></>}
      />
      <Box sx={{ flexGrow: 1, height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={actionBarStyled(principal)}>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ height: "100%" }}>
            {leftActions && (
              <Grid item sx={{ height: "100%" }}>
                {leftActions}
              </Grid>
            )}
            {showVersion && (
              <Grid item>
                {!loadingPageVersion ? (
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                      {versionMessage ? (
                        <>
                          <Box
                            component="span"
                            sx={{
                              fontSize: isPendingVersion ? "0.875rem" : "0.79rem",
                              color: isPendingVersion ? "typography.default" : "layout.draft"
                            }}
                          >
                            {versionMessage}
                          </Box>
                          {isPendingVersion && (
                            <Box sx={pendingVersionActionStyled} onClick={handleCancel}>
                              Annuler
                            </Box>
                          )}
                        </>
                      ) : (
                        <DefaultMessage title={title} version={currentVersion} />
                      )}
                    </Grid>

                    <Grid item>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                          <SelectVersion
                            versions={versions}
                            selectedVersion={currentVersion}
                            onSelectVersion={setCurrentVersion}
                          />
                        </Grid>
                        <Grid item>
                          <Grid container direction="column" spacing={1}>
                            {canRename && (
                              <Grid item>
                                <Icon
                                  icon="edit"
                                  type="fas"
                                  title="Renommer cette version"
                                  onClick={handleClickRenameVersion}
                                  style={{
                                    fontSize: "0.83rem",
                                    cursor: "pointer",
                                    color: theme.palette.layout.icon
                                  }}
                                />
                              </Grid>
                            )}
                            {canDelete && (
                              <Grid item>
                                <Icon
                                  icon="trash"
                                  type="fas"
                                  title="Supprimer cette version"
                                  onClick={handleClickDeleteVersion}
                                  style={{
                                    fontSize: "0.83rem",
                                    cursor: "pointer",
                                    color: theme.palette.layout.icon
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Loader />
                )}
              </Grid>
            )}

            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>{rightActions}</Grid>
                {manualSave && (
                  <Grid item>
                    <Button
                      color="secondary"
                      onClick={() => handleSaveVersion(currentVersion)}
                      disabled={!versionHasChanged || isSaving}
                    >
                      Enregistrer
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  {!canUnpublish && canPublishProp && (
                    <Button color="primary" onClick={handleClickPublish} disabled={!canPublish || versionHasChanged}>
                      Publier cette version
                    </Button>
                  )}
                  {canUnpublish && (
                    <Button
                      sx={unpublishedStyled}
                      onClick={handleClickUnpublish}
                      disabled={manualSave && isPendingVersion}
                    >
                      Dépublier cette version
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, pt: principal ? "80px" : 0 }}>{children({ handleVersionChange })}</Box>
      </Box>
    </Fragment>
  );
};

const versionShape = {
  status: PropTypes.oneOf(Object.values(PageVersionStatus)).isRequired,
  updated: PropTypes.string.isRequired,
  versionName: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape())
};

AdminVersions.propTypes = {
  showVersion: PropTypes.bool,
  leftActions: PropTypes.node,
  rightActions: PropTypes.node,
  versions: PropTypes.arrayOf(PropTypes.shape(versionShape)).isRequired,
  currentVersion: PropTypes.shape(versionShape).isRequired,
  setCurrentVersion: PropTypes.func.isRequired,
  onVersionChange: PropTypes.func.isRequired,
  saveVersion: PropTypes.func.isRequired,
  canRename: PropTypes.bool,
  canPublish: PropTypes.bool,
  canUnpublish: PropTypes.bool,
  updateStatus: PropTypes.func,
  canDelete: PropTypes.bool,
  deleteVersion: PropTypes.func,
  children: PropTypes.func.isRequired,
  autoSaveDelay: PropTypes.number,
  manualSave: PropTypes.bool,
  principal: PropTypes.bool,
  title: PropTypes.string
};
AdminVersions.defaultProps = {
  showVersion: true,
  leftActions: null,
  rightActions: null,
  canRename: true,
  canPublish: true,
  canUnpublish: true,
  updateStatus: null,
  canDelete: true,
  deleteVersion: null,
  autoSaveDelay: 2000,
  manualSave: false,
  principal: false,
  title: null
};

export default AdminVersions;

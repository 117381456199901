import types from "cms/enums/contentsTypes.enum";
export var tab = {
    type: types.TAB,
    label: "Onglet",
    children: [
        {
            key: "title",
            label: "Titre",
            type: types.INPUT,
            value: ""
        },
        {
            label: "children",
            key: "children",
            type: types.DYNAMIC
        }
    ]
};

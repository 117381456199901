import types from "cms/enums/contentsTypes.enum";
export var image = {
    label: "Image",
    type: types.IMAGE,
    icon: "image",
    children: [
        {
            label: "file",
            key: "file",
            type: types.FILE_IMAGE,
            value: "{\"url\":\"" + (typeof window !== "undefined" && window.location.origin) + "/default-image.jpg\"}"
        },
        {
            label: "Texte alternatif",
            key: "alt",
            type: types.INPUT,
            value: "image"
        },
        {
            label: "Titre (visible au survol)",
            key: "title",
            type: types.INPUT,
            value: "image"
        },
        {
            label: "Verticale",
            key: "position_verticale",
            type: types.SELECT,
            options: [
                { label: "Haute", value: "top" },
                { label: "Centrée", value: "center" },
                { label: "Basse", value: "bottom" }
            ],
            value: "top"
        },
        {
            label: "Horizontale",
            key: "position_horizontale",
            type: types.SELECT,
            options: [
                { label: "Gauche", value: "left" },
                { label: "Centrée", value: "center" },
                { label: "Droite", value: "right" }
            ],
            value: "center"
        },
        {
            label: "Ajustement",
            key: "position_ajustement",
            type: types.SELECT,
            options: [
                { label: "Aucun", value: "none" },
                { label: "Rogné", value: "cover" },
                { label: "Étiré", value: "fill" },
                { label: "Contraint", value: "contain" }
            ],
            value: "cover"
        },
        {
            label: "Filtres",
            key: "filters",
            type: types.IMAGE_FILTERS,
            value: ""
        }
    ]
};

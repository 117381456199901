import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ModalActions } from "cms/components/back-office/Modal";

const ModalConfirm = props => {
  const { title, text, confirmText, cancelText, onConfirm, onCancel, confirmButtonProps, cancelButtonProps } = props;

  return (
    <div>
      <h2>{title}</h2>
      {text && <Box mb={2}>{text}</Box>}
      <ModalActions>
        <Button onClick={onCancel} {...cancelButtonProps}>
          {cancelText}
        </Button>
        <Button primary onClick={onConfirm} {...confirmButtonProps}>
          {confirmText}
        </Button>
      </ModalActions>
    </div>
  );
};

ModalConfirm.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string | PropTypes.node,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonProps: PropTypes.shape({}),
  cancelButtonProps: PropTypes.shape({})
};

ModalConfirm.defaultProps = {
  text: "",
  confirmText: "Confirmer",
  cancelText: "Annuler",
  confirmButtonProps: {},
  cancelButtonProps: {}
};

export default ModalConfirm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Box, Grid, Typography } from "@mui/material";
import Link from "cms/components/front-office/contents/Link";
import React, { Fragment } from "react";
import { icsDateFormat } from "utils/icsDateFormat";
// Tools. ----------------------------------------------------------------------
import icsToJson from "utils/icsToJson";
import AgendaElementGraphique from "./AgendaElementGraphique";
// Components. -----------------------------------------------------------------
import AgendaItem from "./AgendaItem";
// Theme/Style. ----------------------------------------------------------------
var styleCard = {
    bgcolor: "primary.light",
    py: { xs: 2, lg: 4 },
    borderRadius: "6px",
    minHeight: { xs: "auto", lg: "125px" },
    height: "auto",
    display: "flex",
    flexDirection: { xs: "row", lg: "column" },
    flexGrow: "1"
};
var Agenda = function (props) {
    var id = props.id, title = props.title, icsUrl = props.icsUrl, seeMoreUrl = props.seeMoreUrl, others = __rest(props, ["id", "title", "icsUrl", "seeMoreUrl"]);
    var date = new Date().toLocaleDateString("fr", { day: "numeric", month: "long", year: "numeric" });
    var _a = React.useState([]), convertIcsedIcs = _a[0], setConvertedIcs = _a[1];
    var _b = React.useState(false), seeMore = _b[0], setSeeMore = _b[1];
    var monthDate = "";
    // Functions. ----------------------------------------------------------------
    var convertIcs = function (icsFile) { return __awaiter(void 0, void 0, void 0, function () {
        var icsRes, icsData, jsonData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(icsFile)];
                case 1:
                    icsRes = _a.sent();
                    return [4 /*yield*/, icsRes.text()];
                case 2:
                    icsData = _a.sent();
                    jsonData = icsToJson(icsData);
                    if (jsonData.length > 3) {
                        setConvertedIcs(jsonData.slice(0, 3));
                        setSeeMore(true);
                    }
                    else {
                        setConvertedIcs(jsonData);
                        setSeeMore(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var itemMonthYear = function (date) {
        var itemDate = icsDateFormat(date, "monthYear");
        if (itemDate !== monthDate) {
            monthDate = itemDate;
            return (_jsxs(Typography, __assign({ variant: "subtitle1", sx: {
                    color: "greyColor.main"
                } }, { children: [itemDate.charAt(0).toUpperCase(), itemDate.slice(1)] }), void 0));
        }
        else {
            return null;
        }
    };
    // Hooks. --------------------------------------------------------------------
    React.useEffect(function () {
        if (icsUrl) {
            convertIcs(icsUrl);
        }
    }, [icsUrl]);
    // Render. -------------------------------------------------------------------
    return (_jsx(_Fragment, { children: _createElement(Box, __assign({}, others, { key: id, sx: styleCard }),
            _jsx(AgendaElementGraphique, {}, void 0),
            _jsxs(Grid, __assign({ container: true, direction: "column", justifyContent: "center", alignItems: "flex-start" }, { children: [_jsxs(Grid, __assign({ item: true, container: true, alignItems: "center", columnSpacing: 2, sx: { mb: 1 } }, { children: [_jsx(Grid, __assign({ item: true, sx: { display: { xs: "none", md: "block" } } }, { children: _jsx("img", { src: "/Agenda-calendar.png", alt: "calendrier agenda" }, void 0) }), void 0), _jsxs(Grid, __assign({ item: true, xs: true, container: true, justifyContent: "space-between", sx: { alignItems: { xs: "flex-end", md: "flex-end" } } }, { children: [_jsxs(Grid, __assign({ item: true }, { children: [title && (_jsx(Typography, __assign({ variant: "h2", sx: { mb: 1 } }, { children: title }), void 0)), _jsxs(Grid, __assign({ item: true, xs: true, alignItems: "inherit", display: "flex" }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: { color: "greyColor.main" } }, { children: "Aujourd'hui:" }), void 0), _jsx(Typography, __assign({ variant: "subtitle1", sx: { ml: 1, color: "greyColor.main" } }, { children: date }), void 0)] }), void 0)] }), void 0), _jsx(Grid, __assign({ item: true, xs: "auto" }, { children: seeMore && seeMoreUrl && (_jsxs(Link, __assign({ title: seeMoreUrl.title, url: seeMoreUrl.url, page: seeMoreUrl.page, external: seeMoreUrl.external, sx: { display: "flex", mb: 0.25 } }, { children: [_jsx(Typography, __assign({ variant: "subtitle2", sx: { color: "primary.dark" } }, { children: "Voir tout" }), void 0), _jsx(CobaltIcon, { name: "chevron-right" }, void 0)] }), void 0)) }), void 0)] }), void 0)] }), void 0), convertIcsedIcs && (_jsx(Grid, __assign({ item: true, sx: { width: "100%" } }, { children: convertIcsedIcs.length > 0 ? (convertIcsedIcs.map(function (event, index) {
                            return (_jsxs(Fragment, { children: [itemMonthYear(event["startDate"]), _jsx(AgendaItem, { event: event }, void 0)] }, "agenda." + index));
                        })) : (_jsx(Typography, __assign({ variant: "subtitle1", sx: {
                                color: "greyColor.main",
                                textAlign: "center"
                            } }, { children: "Aucun \u00E9v\u00E9nement \u00E0 venir" }), void 0)) }), void 0))] }), void 0)) }, void 0));
};
export default Agenda;

import types from "cms/enums/contentsTypes.enum";
export var imageFile = {
    label: "Image",
    type: types.IMAGE_AS_FILE,
    icon: "image",
    children: [
        {
            label: "file",
            key: "file",
            type: types.FILE_IMAGE,
            value: ""
        },
        {
            label: "Texte alternatif",
            key: "alt",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Titre (visible au survol)",
            key: "title",
            type: types.INPUT,
            value: ""
        }
    ]
};

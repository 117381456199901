import categories from "cms/enums/categoriesContents";
import customTypes from "../enums/contentsCustomTypes.enum";
export var divider = {
    label: "Divider",
    type: customTypes.DIVIDER,
    icon: "horizontal-rule",
    editionModalPosition: "top",
    category: categories.STRUCTURE,
    children: []
};

export const swapArrayItems = (array, index1, index2) => {
  const newArray = [...array];
  if (index1 !== -1 && index2 !== -1) {
    const tmpItem = newArray[index1];
    newArray[index1] = newArray[index2];
    newArray[index2] = tmpItem;
  }
  return newArray;
};

export default { swapArrayItems };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
var SideBox = function (props) {
    var _a, _b, _c;
    var bottom = props.bottom, fillBox = props.fillBox, matches = props.matches;
    var theme = useTheme();
    return (_jsx(Box, { sx: __assign(__assign({}, (_c = (_b = (_a = theme === null || theme === void 0 ? void 0 : theme.components) === null || _a === void 0 ? void 0 : _a.SideBox) === null || _b === void 0 ? void 0 : _b.styleOverrides) === null || _c === void 0 ? void 0 : _c.root), { bottom: bottom, background: fillBox ? "#3B29AB" : "", display: matches ? "inherit" : "none" }) }, void 0));
};
export default SideBox;

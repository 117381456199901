var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
var ContactInformation = function (props) {
    var icon = props.icon, title = props.title, content = props.content, _a = props.withDivider, withDivider = _a === void 0 ? false : _a;
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    return (_jsxs(Box, __assign({ id: "contact-card-" + title.replaceAll(/\s/g, "") }, { children: [_jsxs(Grid, __assign({ container: true, direction: "row", flexWrap: "nowrap", columnGap: 1 }, { children: [_jsx(Box, { children: _jsx("img", { alt: "", src: icon }, void 0) }, void 0), _jsxs(Grid, __assign({ container: true, direction: "column", paddingTop: "4px" }, { children: [_jsx(Typography, __assign({ variant: "h3", sx: { fontSize: "1rem", lineHeight: "1.17rem" } }, { children: title }), void 0), _jsx(Typography, __assign({ variant: "text", component: "div", sx: { pt: 0.5, hyphens: "auto" } }, { children: content }), void 0)] }), void 0)] }), void 0), withDivider && _jsx(Divider, { sx: { my: isMobile ? 2 : 4, backgroundColor: "#DEE1F2" } }, void 0)] }), void 0));
};
export default ContactInformation;

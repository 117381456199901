export var ContentLabelType;
(function (ContentLabelType) {
    ContentLabelType["_GRECAPTCHA"] = "_GRECAPTCHA";
    ContentLabelType["LOCAL_STORAGE"] = "Local Storage";
    ContentLabelType["MTM_CONSENT_REMOVED"] = "mtm_consent_removed";
    ContentLabelType["MATOMO_SESSID"] = "MATOMO_SESSID";
    ContentLabelType["ALLOW_THE_PROPER_FUNCTIONING_OF_THE_SITE"] = "Permettre le bon fonctionnement du site";
})(ContentLabelType || (ContentLabelType = {}));
export var cookiesRows = [
    {
        id: 1,
        name: ContentLabelType.LOCAL_STORAGE,
        finality: "Données stockées sur votre navigateur afin d'enregistrer vos choix concernant l'utilisation des cookies. Ces données restent sur votre navigateur et ne sont jamais transmises.",
        conservation: "Valide jusqu'à la suppression des données en cache"
    },
    {
        id: 2,
        name: ContentLabelType.ALLOW_THE_PROPER_FUNCTIONING_OF_THE_SITE,
        finality: "Durée de validité des cookies : 24h",
        conservation: ""
    },
    {
        id: 4,
        name: ContentLabelType.MTM_CONSENT_REMOVED,
        finality: "Ce cookie est utilisé pour vérifier si la mesure d'aucience a été refusée par l'utilisateur",
        conservation: "30 jours"
    },
    {
        id: 3,
        name: ContentLabelType._GRECAPTCHA,
        finality: "Ce cookie est utilisé pour distinguer les humains des robots lors de l'envoi des formulaires et l'utilisation des liens mailto",
        conservation: "Valide jusqu'à la suppression des données en cache"
    },
    {
        id: 5,
        name: ContentLabelType.MATOMO_SESSID,
        finality: "Ce cookie est utilisé pour stocker l'id de session de l'outil de mesure d'audience",
        conservation: "14 jours"
    }
];
export var getDataStaticForConsent = function () {
    var index = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        index[_i] = arguments[_i];
    }
    return cookiesRows.filter(function (hook) { return index.includes(hook.name); });
};
export var setDataStaticConsentFromFront = function (hasCaptcha) {
    var consentData = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        consentData[_i - 1] = arguments[_i];
    }
    return ({
        consentData: getDataStaticForConsent.apply(void 0, consentData),
        captcha: hasCaptcha ? getDataStaticForConsent(ContentLabelType._GRECAPTCHA) : null
    });
};

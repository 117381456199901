import types from "cms/enums/contentsTypes.enum";
export var step = {
    label: "Étape",
    type: types.STEP,
    children: [
        {
            label: "title",
            key: "title",
            type: types.TEXT,
            value: ""
        },
        {
            label: "content",
            key: "content",
            type: types.TEXT,
            value: ""
        }
    ]
};

// import React from "react";
import PropTypes from "prop-types";
// import customTypes from "components/customTypes";

const getEditorComponent = ({ content /* handleContentChange, value, id */ }) => {
  const { type } = content;
  switch (type) {
    default:
      return null;
  }
};

getEditorComponent.propTypes = {
  content: PropTypes.shape().isRequired,
  value: PropTypes.shape().isRequired,
  id: PropTypes.string.isRequired,
  handleContentChange: PropTypes.func.isRequired
};

export default getEditorComponent;

import React from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";

const TextAreaForm = props => {
  const { content, onContentChange, formSubmitted, regexValidation, ...others } = props;

  const { id, label, value } = content;

  const handleTextareaChange = e => {
    onContentChange({
      ...content,
      value: e.target.value
    });
  };

  return (
    <FormControl key={id}>
      <TextField label={label} value={value} onChange={handleTextareaChange} multiline rows={3} {...others} />
    </FormControl>
  );
};

TextAreaForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool,
  regexValidation: PropTypes.string
};

TextAreaForm.defaultProps = {
  formSubmitted: false,
  regexValidation: null
};

export default TextAreaForm;

import uuidv4 from "uuid/v4";
import types from "cms/enums/contentsTypes.enum";
import categories from "cms/enums/categoriesContents";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import { urlRegex } from "cms/utils/commonUtils";
import { adminContents as contents } from "cms/contents/adminContents";

const getAllContents = () => {
  const projectContents = (CmsHooks.getHook(HOOKS.adminContentsUtils_contents) || []).map(projectContent => ({
    ...projectContent,
    category: projectContent.category || categories.SPECIFIC
  }));
  const projectTypes = projectContents.map(c => c.type);
  const contentsFiltered = contents.filter(c => !projectTypes.includes(c.type));
  return [...projectContents, ...contentsFiltered];
};

// Get a random number for ids
export const generateContentId = (content, updatedIds = {}) => {
  const newId = uuidv4();
  if (content.id && typeof updatedIds === "object") {
    // eslint-disable-next-line no-param-reassign
    updatedIds[content.id] = newId;
  }
  const contentWithId = { ...content, id: newId };
  if (content && content.children) {
    contentWithId.children = content.children.map(child => generateContentId(child, updatedIds));
  }
  return contentWithId;
};

export const enhanceContent = (content = {}) => {
  const { type, children = [] } = content;
  const defaultContent = getAllContents().find(c => c.type === type);
  const { dynamicChildKey } = defaultContent || {};

  const enhancedContent = {
    ...defaultContent,
    ...content
  };

  // enchanced children
  const enhancedChildren = children.map(child => {
    const defaultChild = ((defaultContent && defaultContent.children) || []).find(c => c.key === child.key) || {};
    return enhanceContent({
      ...defaultChild,
      ...child
    });
  });

  // add defaults children not founds
  ((defaultContent && defaultContent.children) || []).forEach(defaultChild => {
    const defaultChildAlreadyExists = enhancedChildren.find(c => c.key === defaultChild.key);
    if ((defaultChild.value !== undefined || defaultChild.children) && !defaultChildAlreadyExists) {
      enhancedChildren.push(generateContentId(enhanceContent(defaultChild)));
    }
  });

  if (defaultContent && defaultContent.children) {
    enhancedContent.children = enhancedChildren.filter(
      child =>
        (dynamicChildKey && child.key === dynamicChildKey) ||
        (defaultContent.children || []).find(defaultChild => child.key === defaultChild.key)
    );
  } else {
    enhancedContent.children = enhancedChildren;
  }

  return {
    ...enhancedContent,
    value: enhancedContent.value || ""
  };
};

export const replacePagesByIds = (content, updatedIds = {}) => {
  const { type, value, children } = content;
  const newContent = { ...content };

  if ([types.PAGE, types.SUBPAGE].includes(type) && typeof value === "object") {
    newContent.value = value.page ? value.page.id : "";
  } else if (type === types.CONTENTS_GROUP) {
    newContent.children = children.map(child => {
      if (child.key === "contents") {
        return {
          ...child,
          value: updatedIds[child.value] || child.value,
          children: []
        };
      }
      return child;
    });
  } else if (children && children.length) {
    newContent.children = children.map(child => replacePagesByIds(child, updatedIds));
  }
  return newContent;
};

export const getDefaultContentByType = type => enhanceContent(getAllContents().find(content => content.type === type));

export const getAllDefaultContents = () => getAllContents().map(enhanceContent);

export const adminProperties = [
  "label",
  "options",
  "editionModal",
  "editionModalPosition",
  "isTemplateSettings",
  "min",
  "max",
  "step",
  "category",
  "icon",
  "requiredByKey",
  "regexValidation",
  "width",
  "tooltip",
  "limit",
  "dynamicChildKey",
  "updatedAt"
];

// Used to check form values. ------------------------------------------------
const checkChildHasFormValidation = content => {
  return content.regexValidation || content.requiredByKey;
};
const checkChildRegexValidation = content => {
  return !content.regexValidation || !content.value || content.value.match(content.regexValidation);
};
const checkFileUrlIsValid = content => {
  if (content.key === "file") {
    const valueParse = content.value ? JSON.parse(content.value) : "";
    const { url = "" } = valueParse;
    return url.match(urlRegex);
  }
  return true;
};

export const isValidContent = (content, keys) => {
  if (!keys && !checkChildHasFormValidation(content) && (!content.children || content.children.length === 0))
    return true;
  if (!checkChildRegexValidation(content) || !checkFileUrlIsValid(content)) return false;

  const hasRequiredKeys = content.requiredByKey || keys;
  if (hasRequiredKeys && (content.requiredByKey || keys).includes(content.key)) {
    return !!content.value;
  }
  if (content.children) {
    let childrenValidate = true;
    content.children.forEach(element => {
      if (!isValidContent(element, content.requiredByKey || keys)) childrenValidate = false;
    });
    return childrenValidate;
  }
  return true;
};

export default {};

import React from "react";
import Box from "@mui/material/Box";

const svgIconStyled = {
  display: "inline-block",
  textAlign: "center",
  height: "1em",
  width: "1em",
  "&> svg": {
    verticalAlign: "middle"
  }
};

export default Component => {
  const SVGIconComponent = props => {
    return (
      <Box sx={svgIconStyled}>
        <Component {...props} />
      </Box>
    );
  };

  return SVGIconComponent;
};

import types from "cms/enums/contentsTypes.enum";
export var socialNetwork = {
    label: "social network",
    type: types.SOCIAL_NETWORK,
    value: "",
    children: [
        {
            label: "url",
            key: "url",
            type: types.URL,
            value: ""
        },
        {
            key: "title",
            label: "Titre du lien",
            type: types.INPUT,
            value: ""
        },
        {
            label: "icon",
            key: "icon",
            type: types.ICON,
            value: ""
        }
    ]
};

import React, { useContext } from "react";
import PropTypes from "prop-types";
import contentsTypes from "cms/enums/contentsTypes.enum";
import FilePicker from "cms/components/back-office/FilePicker";
import AdminPageVersionContext from "cms/components/back-office/contexts/AdminPageVersionContext";
import { FormFieldset } from "cms/components/back-office/contentForms/ContentFormStyled";
import ContentFormContext from "cms/components/back-office/contentForms/ContentFormContext";

const ImageForm = props => {
  const { content, onContentChange, formSubmitted } = props;
  const { id, label, children, requiredByKey } = content;

  const { currentPageVersion } = useContext(AdminPageVersionContext);
  const { displayVertical } = useContext(ContentFormContext);

  const fileImageChild = children.find(c => c.type === contentsTypes.FILE_IMAGE);
  const altChild = children.find(c => c.key === "alt");
  const titleChild = children.find(c => c.key === "title");
  const positionHorizontaleChild = children.find(c => c.key === "position_horizontale");
  const positionVerticaleChild = children.find(c => c.key === "position_verticale");
  const positionAjustementChild = children.find(c => c.key === "position_ajustement");
  const imageFiltersChild = children.find(c => c.key === "filters");

  const fileValue = {
    ...((fileImageChild && fileImageChild.value && JSON.parse(fileImageChild.value)) || {}),
    alt: altChild && altChild.value,
    title: titleChild && titleChild.value
  };

  const handleSelectFile = file => {
    const { alt, title, filters, position_horizontale, position_verticale, position_ajustement } = file || {};
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === fileImageChild) {
          return {
            ...child,
            value: file ? JSON.stringify(file) : ""
          };
        }
        if (child === altChild) {
          return {
            ...child,
            value: alt || ""
          };
        }
        if (child === titleChild) {
          return {
            ...child,
            value: title || ""
          };
        }
        if (filters && child === imageFiltersChild) {
          return filters;
        }

        // Position: Horizontal, vertical and fit.
        if (position_horizontale && child === positionHorizontaleChild) {
          return {
            ...child,
            value: position_horizontale || ""
          };
        }
        if (position_verticale && child === positionVerticaleChild) {
          return {
            ...child,
            value: position_verticale || ""
          };
        }
        if (position_ajustement && child === positionAjustementChild) {
          return {
            ...child,
            value: position_ajustement || ""
          };
        }

        return child;
      })
    });
  };

  return (
    <FormFieldset key={id}>
      <legend>{label}</legend>
      <FilePicker
        onSelectFile={handleSelectFile}
        pageVersion={currentPageVersion}
        image
        file={fileValue}
        required={!!requiredByKey}
        formSubmitted={formSubmitted}
        positionHorizontaleChild={positionHorizontaleChild}
        positionVerticaleChild={positionVerticaleChild}
        positionAjustementChild={positionAjustementChild}
        imageFiltersChild={imageFiltersChild}
        displayVertical={displayVertical}
      />
    </FormFieldset>
  );
};

ImageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool
};

ImageForm.defaultProps = {
  formSubmitted: false
};

export default ImageForm;

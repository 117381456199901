import React from "react";
import PropTypes from "prop-types";
import SVGIcon from "cms/components/shared/icons/SVGIcon";

const ExpansionPanelIcon = props => {
  const { color, backgroundColor } = props;

  return (
    <svg viewBox="0 0 55 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.833984" width="53.3333" height="24" rx="2" fill={backgroundColor} />
      <path
        d="M37.0667 8L42.1667 13.0889L47.2667 8L48.8333 9.56667L42.1667 16.2333L35.5 9.56667L37.0667 8Z"
        fill={color}
      />
    </svg>
  );
};

ExpansionPanelIcon.propTypes = {
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
};

export default SVGIcon(ExpansionPanelIcon);

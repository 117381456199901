import React, { useContext, useState, useEffect } from "react";
import Link from "cms/components/front-office/contents/Link";
import Box from "@mui/material/Box";
import Icon from "cms/components/front-office/contents/Icon";
import PageVersionContext from "cms/components/shared/PageVersionContext";
import { useLocation } from "react-router-dom";
import SearchBarHeader from "./SearchBarHeader";
import LogoHeader from "./LogoHeader";

const styleAfterMenuItemActive = {
  top: "50%",
  left: 0,
  transform: "translate(-16px, -50%) rotate(-45deg)",
  width: "8px",
  height: "8px",
  content: "''",
  position: "absolute",
  bgcolor: "primary.main",
  border: "1px solid",
  borderColor: "primary.main",
  borderRadius: "2px"
};

const menuBoxStyled = menuMobileActive => ({
  display: "flex",
  alignItems: "start",
  position: "fixed",
  overflow: "hidden",
  overflowY: "auto",
  flexDirection: "column",
  zIndex: 51,
  bgcolor: "white",
  bottom: 0,
  top: 0,
  right: 0,
  left: menuMobileActive ? 0 : "100%",
  textAlign: "left",
  transition: "all .5s ease-in-out",
  boxShadow: "0px 0px 5px rgba(0,0,0,.2)"
});

const menuItemsStyled = {
  px: "32px",
  listStyleType: "none"
};

const menuItemStyled = {
  textTransform: "none",
  fontSize: "1rem",
  ml: 0,
  fontWeight: "500",
  color: "primary.dark",
  position: "relative"
};

const menuItemActiveStyled = ({ menuItem, isActive }) => ({
  left: isActive(menuItem) && "18px",
  "&::after": isActive(menuItem) && styleAfterMenuItemActive
});

const burgerStyle = {
  display: "block",
  color: "primary.dark",
  fontSize: "1.5rem",
  pr: "12px",
  cursor: "pointer"
};

const closeMenuStyle = {
  color: "primary.dark",
  fontSize: "1.5rem",
  pr: "12px",
  cursor: "pointer"
};

const headerStyle = {
  width: "100%",
  p: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
};

const NavigationMobile = props => {
  const { menuItems, logo } = props;
  const { pathname } = useLocation();
  const { currentPageVersion } = useContext(PageVersionContext);
  const [menuMobileActive, setMenuMobileActive] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const isActive = menuItem => {
    const { link } = menuItem || {};
    const { page } = link || {};
    const { path } = page || {};
    if (currentPageVersion && path) {
      if (path === "/") {
        return currentPageVersion.path === path;
      }
      if (currentPageVersion.fullPath) {
        return currentPageVersion.fullPath.startsWith(path);
      }
    }
    return false;
  };

  const handleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const keypressHandler = e => {
    if (e.code === "Enter") {
      e.target.click();
    }
  };

  useEffect(() => {
    if (pathname !== "/rechercher") setShowSearchBar(false);
  }, [pathname, menuMobileActive]);

  return (
    <nav>
      <Box sx={burgerStyle}>
        <i
          className="fas fa-bars"
          onClick={() => setMenuMobileActive(true)}
          aria-label="Ouvrir le menu mobile"
          onKeyDown={keypressHandler}
          tabindex={0}
          role="button"
        />
      </Box>
      <Box sx={menuBoxStyled(menuMobileActive)}>
        <Box sx={headerStyle}>
          <LogoHeader logo={logo} />
          <Box sx={closeMenuStyle}>
            <i
              className="fal fa-times"
              aria-label="Fermer le menu mobile"
              role="button"
              onKeyDown={keypressHandler}
              tabindex={0}
              onClick={() => setMenuMobileActive(false)}
            />
          </Box>
        </Box>
        <Box component="ul" sx={menuItemsStyled}>
          {!showSearchBar && (
            <>
              {menuItems.map(menuItem => {
                return (
                  <Box
                    component="li"
                    key={"box-menu-item-key-" + menuItem.id}
                    sx={{
                      my: "16px"
                    }}
                  >
                    <Link
                      {...menuItem.link}
                      sx={{ ...menuItemStyled, ...menuItemActiveStyled({ menuItem, isActive }) }}
                      variant="subtitle2"
                      onClick={() => setMenuMobileActive(false)}
                    >
                      {menuItem.title}
                    </Link>
                  </Box>
                );
              })}
              <Box
                sx={{
                  "& > i": {
                    my: "16px",
                    ...menuItemStyled
                  }
                }}
              >
                <Icon icon="search" type="fal" onClick={handleSearchBar} />
              </Box>
            </>
          )}
          <SearchBarHeader isOpen={showSearchBar} toggle={handleSearchBar} isMobile={true} />
        </Box>
      </Box>
    </nav>
  );
};

export default NavigationMobile;

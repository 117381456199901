const categories = {
  STRUCTURE: "STRUCTURE",
  SPECIFIC: "SPECIFIC"
};

export default categories;

export const getCategoryLabel = category => {
  switch (category) {
    case categories.STRUCTURE:
      return "Structure";
    case categories.SPECIFIC:
      return "Composants spécifiques";
    default:
      return "Composants de base";
  }
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import axios from "axios";
import contentsTypes from "cms/enums/contentsTypes.enum";
import generateCRUD, { find } from "cms/services/admin/generateCRUD";
import { generateContentId } from "cms/utils/adminContentsUtils";
import { removeProperties } from "cms/utils/commonUtils";
import { useMutation, useQuery } from "react-query";
var SitesCRUD = generateCRUD("sites");
var childCacheKey = "updated";
var params = {
    title: "test",
    lang: "fr",
    menu: [],
    footer: [],
    header: [],
    home: {
        title: "Accueil",
        shortDescription: "Accueil",
        template: "HOME",
        path: "/",
        contents: []
    },
    contents: []
};
export var useSites = function (options, config) {
    if (config === void 0) { config = {}; }
    var _a = useQuery(["sites", { options: options }], function (context) { return find(context.queryKey[0], options); }, __assign({}, config)), data = _a.data, rest = __rest(_a, ["data"]);
    return __assign({ sites: data }, rest);
};
export var useDuplicateSite = function (config) {
    if (config === void 0) { config = {}; }
    return useMutation(function (site) {
        return axios.post("/api/sites/".concat(site.id, "/duplicate"), removeProperties(site, ["id", "created", "updated", "settings"]));
    }, __assign({}, config));
};
export var createSiteForm = function (siteName, host) {
    var queryParams = [
        __assign({ name: siteName, host: host }, params)
    ];
    return axios.get("/createSite", {
        params: { sites: queryParams }
    });
};
export var createPages = function (site) {
    var queryParams = [
        __assign({ name: site.name, host: site.host, id: site.id }, params)
    ];
    return axios.get("/createPages", {
        params: { site: queryParams }
    });
};
var AdminSitesService = /** @class */ (function (_super) {
    __extends(AdminSitesService, _super);
    function AdminSitesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AdminSitesService.resetSiteCache = function (site) {
        var id = site.id, contents = site.contents;
        var cacheChild = contents.find(function (c) { return c.key === childCacheKey; });
        var newContents = [];
        if (cacheChild) {
            newContents = contents.map(function (c) { return (c.id === cacheChild.id ? __assign(__assign({}, cacheChild), { value: "".concat(new Date()) }) : c); });
        }
        else {
            newContents = __spreadArray(__spreadArray([], contents, true), [
                generateContentId({
                    type: contentsTypes.INPUT,
                    key: childCacheKey,
                    value: "".concat(new Date())
                })
            ], false);
        }
        SitesCRUD.update({ id: id, contents: newContents });
    };
    AdminSitesService.export = function (siteId) {
        return axios.get("/api/sites/".concat(siteId, "/export"), { responseType: "blob" });
    };
    AdminSitesService.import = function (file) {
        return axios.post("/api/sites/import", file);
    };
    return AdminSitesService;
}(SitesCRUD));
export default AdminSitesService;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
var GridItem = function (_a) {
    var item = _a.item, _b = _a.sizeXs, sizeXs = _b === void 0 ? "12" : _b, _c = _a.size, size = _c === void 0 ? "6" : _c;
    return (_jsx(Grid, __assign({ item: true, role: "listitem", xs: parseInt(sizeXs, 10), md: parseInt(size, 10) }, { children: item }), void 0));
};
export default GridItem;

import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const loadingPageStyled = {
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  fontSize: "1.14rem",
  "& .MuiCircularProgress-circle": {
    stroke: "#3787FF",
    strokeLinecap: "round"
  },
  "& > span": {
    color: "#666666",
    mt: 2
  }
};
const LoadingPage = props => {
  const { title } = props;

  return (
    <Box sx={loadingPageStyled}>
      <CircularProgress />
      <span>{title}</span>
    </Box>
  );
};

LoadingPage.propTypes = {
  title: PropTypes.string.isRequired
};

export default LoadingPage;

import types from "cms/enums/contentsTypes.enum";
export var codeBlock = {
    label: "Bloc de code",
    type: types.CODE_BLOCK,
    icon: "code",
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["code"],
    children: [
        {
            label: "Code",
            key: "code",
            type: types.CODE_BLOCK_TEXT,
            value: ""
        },
        {
            label: "Langage",
            key: "language",
            type: types.SELECT,
            options: [
                { label: "C", value: "c" },
                { label: "C++", value: "cpp" },
                { label: "C#", value: "csharp" },
                { label: "CSS", value: "css" },
                { label: "Docker", value: "docker" },
                { label: "Git", value: "git" },
                { label: "HTML", value: "html" },
                { label: "Java", value: "java" },
                { label: "Javascript", value: "javascript" },
                { label: "Json", value: "json" },
                { label: "JSX", value: "jsx" },
                { label: "Less", value: "less" },
                { label: "Objective C", value: "objectivec" },
                { label: "PHP", value: "php" },
                { label: "Python", value: "python" },
                { label: "Regex", value: "regex" },
                { label: "Ruby", value: "ruby" },
                { label: "Sass", value: "sass" },
                { label: "SQL", value: "sql" },
                { label: "Typescript", value: "typescript" },
                { label: "Vim", value: "vim" }
            ],
            value: "javascript"
        },
        {
            label: "Mode sombre",
            key: "isDark",
            type: types.CHECKBOX,
            value: "true"
        }
    ]
};

import types from "cms/enums/contentsTypes.enum";
export var imageFilters = {
    label: "Filtres",
    type: types.IMAGE_FILTERS,
    children: [
        {
            label: "Ajouter des filtres",
            key: "activated",
            type: types.CHECKBOX,
            value: "false"
        },
        {
            label: "Opacité",
            key: "opacity",
            type: types.SLIDER,
            value: "1",
            min: "0",
            max: "1",
            step: "0.05"
        },
        {
            label: "Flou",
            key: "blur",
            type: types.SLIDER,
            value: "0",
            min: "0",
            max: "5",
            step: "0.25"
        },
        {
            label: "Échelle de gris",
            key: "grayscale",
            type: types.SLIDER,
            value: "0",
            min: "0",
            max: "1",
            step: "0.05"
        },
        {
            label: "Luminosité",
            key: "brightness",
            type: types.SLIDER,
            value: "1",
            min: "0",
            max: "3",
            step: "0.1"
        },
        {
            label: "Saturation",
            key: "saturate",
            type: types.SLIDER,
            value: "1",
            min: "0",
            max: "3",
            step: "0.2"
        },
        {
            label: "Sépia",
            key: "sepia",
            type: types.SLIDER,
            value: "0",
            min: "0",
            max: "1",
            step: "0.05"
        }
    ]
};

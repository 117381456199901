import ContainerPreview from "cms/components/back-office/contentsPreview/ContainerPreview";
import ContainerIcon from "cms/components/shared/icons/ContainerIcon";
import categories from "cms/enums/categoriesContents";
import types from "cms/enums/contentsTypes.enum";
export var container = {
    label: "Conteneur",
    type: types.CONTAINER,
    category: categories.STRUCTURE,
    IconComponent: ContainerIcon,
    editionModal: true,
    editionModalPosition: "top",
    Preview: ContainerPreview,
    dynamicChildKey: "containerElement",
    children: [
        {
            key: "justifyContent",
            label: "Alignement",
            type: types.SELECT,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" }
            ],
            value: "center"
        },
        {
            label: "Largeur du contenu (en %)",
            key: "width",
            type: types.SLIDER,
            value: "50",
            min: "0",
            max: "100",
            step: "10"
        },
        {
            label: "Largeur du contenu (en %) sur mobile",
            key: "mobileWidth",
            type: types.SLIDER,
            min: "0",
            max: "100",
            step: "10"
        }
    ]
};

import types from "cms/enums/contentsTypes.enum";
export var tabs = {
    label: "Onglets",
    type: types.TABS,
    children: [
        {
            label: "tabs",
            key: "tabs",
            type: types.TAB
        }
    ]
};

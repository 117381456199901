import axios from "axios";
import axiosCatchError from "./AxiosCatchErrorImplement";
import gedMiddlewareEndpoint from "./gedMiddlewareEndpoint";

let accountIdPromise = null;

export default class AuthService {
  static getMyAccountId = () => {
    if (!accountIdPromise) {
      accountIdPromise = axios
        .get(`${gedMiddlewareEndpoint}/accounts`)
        .then(res => {
          return res.data && res.data.owner;
        })
        .catch(error => {
          axiosCatchError(error);
          accountIdPromise = null;
        });
    }
    return accountIdPromise;
  };
}

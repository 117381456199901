var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import React from "react";
// Components. -----------------------------------------------------------------
// Tools. ----------------------------------------------------------------------
import { icsDateFormat } from "utils/icsDateFormat";
// Theme/Style. ----------------------------------------------------------------
var cardStyled = {
    bgcolor: "cobalt.white",
    my: 2,
    p: { xs: 2.5, md: 2 },
    pb: { xs: 5.5, md: 2 }
};
var dateStyled = { fontWeight: 900, lineHeight: "1.25rem" };
var iconStyled = { mr: 1, color: "greyColor.main" };
var AgendaItem = function (props) {
    var event = props.event, others = __rest(props, ["event"]);
    var _a = event.description, description = _a === void 0 ? "" : _a, _b = event.endDate, endDate = _b === void 0 ? "" : _b, _c = event.location, location = _c === void 0 ? "" : _c, _d = event.startDate, startDate = _d === void 0 ? "" : _d, _e = event.summary, summary = _e === void 0 ? "" : _e;
    var _f = React.useState(false), expanded = _f[0], setExpanded = _f[1];
    // Functions. ----------------------------------------------------------------
    var displayDateTime = function (icsDateTime) {
        return (_jsx(_Fragment, { children: _jsx(Grid, __assign({ item: true }, { children: _jsxs(Grid, __assign({ container: true, alignItems: "center" }, { children: [_jsx(CobaltIcon, { fontSize: "1rem", name: "clock", sx: iconStyled }, void 0), _jsx(Typography, __assign({ variant: "textMedium", sx: { color: "primary.main" } }, { children: icsDateFormat(icsDateTime, "time") }), void 0)] }), void 0) }), void 0) }, void 0));
    };
    var date = dayjs(icsDateFormat(startDate, "date"))
        .locale("fr")
        .format("dddd D MMMM")
        .toUpperCase()
        .split(" ");
    // Render. -------------------------------------------------------------------
    return (_jsxs(Accordion, __assign({}, others, { disableGutters: true, expanded: expanded, sx: cardStyled, onChange: function () {
            setExpanded(!expanded);
        } }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(CobaltIcon, { "data-testid": "chevron-down-icon", fontSize: "1.2rem", name: "chevron-down", sx: { color: "primary.main" } }, void 0), sx: {
                    p: 0,
                    bgcolor: "white",
                    position: "initial",
                    "& .MuiAccordionSummary-content": { m: 0 },
                    "& .MuiAccordionSummary-expandIconWrapper": { position: { xs: "absolute", md: "initial" }, bottom: 14 }
                } }, { children: _jsxs(Grid, __assign({ container: true, direction: "row", justifyContent: "center" }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, md: 3, lg: 2, container: true, justifyContent: "center", alignItems: "center", rowSpacing: { md: 0.5 }, sx: { pt: { md: 2.5 }, pb: 2.5, mr: { md: 2 }, flexDirection: { xs: "row", md: "column" } } }, { children: [_jsx(Grid, __assign({ item: true, sx: { mr: 0.75 } }, { children: _jsxs(Typography, __assign({ variant: "h2", color: "primary", sx: dateStyled }, { children: [date[0], " ", date[1]] }), void 0) }), void 0), _jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ variant: "h2", sx: dateStyled }, { children: date[2] }), void 0) }), void 0)] }), void 0), _jsx(Divider, { sx: {
                                borderBottomWidth: { xs: "thin", md: 0 },
                                borderRightWidth: { xs: 0, md: "thin" },
                                width: { xs: "100%", md: 0 },
                                mb: { xs: 2, md: 0 },
                                my: { md: 0.875 }
                            } }, void 0), _jsxs(Grid, __assign({ item: true, xs: 12, md: true, container: true, direction: "column", justifyContent: "space-evenly", rowSpacing: { xs: 1.25, md: 0 }, sx: { ml: { md: 2.5 } } }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ variant: "h2" }, { children: summary }), void 0) }), void 0), _jsxs(Grid, __assign({ item: true, container: true, alignItems: "center" }, { children: [displayDateTime(startDate), _jsx(CobaltIcon, { fontSize: "1rem", name: "arrow-right", sx: { ml: 2, mr: 1, color: "greyColor.main" } }, void 0), displayDateTime(endDate)] }), void 0), _jsx(Grid, __assign({ item: true, container: true, alignItems: "center" }, { children: _jsxs(Typography, __assign({ variant: "textMedium", sx: { display: "flex" } }, { children: [_jsx(CobaltIcon, { fontSize: "1rem", name: "map-pin", sx: iconStyled }, void 0), location.split("\\").join("")] }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx(AccordionDetails, __assign({ sx: { p: 0, pt: 1.25 } }, { children: expanded && (_jsx(Typography, __assign({ variant: "text", sx: { color: "greyColor.main" } }, { children: description }), void 0)) }), void 0)] }), void 0));
};
export default AgendaItem;

var ActionPositions;
(function (ActionPositions) {
    ActionPositions["TOP_RIGHT"] = "topRightActions";
    ActionPositions["RIGHT"] = "rightActions";
    ActionPositions["BOTTOM_RIGHT"] = "bottomRightActions";
    ActionPositions["BOTTOM"] = "bottomActions";
    ActionPositions["BOTTOM_LEFT"] = "bottomLeftActions";
    ActionPositions["LEFT"] = "leftActions";
    ActionPositions["TOP_LEFT"] = "topLeftActions";
    ActionPositions["TOP"] = "topActions";
})(ActionPositions || (ActionPositions = {}));
export default ActionPositions;

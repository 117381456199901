import types from "cms/enums/contentsTypes.enum";
export var menuItem = {
    label: "Lien",
    editionModal: true,
    type: types.MENU_ITEM,
    children: [
        {
            label: "title",
            key: "title",
            type: types.INPUT,
            value: ""
        },
        {
            label: "link",
            key: "link",
            type: types.LINK,
            value: ""
        },
        {
            label: "icon",
            key: "icon",
            type: types.ICON,
            value: ""
        }
    ]
};

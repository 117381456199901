import types from "cms/enums/contentsTypes.enum";
export var glossaryItem = {
    label: "Définition",
    type: types.GLOSSARY_ITEM,
    // editionModal: true,
    children: [
        {
            key: "word",
            label: "Mot / Acronyme",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Description",
            key: "description",
            type: types.SHORT_TEXT,
            value: ""
        }
    ]
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
// Components. -----------------------------------------------------------------
import Image from "cms/components/front-office/contents/Image";
import PresentationRow from "./PresentationRow";
// =============================================================================
// PresentationRowPreview...
// =============================================================================
var PresentationRowPreview = function () {
    // Render. -------------------------------------------------------------------
    return (_jsx(Box, __assign({ sx: { width: "100%" } }, { children: _jsx(PresentationRow, { title: "Titre de la presentation", description: "Zone de texte libre", image: _jsx(Image, { file: { url: "/default-image.jpg" }, disableLazy: true }, void 0), dynamicPartAfterDescription: null }, void 0) }), void 0));
};
export default PresentationRowPreview;

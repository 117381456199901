import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Modal, { ModalActions } from "cms/components/back-office/Modal";
import { enhanceContent, isValidContent } from "cms/utils/adminContentsUtils";
import ContentForm from "cms/components/back-office/contentForms/ContentForm";
import ContentFormContext from "cms/components/back-office/contentForms/ContentFormContext";
import { AdminActionsContext } from "cms/components/back-office/admin/AdminActions";
import ThemeBackOfficeProvider from "cms/theme/ThemeBackOfficeProvider";
import { Button, Drawer } from "@mui/material";
import { debounce } from "cms/utils/commonUtils";

const AdminContentModal = props => {
  const { open, drawer, title, content, onValidate, onContentChange, onClose, ...others } = props;
  const [fullContent, setFullContent] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { id } = content || {};
  const { hideActions } = React.useContext(AdminActionsContext);

  // Init... -------------------------------------------------------------------
  useEffect(() => {
    if (open && !fullContent) {
      setFullContent(enhanceContent(content));
    } else if (!open) {
      setFullContent(null);
    }
  }, [open, content, fullContent]);
  useEffect(() => {
    if (open) {
      setFormSubmitted(false);
    }
  }, [open]);

  // handleSubmit: used in creation mode. --------------------------------------
  const handleSubmit = e => {
    e.preventDefault();
    setFormSubmitted(true);
    let formIsValid = true;
    if (fullContent.children) {
      if (!isValidContent(fullContent)) formIsValid = false;
    }
    if (formIsValid && onValidate !== null) onValidate(fullContent);
  };

  // handleContentChange: used to previsualize update. -------------------------
  const handleContentChange = newContent => {
    setFullContent(newContent);
    if (onContentChange !== null && isValidContent(fullContent)) {
      debounce(() => onContentChange(newContent), 350)();
    }
  };

  // Select Drawer or Modal according to drawer prop. --------------------------
  const Container = React.useMemo(() => {
    const close = () => {
      if (typeof hideActions === "function") hideActions();
      onClose();
    };
    if (drawer) {
      return ({ children, ...othersProps }) => (
        <Drawer
          transitionDuration={0}
          anchor="right"
          BackdropProps={{ sx: { bgcolor: "rgba(0,0,0,0.4)" } }}
          open={open}
          onClose={close}
          PaperProps={{
            sx: {
              width: "325px",
              pr: "10px",
              pl: "10px",
              border: "none",
              borderRadius: "0px"
            }
          }}
          {...othersProps}
        >
          {children}
        </Drawer>
      );
    } else {
      return ({ children, ...othersProps }) => (
        <Modal
          aria-labelledby="admin-content"
          aria-describedby="content-administration"
          open={open}
          title={title}
          onClose={onClose}
          size="md"
          {...othersProps}
        >
          {children}
        </Modal>
      );
    }
  }, [drawer, onClose, open, title, hideActions]);

  // Display Drawer or Modal formular. -----------------------------------------
  if (!fullContent) {
    return null;
  }
  return (
    <ThemeBackOfficeProvider>
      <Container key={id} {...others}>
        <form onSubmit={handleSubmit}>
          <ContentFormContext.Provider value={{ displayVertical: drawer }}>
            <ContentForm
              topLevel
              content={fullContent}
              onContentChange={handleContentChange}
              formSubmitted={formSubmitted}
            />
          </ContentFormContext.Provider>
          <ModalActions>
            {drawer ? (
              <IconButton
                onClick={onClose}
                sx={{ position: "absolute", top: "8px", right: "2px", color: "secondary.text" }}
              >
                <CloseIcon sx={{ width: "30px", height: "30px" }} />
              </IconButton>
            ) : (
              <>
                <Button color="secondary" onClick={onClose}>
                  Annuler
                </Button>
                <Button type="submit" color="primary">
                  {content && content.id ? "Mettre à jour" : "Créer"}
                </Button>
              </>
            )}
          </ModalActions>
        </form>
      </Container>
    </ThemeBackOfficeProvider>
  );
};

AdminContentModal.propTypes = {
  drawer: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.shape(),
  onValidate: PropTypes.func,
  onContentChange: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

AdminContentModal.defaultProps = {
  drawer: false,
  open: false,
  title: null,
  content: null,
  onValidate: null,
  onContentChange: null
};

export default AdminContentModal;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, useTheme } from "@mui/material";
var ElementGraphique = function () {
    var theme = useTheme();
    var boxStyled = {
        position: "relative",
        background: {
            xs: "none"
        },
        borderRadius: "9px",
        top: {
            xs: "10px",
            md: "40px"
        },
        padding: {
            xs: "0",
            md: "56px 130px"
        },
        pl: {
            xs: "0",
            md: "270px"
        },
        textAlign: {
            xs: "center",
            md: "left"
        },
        zIndex: 3
    };
    var additionalBoxContourSecond = {
        boxSizing: "border-box",
        position: "absolute",
        width: "300px",
        height: "128px",
        left: "1002px",
        border: "1px solid #3B29AB",
        top: "82px",
        borderRadius: theme.student ? "1000px" : "9px",
        zIndex: 3,
        "@media (max-width: 1382px)": {
            color: "transparent",
            border: "transparent"
        }
    };
    var additionalBoxStyled = {
        position: "absolute",
        width: "200px",
        height: "182px",
        backgroundColor: { xs: "transparent", md: "#3B29AB" },
        borderRadius: theme.student ? "1000px" : "9px",
        top: "150px",
        right: "-50px",
        transform: "translateY(-50%)",
        zIndex: 1,
        "@media (max-width: 1382px)": {
            color: "transparent"
        }
    };
    var additionalBoxContour = {
        boxSizing: "border-box",
        position: "absolute",
        width: "288px",
        height: "256px",
        left: "955px",
        border: "1px solid #3B29AB",
        top: "23px",
        borderRadius: "16px",
        zIndex: 3,
        "@media (max-width: 1382px)": {
            color: "transparent",
            border: "transparent"
        }
    };
    var hideOnSmallScreen = {
        "@media (max-width: 1382px)": {
            display: "none",
            color: "transparent"
        }
    };
    return (_jsx(Box, __assign({ sx: hideOnSmallScreen }, { children: _jsxs(Box, __assign({ sx: boxStyled }, { children: [_jsx(Box, { sx: additionalBoxContourSecond, id: "additionalBoxContourSecond" }, void 0), _jsx(Box, { sx: additionalBoxStyled, id: "additionalBoxStyled" }, void 0), theme.professor && _jsx(Box, { sx: additionalBoxContour, id: "additionalBoxContour" }, void 0)] }), void 0) }), void 0));
};
export default ElementGraphique;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
import LoadingPage from "cms/components/back-office/Layout/LoadingPage";
import { useSites } from "cms/services/admin/AdminSitesService";
import { queryParamPageKey, queryParamSiteKey } from "cms/utils/queryParamsKeys";
import { createQueryParams, getQueryParams } from "cms/utils/urlUtils";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CreateSiteForm from "../contentForms/CreateSiteForm";
import AdminWebsiteContext from "./AdminWebsiteContext";
var currentSiteStorageKey = "currentSite";
export var AdminWebsiteContextProvider = function (props) {
    var children = props.children;
    var pathname = useLocation().pathname;
    var historyPush = useHistory().push;
    var _a = getQueryParams(), _b = queryParamSiteKey, siteId = _a[_b];
    var _c = useSites({
        params: {
            sort: "name,ASC"
        }
    }), _d = _c.sites, sites = _d === void 0 ? [] : _d, refetchSites = _c.refetch, isFetching = _c.isFetching, status = _c.status;
    useEffect(function () {
        var _a;
        if (!isFetching && (!siteId || !sites.find(function (s) { return s.id === siteId; }))) {
            var id_1 = localStorage.getItem(currentSiteStorageKey);
            var site = id_1 && sites.find(function (s) { return s.id === id_1; }) ? sites.find(function (s) { return s.id === id_1; }) : sites[0];
            historyPush(pathname + "?" + createQueryParams((_a = {},
                _a[queryParamSiteKey] = site === null || site === void 0 ? void 0 : site.id,
                _a)));
        }
    }, [isFetching, siteId, sites]);
    var currentSite = React.useMemo(function () { return sites === null || sites === void 0 ? void 0 : sites.find(function (site) { return site.id === siteId; }); }, [sites, siteId]);
    var handleChangeSite = React.useCallback(function (site, page) {
        var _a, _b;
        var id = site.id;
        localStorage.setItem(currentSiteStorageKey, id);
        historyPush(pathname + "?" + createQueryParams(__assign((_a = {}, _a[queryParamSiteKey] = id, _a), (page ? (_b = {}, _b[queryParamPageKey] = page.id, _b) : {}))));
    }, [historyPush, pathname]);
    if (status === "success" && !sites.length) {
        return (_jsx(Box, __assign({ sx: {
                display: "flex",
                height: "100vh",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "1.14rem"
            } }, { children: _jsx(CreateSiteForm, { refetchSites: refetchSites }, void 0) }), void 0));
    }
    if (!currentSite) {
        return _jsx(LoadingPage, { title: "Chargement des sites" }, void 0);
    }
    return (_jsx(AdminWebsiteContext.Provider, __assign({ value: {
            currentSite: currentSite,
            setCurrentSite: handleChangeSite,
            refreshSite: refetchSites,
            allSites: sites
        } }, { children: children }), void 0));
};

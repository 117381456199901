var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { createBackOfficeTheme, getCurrentThemeType, saveBackOfficeTheme } from "cms/theme/themeBackOffice";
import { createContext, useEffect, useState } from "react";
export var ThemeContext = createContext({ setThemeType: function () { return null; }, themeBackOffice: null });
var ThemeBackOfficeProvider = function (props) {
    var _a = props.children, children = _a === void 0 ? null : _a;
    var themeType = getCurrentThemeType();
    var _b = useState(), theme = _b[0], setTheme = _b[1];
    useEffect(function () {
        setTheme(createBackOfficeTheme(themeType));
    }, [themeType]);
    useEffect(function () {
        setTheme(createBackOfficeTheme(getCurrentThemeType()));
    }, []);
    var handleChangeTheme = function (type) {
        saveBackOfficeTheme(type);
        setTheme(createBackOfficeTheme(type));
    };
    if (!theme)
        return null;
    return (_jsx(ThemeContext.Provider, __assign({ value: { setThemeType: handleChangeTheme, themeBackOffice: theme } }, { children: _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: children }), void 0) }), void 0) }), void 0));
};
export default ThemeBackOfficeProvider;

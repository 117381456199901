var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid } from "@mui/material";
// =============================================================================
// GridBox...
// =============================================================================
var containerStyled = function (_a) {
    var selected = _a.selected, option = _a.option;
    return ({
        height: "55px",
        bgcolor: "secondary.main",
        py: 0.25,
        m: 0.25,
        border: "2px solid",
        borderColor: selected === option.value ? "primary.main" : "secondary.main"
    });
};
var itemStyled = {
    bgcolor: "form.text",
    height: "100%",
    mx: 0.25
};
export var GridBox = function (_a) {
    var option = _a.option, selected = _a.selected, onClickBox = _a.onClickBox;
    //Render. --------------------------------------------------------------------
    return (_jsx(Grid, __assign({ container: true, direction: "row", justifyContent: "flex-start", alignItems: "center", sx: containerStyled({ selected: selected, option: option }), onClick: function () { return onClickBox(option.value); } }, { children: __spreadArray([], Array(+option.label), true).map(function (_, idx) { return (_jsx(Grid, __assign({ item: true, xs: true, sx: itemStyled }, { children: "\u00A0" }), idx)); }) }), void 0));
};
export default GridBox;

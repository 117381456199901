import React, { useState, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { SiteContextProvider } from "cms/components/shared/SiteContext";
import AdminWebsiteContext from "cms/components/back-office/contexts/AdminWebsiteContext";
import AdminTemplateSettings from "cms/components/back-office/AdminTemplateSettings/AdminTemplateSettings";
import AdminTemplateSettingsContext from "cms/components/back-office/AdminTemplateSettings/AdminTemplateSettingsContext";
import generateEditablePage, {
  generateTemplatePropsFromContents as generateTemplatePropsFromAdminContents
} from "cms/utils/templateAdminUtils";
import { enhancePageVersionContents, getTemplateComponentByKey } from "cms/utils/templateUtils";
import Page from "cms/components/front-office/Page";
import PageVersionContext from "cms/components/shared/PageVersionContext";
import generateTemplatePropsFromContents from "cms/utils/templatePropsUtils";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import contentsTypes from "cms/enums/contentsTypes.enum";
import AdminPageVersionContext from "cms/components/back-office/contexts/AdminPageVersionContext";

const ckeBordersStyled = {
  "& .cke_editable, & input,  & textarea, & pre": {
    bgcolor: "transparent",
    fontSize: "inherit",
    fontWeight: "inherit",
    fontFamily: "inherit",
    lineHeight: "inherit",
    textAlign: "inherit",
    letterSpacing: "inherit",
    width: "100%"
  },
  "& .cke_editable, & input,  & textarea": {
    border: "1px dashed #999"
  },
  "& pre": {
    whiteSpace: "pre-wrap"
  }
};

const RenderPage = props => {
  const Layout = CmsHooks.getHook(HOOKS.App_Layout) || (({ children }) => children);

  const { page: pageProps, handlePageChange, handleTemplateSettingsChange, editionMode } = props;

  const page = React.useMemo(() => enhancePageVersionContents(pageProps), [pageProps]);

  const [displayAdminTemplateSettingsModal, setDisplayAdminTemplateSettingsModal] = useState(false);

  const { currentSite } = useContext(AdminWebsiteContext);
  const { currentPageVersion, setCurrentPageVersion } = useContext(AdminPageVersionContext);
  const { contents = [] } = currentSite;

  const getTemplatesSettings = useCallback(() => {
    return (contents && contents.find(c => c.type === contentsTypes.TEMPLATES_SETTINGS)) || {};
  }, [contents]);

  const { template } = page || {};

  const getTemplateSettings = useCallback(() => {
    const templatesSettings = getTemplatesSettings();
    const currentTemplateSettings = templatesSettings.children
      ? templatesSettings.children.find(c => c.key === template)
      : null;

    if (currentTemplateSettings && currentTemplateSettings.children) {
      const currentTemplateSettingsChildren = [];
      currentTemplateSettings.children.forEach(c => {
        currentTemplateSettingsChildren.push({ ...c, isTemplateSettings: true });
      });
      return { ...currentTemplateSettings, children: currentTemplateSettingsChildren };
    }

    return currentTemplateSettings;
  }, [template, getTemplatesSettings]);

  const [templateIsModify, setTemplateIsModify] = useState(false);
  const [previousTemplateSettings, setPreviousTemplateSettings] = useState(getTemplateSettings());
  const [currentTemplateSettings, setCurrentTemplateSettings] = useState(getTemplateSettings());

  useEffect(() => {
    setPreviousTemplateSettings(getTemplateSettings());
    setCurrentTemplateSettings(getTemplateSettings());
  }, [getTemplateSettings]);

  const generateTemplateSettingsProps = () => {
    if (editionMode) return generateTemplatePropsFromAdminContents(currentTemplateSettings.children);
    return generateTemplatePropsFromContents(currentTemplateSettings.children);
  };
  const templateSettingsProps = currentTemplateSettings ? generateTemplateSettingsProps() : {};

  const currentTemplate = React.useMemo(() => {
    const templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
    return templates.find(t => t.key === template);
  }, [template]);

  const editablePage = React.useMemo(() => {
    if (!page) {
      return null;
    }
    return generateEditablePage(page, handlePageChange, currentTemplate);
  }, [currentTemplate, handlePageChange, page]);

  if (!page) {
    return null;
  }

  const TemplateTag = getTemplateComponentByKey(template);

  const handleChangeTemplateSettings = newTemplateSettings => {
    setTemplateIsModify(true);
    setCurrentTemplateSettings(newTemplateSettings);
  };

  const handleValidateTemplateSettingsChange = () => {
    const templatesSettings = getTemplatesSettings();

    handleTemplateSettingsChange({
      ...templatesSettings,
      children: templatesSettings.children.map(t => (t.key === template ? currentTemplateSettings : t))
    });
  };

  const handleCancelTemplateSettingsChange = () => {
    setCurrentTemplateSettings(previousTemplateSettings);
  };

  return (
    <SiteContextProvider site={currentSite}>
      <PageVersionContext.Provider value={{ currentPageVersion, setCurrentPageVersion }}>
        <Layout bo>
          <Box sx={ckeBordersStyled}>
            {editionMode ? (
              <AdminTemplateSettingsContext.Provider value={{ setDisplayAdminTemplateSettingsModal }}>
                <AdminTemplateSettings
                  templateSettings={currentTemplateSettings}
                  handleChangeTemplateSettings={handleChangeTemplateSettings}
                  displayModal={displayAdminTemplateSettingsModal}
                  setDisplayModal={setDisplayAdminTemplateSettingsModal}
                  handleValidateTemplateSettingsChange={handleValidateTemplateSettingsChange}
                  handleCancelTemplateSettingsChange={handleCancelTemplateSettingsChange}
                  templateIsModify={templateIsModify}
                  setTemplateIsModify={setTemplateIsModify}
                />
                <TemplateTag page={editablePage} {...templateSettingsProps} />
              </AdminTemplateSettingsContext.Provider>
            ) : (
              <Page page={page} templatesSettings={templateSettingsProps} />
            )}
          </Box>
        </Layout>
      </PageVersionContext.Provider>
    </SiteContextProvider>
  );
};

RenderPage.propTypes = {
  page: PropTypes.shape(),
  handlePageChange: PropTypes.func.isRequired,
  editionMode: PropTypes.bool.isRequired,
  handleTemplateSettingsChange: PropTypes.func.isRequired
};

RenderPage.defaultProps = {
  page: null
};

export default RenderPage;

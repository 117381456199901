import React, { useState, useCallback, useEffect, useContext } from "react";
import { useKeycloak } from "@react-keycloak/web";
import PropTypes from "prop-types";
import AdminVersions from "cms/components/back-office/AdminVersions";
import AdminWebsiteContext from "cms/components/back-office/contexts/AdminWebsiteContext";
import AdminSectionsService from "cms/services/admin/AdminSectionsService";
import sectionsTypes from "cms/enums/sectionsTypes";
import { PageVersionStatus } from "cms/types/cms/page/version/page-version.status.enum";
import useEnvironment from "cms/hooks/useEnvironment";
import { SectionVersionRole } from "cms/types/cms/common/roles.enum";

const AdminSection = props => {
  const { title, type, children, onSectionChanged } = props;

  const { keycloak } = useKeycloak();
  const { keycloak_client_cms } = useEnvironment();

  const canPublish = keycloak.hasResourceRole(SectionVersionRole.UPDATE_SECTION_VERSION_STATUS, keycloak_client_cms);
  const canUpdate = keycloak.hasResourceRole(SectionVersionRole.UPDATE_SECTION_VERSION, keycloak_client_cms);
  const canDelete = keycloak.hasResourceRole(SectionVersionRole.DELETE_SECTION_VERSION, keycloak_client_cms);

  const [sectionsVersions, setSectionsVersions] = useState([]);
  const [currentSection, setCurrentSection] = useState(null);
  const [sectionForm, setSectionForm] = useState(null);

  useEffect(() => {
    setSectionForm(currentSection);
  }, [currentSection]);

  const { currentSite } = useContext(AdminWebsiteContext);

  const getSections = useCallback(() => {
    return AdminSectionsService.getSectionVersions({ type, siteId: currentSite.id }).then(sections => {
      sections.sort(({ status: status1 }, { status: status2 }) => {
        if (status1 === status2 || ![status1, status2].includes(PageVersionStatus.ARCHIVED)) {
          return 0;
        }
        return status1 === PageVersionStatus.ARCHIVED ? 1 : -1;
      });
      setSectionsVersions(sections);
      return sections;
    });
  }, [currentSite.id, type]);

  const setCurrentSectionVersion = useCallback(
    (section = {}) =>
      getSections().then(sections => {
        setCurrentSection((sections && sections.find(s => s.id === section.id)) || sections[0]);
      }),
    [getSections]
  );

  useEffect(() => {
    setCurrentSectionVersion();
  }, [setCurrentSectionVersion]);

  const sectionChangedCallback = useCallback(
    response => {
      if (typeof onSectionChanged === "function") {
        onSectionChanged(response);
      }
      return response;
    },
    [onSectionChanged]
  );

  const handleSaveVersion = section => {
    if (section.status === PageVersionStatus.DRAFT) {
      return AdminSectionsService.update(section)
        .then(sectionChangedCallback)
        .catch(() => {
          setCurrentSectionVersion(section);
          // displayError("Erreur lors de la mise à jour du brouillon");
        });
    }
    return AdminSectionsService.create(section)
      .then(sectionChangedCallback)
      .catch(() => {
        setCurrentSectionVersion(section);
        // displayError("Erreur lors de la création du brouillon");
      });
  };

  const updateVersionStatus = ({ version, status }) => {
    return AdminSectionsService.updateStatus(version, status)
      .then(sectionChangedCallback)
      .catch(() => {
        // displayError("Erreur lors de la mise à jour du statut de la version");
      });
  };

  const deleteVersion = version => AdminSectionsService.delete(version).then(sectionChangedCallback);

  return (
    <>
      {sectionForm && (
        <AdminVersions
          title={title}
          versions={sectionsVersions}
          currentVersion={sectionForm}
          setCurrentVersion={setCurrentSectionVersion}
          onVersionChange={setSectionForm}
          saveVersion={handleSaveVersion}
          canUnpublish={false}
          canPublish={canPublish}
          canUpdate={canUpdate}
          canDelete={canDelete}
          canRename={canUpdate}
          updateStatus={updateVersionStatus}
          deleteVersion={deleteVersion}
        >
          {({ handleVersionChange }) => children({ section: sectionForm, onSectionChange: handleVersionChange })}
        </AdminVersions>
      )}
    </>
  );
};

AdminSection.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(sectionsTypes)).isRequired,
  children: PropTypes.func.isRequired,
  onSectionChanged: PropTypes.func
};

AdminSection.defaultProps = {
  onSectionChanged: null
};

export default AdminSection;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
var PartenaireElementsGraphique = function () {
    var _a = useState(8000), windowWidth = _a[0], setWindowWidth = _a[1];
    var theme = useTheme();
    useEffect(function () {
        var handleResize = function () { return setWindowWidth((1228 + window.innerWidth) / 2); };
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    var isScreenSizeValid = useMediaQuery("(min-width: 1391px)");
    var mainContainerStyles = {
        position: "relative",
        width: windowWidth + "px"
    };
    var topPositions = ["112px", "133px", "150px"];
    var boxStyle = {
        position: "absolute",
        width: "118px",
        height: "118px",
        right: "-59px",
        border: "1px solid #3B29AB",
        transform: "rotate(-44.67deg)",
        display: isScreenSizeValid ? "block" : "none"
    };
    return (_jsx(Box, __assign({ sx: mainContainerStyles }, { children: topPositions.map(function (top, index) { return (_jsx(Box, { sx: __assign(__assign({}, boxStyle), { top: top, background: index === 1 ? "#3B29AB" : null, borderRadius: theme.student ? "1000px" : index === 1 ? "11px" : "7px" }) }, index.toString())); }) }), void 0));
};
export default PartenaireElementsGraphique;

export default {
    AGENDA: "AGENDA",
    CARD_BOX_1: "CARD_BOX_1",
    CARD_BOX_2: "CARD_BOX_2",
    GEOGRAPHIC_MAP: "GEOGRAPHIC_MAP",
    PARTNERS: "PARTNERS",
    PRESENTATION_BOX: "PRESENTATION_BOX",
    PRESENTATION_ROW: "PRESENTATION_ROW",
    LAST_ARTICLES_BOX: "LAST_ARTICLES_BOX",
    SIMPLE_LINK: "SIMPLE_LINK",
    TEAM_UNIT: "TEAM_UNIT",
    SUB_PAGE_REDIRECT: "SUB_PAGE_REDIRECT",
    SUBPAGE_SEARCH: "SUBPAGE_SEARCH",
    NEWSLETTER: "NEWSLETTER",
    SLIDER2: "SLIDER2",
    DIVIDER: "DIVIDER",
    IFRAME: "IFRAME"
};

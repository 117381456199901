import React from "react";
import { Box } from "@mui/material";
import SideBar, { SideBarContextProvider } from "cms/components/back-office/Layout/SideBar";

const LayoutBackV2 = props => {
  const { children } = props;

  return (
    <SideBarContextProvider>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <SideBar />
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </Box>
    </SideBarContextProvider>
  );
};

export default LayoutBackV2;

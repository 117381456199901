import React from "react";
import PropTypes from "prop-types";
import SVGIcon from "cms/components/shared/icons/SVGIcon";

const ContainerIcon = props => {
  const { backgroundColor } = props;

  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33333 2.66667H8V0H5.33333V2.66667ZM5.33333 24H8V21.3333H5.33333V24ZM10.6667 24H13.3333V21.3333H10.6667V24ZM0 24H2.66667V21.3333H0V24ZM0 18.6667H2.66667V16H0V18.6667ZM0 13.3333H2.66667V10.6667H0V13.3333ZM0 8H2.66667V5.33333H0V8ZM0 2.66667H2.66667V0H0V2.66667ZM21.3333 18.6667H24V16H21.3333V18.6667ZM21.3333 13.3333H24V10.6667H21.3333V13.3333ZM21.3333 24H24V21.3333H21.3333V24ZM21.3333 8H24V5.33333H21.3333V8ZM21.3333 0V2.66667H24V0H21.3333ZM10.6667 2.66667H13.3333V0H10.6667V2.66667ZM16 24H18.6667V21.3333H16V24ZM16 2.66667H18.6667V0H16V2.66667Z"
        fill={backgroundColor}
      />
    </svg>
  );
};

ContainerIcon.propTypes = {
  backgroundColor: PropTypes.string.isRequired
};

export default SVGIcon(ContainerIcon);

import axios from "axios";

class SearchService {
  static search = ({ query, from, size, siteId }) => {
    return axios({
      method: "POST",
      url: "/search",
      data: { query, from, size, siteId }
    }).then(response => response.data);
  };
}

export default SearchService;

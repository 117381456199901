var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Backdrop, Box, Button, Typography } from "@mui/material";
import { ModalActions } from "cms/components/back-office/Modal";
import Loader from "cms/components/shared/Loader";
import NodesListing from "da-ged-web/components/NodesListing/NodesListing";
import NodesPicker from "da-ged-web/components/NodesListing/NodesPicker";
import FullToolbar from "da-ged-web/components/Toolbar/FullToolbar";
var StyledToolbar = {
    'div[class*="Toolbar-root"], .MuiToolbar-root': {
        paddingBottom: 2
    },
    ".MuiBreadcrumbs-ol": {
        color: "typography.default",
        ".MuiTypography-root": {
            color: "typography.default",
            fontWeight: "bold"
        },
        ".MuiBreadcrumbs-li:not(:last-child)": {
            ".MuiTypography-root": {
                "&:hover": {
                    textDecoration: "underline"
                }
            }
        }
    },
    ".MuiInput-root": {
        backgroundColor: "form.inputBackgroundColor",
        border: "initial"
    }
};
var StyledNodesListing = {
    flex: "1",
    ".MuiPaper-root": {
        backgroundColor: "secondary.main",
        "svg path": {
            color: "layout.icon"
        }
    },
    ".MuiButton-contained": {
        backgroundColor: "transparent",
        color: "typography.default"
    },
    'div[class$="-selecting"]': {
        "&.MuiPaper-root": {
            backgroundColor: "primary.main",
            color: "#FFFFFF"
        }
    }
};
var GEDModal = function () { return (_jsxs(Box, { sx: {
        height: "85vh",
        display: "flex",
        flexDirection: "column"
    }, children: [_jsx(Box, { sx: StyledToolbar, children: _jsx(FullToolbar, {}) }), _jsx(Box, { sx: StyledNodesListing, children: _jsx(NodesListing, { tableProps: {
                    rowHeight: 35
                } }) })] })); };
export var GEDNodesPicker = function (props) {
    var onCancel = props.onCancel, onSubmit = props.onSubmit, onSelectionChange = props.onSelectionChange, _a = props.loading, loading = _a === void 0 ? false : _a, _b = props.disabled, disabled = _b === void 0 ? false : _b;
    return (_jsxs("div", { children: [_jsxs(Box, { sx: __assign(__assign({ height: "75vh" }, StyledToolbar), StyledNodesListing), children: [_jsx(NodesPicker, { tableProps: {
                            rowHeight: 35
                        }, onSelectionChange: onSelectionChange }), _jsxs(Backdrop, { sx: {
                            backgroundColor: "rgba(255, 255, 255, 0.6)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }, open: loading, children: [_jsx(Loader, {}), _jsx(Typography, { align: "center", children: "Chargement du fichier" })] })] }), _jsxs(ModalActions, { children: [_jsx(Button, { color: "secondary", onClick: onCancel, children: "Annuler" }), _jsx(Button, { color: "primary", onClick: onSubmit, disabled: disabled, children: "Valider" })] })] }));
};
export default GEDModal;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider } from "@mui/material";
import { Fragment } from "react";
import ArticleCardHorizontal from "templates/articles/ArticleCardHorizontal";
var ArticleSubpage = function (props) {
    var articles = props.articles, _a = props.small, small = _a === void 0 ? false : _a;
    return (_jsx(_Fragment, { children: articles === null || articles === void 0 ? void 0 : articles.map(function (article, index) {
            return index < 3 && (_jsxs(Fragment, { children: [_jsx(ArticleCardHorizontal, { page: article, small: small }, void 0), index < 2 && _jsx(Divider, { sx: { height: "1px", width: "100%" } }, void 0)] }, index));
        }) }, void 0));
};
export default ArticleSubpage;

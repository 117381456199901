import { createContext } from "react";
export var defaultContext = {
    buttonLabel: "Gérer des groupes de contenus",
    title: "Gérer des groupes de contenus",
    subTitle: "",
    types: [],
    getLabelFromComponent: function (component) { return component.key; },
    getLabelFromContent: function (content) { return content.key; }
};
export default createContext(defaultContext);

import types from "cms/enums/contentsTypes.enum";
export var contentsGroup = {
    label: "contents group",
    type: types.CONTENTS_GROUP,
    value: "",
    children: [
        {
            label: "name",
            type: types.INPUT,
            key: "name",
            value: ""
        },
        {
            label: "contents",
            key: "contents"
        }
    ]
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import SwipeableViews from "react-swipeable-views";
var containerStyled = {
    width: "100%",
    pt: "32px",
    flexDirection: "row",
    justifyItems: "center",
    textAlign: "center",
    position: "relative"
};
var imageStyled = {
    position: "absolute",
    left: "-112px",
    top: "-20px",
    display: { xs: "none", lg: "inline" }
};
function a11yProps(index) {
    return {
        id: "simple-tab-" + index
        // "aria-controls": `simple-tabpanel-${index}`
    };
}
var PartnerTabs = function (props) {
    var _a = props.tabs, tabs = _a === void 0 ? [] : _a;
    var _b = React.useState(0), value = _b[0], setValue = _b[1];
    var matches = useMediaQuery("(max-width:900px)");
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    if (!Array.isArray(tabs)) {
        tabs = [tabs];
    }
    return (_jsxs(Box, __assign({ sx: containerStyled }, { children: [_jsx(Box, __assign({ sx: imageStyled }, { children: _jsx("img", { alt: "", src: "/team_meeting.svg" }, void 0) }), void 0), _jsxs(Box, __assign({ sx: { pt: "24px" } }, { children: [_jsx(Box, __assign({ sx: {
                            borderBottom: 1,
                            borderColor: "divider",
                            display: { sm: "inherit", md: "inline-block" }
                        } }, { children: _jsx(Tabs, __assign({ variant: matches ? "scrollable" : "standard", value: value, onChange: handleChange, role: "tablist", "aria-label": "tabs partenaires", centered: matches ? false : true }, { children: tabs.map(function (tab, index) { return (_jsx(Tab, __assign({ label: tab.title }, a11yProps(index), { sx: { textTransform: "none" } }), tab.id)); }) }), void 0) }), void 0), _jsx(SwipeableViews, __assign({ index: value, onChangeIndex: handleChange }, { children: tabs.map(function (tab, index) {
                            var children = tab.children, othersTabProps = __rest(tab, ["children"]);
                            return (_createElement(Box, __assign({}, othersTabProps, { role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-" + index, "aria-labelledby": "simple-tab-" + index, key: index.toString() }), value === index && _jsx(Box, __assign({ sx: { pt: 3 } }, { children: children }), void 0)));
                        }) }), void 0)] }), void 0)] }), void 0));
};
export default PartnerTabs;

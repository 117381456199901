var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
var ImageContainer = function (props) {
    var ratio = props.ratio, _a = props.children, children = _a === void 0 ? null : _a;
    if (Number.isNaN(parseFloat(ratio.toString()))) {
        return children;
    }
    return (_jsx(Box, __assign({ sx: {
            pt: ratio + "%",
            position: "relative",
            flex: "1 1 0px",
            "@media print": {
                display: "none"
            }
        } }, { children: _jsx(Box, __assign({ sx: {
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%"
            } }, { children: children }), void 0) }), void 0));
};
export default ImageContainer;

import contentsTypes from "cms/enums/contentsTypes.enum";
import CardBox1Preview from "../components/CardBox1/CardBox1Preview";
import customTypes from "../enums/contentsCustomTypes.enum";
export var newsletter = {
    label: "Newsletter",
    type: customTypes.NEWSLETTER,
    icon: "mailbox",
    editionModal: true,
    editionModalPosition: "top",
    Preview: CardBox1Preview,
    children: [
        {
            label: "Titre",
            key: "title",
            type: contentsTypes.INPUT,
            value: "S'inscrire à notre Newsletter"
        },
        {
            label: "Sous titre",
            key: "subtitle",
            type: contentsTypes.INPUT,
            value: "Inscris toi et reçois tous les mois les dernières informations."
        },
        {
            label: "Lien",
            key: "link",
            type: contentsTypes.LINK,
            value: ""
        },
        {
            label: "Texte Réseaux sociaux",
            key: "textRS",
            type: contentsTypes.INPUT,
            value: "Suivez-nous sur les réseaux"
        },
        {
            label: "N° de la liste sendinblue",
            key: "listId",
            type: contentsTypes.INPUT_NUMBER,
            value: "2"
        },
        {
            label: "Image",
            key: "image",
            type: contentsTypes.IMAGE,
            value: ""
        }
    ]
};

import contentsTypes from "cms/enums/contentsTypes.enum";
import DynamicTemplate from "templates/dynamic/DynamicTemplate";

export default {
  key: "DYNAMIC",
  label: "Standard",
  templateImage: "/template-dynamic.png",
  Component: DynamicTemplate,
  initialContents: [
    {
      key: "dynamicPart",
      type: contentsTypes.DYNAMIC,
      value: ""
    }
  ]
};

import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "../enums/contentsCustomTypes.enum";
export var partners = {
    label: "Partenaires",
    type: customTypes.PARTNERS,
    editionModal: true,
    editionModalPosition: "top",
    icon: "handshake",
    children: [
        {
            label: "Titre",
            key: "title",
            type: contentsTypes.EDITABLE_INPUT,
            value: ""
        },
        {
            label: "Description",
            key: "description",
            type: contentsTypes.TEXT,
            value: ""
        },
        {
            label: "Onglets",
            key: "tabs",
            type: contentsTypes.TABS,
            value: ""
        }
    ]
};

import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MuiModal from "@mui/material/Modal";
import SimpleBar from "simplebar-react";

export const ModalActions = props => (
  <Grid
    container
    justifyContent="flex-end"
    sx={{
      "&> *": {
        ml: 1
      }
    }}
    {...props}
  />
);

const wrapperStyled = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

const mainStyled = ({ theme, size }) => ({
  width: `${size && theme.breakpoints.values[size] ? `${theme.breakpoints.values[size]}px` : "auto"}`,
  maxWidth: "100%",
  maxHeight: "100%",
  overflow: "auto",
  "& .simplebar-track.simplebar-vertical .simplebar-scrollbar:before ": {
    bgcolor: "layout.scrollbar"
  }
});

const Modal = props => {
  const { children, onClose, size, title, ...others } = props;

  return (
    <MuiModal onClose={onClose} {...others}>
      <Box sx={wrapperStyled} onClick={onClose}>
        <Paper onClick={e => e.stopPropagation()} sx={theme => mainStyled({ theme, size })}>
          <SimpleBar style={{ maxHeight: "90vh" }}>
            {title && (
              <>
                <Box pl={2}>
                  <h2>{title}</h2>
                </Box>
                <Divider />
              </>
            )}
            <Box p={2}>{children}</Box>
          </SimpleBar>
        </Paper>
      </Box>
    </MuiModal>
  );
};

Modal.propTypes = {
  ...MuiModal.propTypes,
  title: PropTypes.string
};

Modal.defaultProps = {
  title: null,
  className: "",
  children: null,
  size: "md"
};

export default Modal;

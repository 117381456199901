var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AdminContentModal from "cms/components/back-office/admin/AdminContentModal";
import contentsTypes from "cms/enums/contentsTypes.enum";
import { enhanceContent, generateContentId, getDefaultContentByType } from "cms/utils/adminContentsUtils";
import { useCallback, useState } from "react";
import AdminMenuList from "./AdminMenuList";
var defaultMenuItemModal = {
    open: false,
    menuItem: null,
    onValidate: function (item) { }
};
var AdminMenuForm = function (props) {
    var menu = props.menu, onMenuChange = props.onMenuChange, canUpdate = props.canUpdate, itemKey = props.itemKey, subItemKey = props.subItemKey, maxDepth = props.maxDepth, currentDepth = props.currentDepth;
    var _a = menu.contents, contents = _a === void 0 ? [] : _a;
    var _b = useState(currentDepth === maxDepth), selectContent = _b[0], setSelectContent = _b[1];
    var _c = useState(defaultMenuItemModal), menuItemModal = _c[0], setMenuItemModal = _c[1];
    var itemModalOpen = menuItemModal.open, onValidateItem = menuItemModal.onValidate, currentMenuItem = menuItemModal.menuItem;
    var closeMenuItemModal = useCallback(function () { return setMenuItemModal(defaultMenuItemModal); }, []);
    var handleItemsChange = function (menuItems) {
        onMenuChange(__assign(__assign({}, menu), { contents: menuItems }));
    };
    var handleItemChange = function (item) {
        var id = item.id;
        onMenuChange(__assign(__assign({}, menu), { contents: contents.map(function (c) { return (c.id !== id ? c : item); }) }));
    };
    var getAvailableContents = function (depth) {
        if (depth === void 0) { depth = currentDepth; }
        var availableContents = [getDefaultContentByType(contentsTypes.MENU_ITEM)];
        if (depth < maxDepth) {
            availableContents.push(getDefaultContentByType(contentsTypes.MENU_GROUP));
        }
        return availableContents;
    };
    var handleClickAddContent = function (onValidate, depth) {
        if (depth === void 0) { depth = 0; }
        var availableContents = getAvailableContents(depth);
        if (availableContents.length > 1) {
            setSelectContent(true);
        }
        else if (availableContents.length === 1) {
            handleSelectContent(availableContents[0], onValidate);
        }
    };
    var handleSelectContent = function (content, onValidate) {
        if (onValidate === void 0) { onValidate = addMenuItem; }
        if (content === null || content === void 0 ? void 0 : content.editionModal) {
            setMenuItemModal({
                open: true,
                menuItem: enhanceContent(content),
                onValidate: function (e) {
                    onValidate(e);
                    closeMenuItemModal();
                }
            });
        }
        else {
            onValidate(content);
        }
    };
    var handleClickEditMenuItem = function (menuItem) {
        setMenuItemModal({
            open: true,
            menuItem: enhanceContent(menuItem),
            onValidate: function (newItem) {
                handleItemChange(newItem);
                closeMenuItemModal();
            }
        });
    };
    var handleClickDelete = function (childId) {
        handleItemsChange(__spreadArray([], contents.filter(function (c) { return c.id !== childId; }), true));
    };
    var addMenuItem = function (menuItem) {
        handleItemsChange(__spreadArray(__spreadArray([], contents, true), [generateContentId(__assign({ key: itemKey }, menuItem))], false));
    };
    return (_jsxs("div", { children: [_jsx(AdminMenuList, { items: contents, itemKey: itemKey, subItemKey: subItemKey, maxDepth: maxDepth, currentDepth: currentDepth, canUpdate: canUpdate, saveItem: handleItemChange, onItemsChange: handleItemsChange, clickEditMenuItem: handleClickEditMenuItem, clickDeleteMenuItem: handleClickDelete }, "admin-menu-form"), canUpdate && (_jsxs(Box, { sx: { mt: currentDepth === 0 ? "12px" : 0 }, children: [!selectContent ? (_jsx(Button, { onClick: function () { return handleClickAddContent(addMenuItem, 0); }, sx: { borderRadius: 0 }, size: "small", children: "+ Ajouter un \u00E9l\u00E9ment" })) : (_jsx(Grid, { container: true, spacing: 2, children: getAvailableContents().map(function (content) { return (_jsx(Grid, { item: true, children: _jsxs(Button, { onClick: function () {
                                    handleSelectContent(content);
                                    setSelectContent(currentDepth === maxDepth);
                                }, sx: { borderRadius: 0 }, size: "small", children: ["+ ", content.label] }) })); }) })), _jsx(AdminContentModal, { open: itemModalOpen, content: currentMenuItem, onValidate: onValidateItem, onClose: closeMenuItemModal })] }))] }));
};
export default AdminMenuForm;

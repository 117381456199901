import types from "cms/enums/contentsTypes.enum";
export var icon = {
    label: "Icône",
    type: types.ICON,
    value: "",
    children: [
        {
            label: "Nom",
            key: "icon",
            type: types.INPUT,
            value: ""
        },
        {
            label: "Type",
            key: "type",
            type: types.SELECT,
            options: [
                { label: "Solid", value: "fas" },
                { label: "Regular", value: "far" },
                { label: "Light", value: "fal" },
                { label: "Duotone", value: "fad" },
                { label: "Brands", value: "fab" }
            ],
            value: "fal"
        },
        {
            label: "Couleur",
            type: types.COLOR,
            key: "color",
            value: ""
        },
        {
            label: "Couleur de fond",
            type: types.COLOR,
            key: "background",
            value: ""
        }
    ]
};

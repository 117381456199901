import React from "react";
import PropTypes from "prop-types";
import SelectPage from "cms/components/back-office/SelectPage";
import { FormTitle } from "cms/components/back-office/contentForms/ContentFormStyled";

const SubpageForm = props => {
  const { content, onContentChange, withLabel, canBeEmpty } = props;

  const { id, label } = content;

  const handleSelectPage = p => {
    if (p && p.id) {
      const { versions, ...others } = p;
      versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
      const [lastVersion] = versions;
      if (lastVersion) {
        const pageVersion = {
          ...lastVersion,
          page: { ...others }
        };

        onContentChange({
          ...content,
          value: pageVersion
        });
      }
    } else if (!p || !p.id) {
      onContentChange({
        ...content,
        value: ""
      });
    }
  };

  const SelectPageInput = (
    <SelectPage
      currentPage={content.value && content.value.page}
      onSelectPage={handleSelectPage}
      canBeEmpty={canBeEmpty}
      label={label}
      onlyPublishedPages
    />
  );

  if (withLabel) {
    return (
      <div key={id} style={{ maxWidth: 400, marginBottom: "0.875rem" }}>
        <FormTitle>{label}</FormTitle>
        {SelectPageInput}
      </div>
    );
  }
  return SelectPageInput;
};

SubpageForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  withLabel: PropTypes.bool,
  canBeEmpty: PropTypes.bool
};

SubpageForm.defaultProps = {
  withLabel: true,
  canBeEmpty: false
};

export default SubpageForm;

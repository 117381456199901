var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import Glider from "react-glider";
import ArticleCard from "templates/articles/ArticleCard";
var ArticlesCarousel = function (_a) {
    var articles = _a.articles;
    React.useEffect(function () {
        if (window) {
            // eslint-disable-next-line global-require
            require("glider-js/glider.min.css");
        }
    }, []);
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (_jsx(Grid, __assign({ container: true, columnSpacing: isMobile ? 0 : 6 }, { children: isMobile ? (_jsx(Glider, __assign({ skipTrack: true, draggable: true, slidesToScroll: 1, slidesToShow: "auto", dragVelocity: 1.5, itemWidth: 240, hasArrows: true, hasDots: true }, { children: _jsx("div", __assign({ className: "grider-track" }, { children: articles.map(function (article, index) { return (_jsx(Box, __assign({ ml: index === 0 ? 0 : 1.25, mr: index === articles.length - 1 ? 0 : 1.25 }, { children: _jsx(ArticleCard, { page: article }, void 0) }), article.id)); }) }), void 0) }), void 0)) : (articles.map(function (article) { return _jsx(ArticleCard, { page: article }, article.id); })) }), void 0));
};
export default ArticlesCarousel;

import axios from "axios";
import Node, { FOLDER, FILE } from "da-ged-web/models/Node";
import Owner from "da-ged-web/models/Owner";
import AuthService from "./AuthService";
import axiosCatchError from "./AxiosCatchErrorImplement";
import gedMiddlewareEndpoint from "./gedMiddlewareEndpoint";

const nodeAttributeMap = {
  id: "id",
  name: "name",
  size: "size",
  lastUpdate: "updated"
};

const getSize = size => {
  if (size >= 1048576) {
    return `${parseInt(size / 1048576, 10)} Mo`;
  }
  if (size >= 1024) {
    return `${parseInt(size / 1024, 10)} Ko`;
  }
  return `${size} octets`;
};

const formatNode = (node, accountId) => {
  const ownerName = node.owner === accountId ? "myAccount" : "FirstName Last Name"; // À corriger après l'ajout d'une requête correspondante

  const lastUpdate = node.updated ? node.updated.split(".") : null;

  return new Node({
    type: node.type,
    mimeType: node.mimeType ? node.mimeType : null,
    id: node.id,
    name: node.name,
    size: node.size ? getSize(node.size) : 0,
    lastUpdate: lastUpdate ? new Date(lastUpdate[0]).toLocaleDateString("fr-FR") : null,
    owner: new Owner({ name: ownerName, id: node.owner }),
    erasable: true,
    parentId: node.parentId
    // parentName: parent.identifierKey === "root" ? "Mes documents" : parent.name
  });
};

export default class NodeService {
  static deleteAll = () => {
    return axios.delete(`${gedMiddlewareEndpoint}/trash`).catch(error => axiosCatchError(error));
  };

  static fetchGedItems = ({ pageSize, pageIndex, sortBy, order, search, nodeId, folderOnly, trash } = {}) => {
    return new Promise(async resolve => {
      const accountId = await AuthService.getMyAccountId();

      const per_page = pageSize || 10;
      const offset = pageIndex * per_page;

      const queryParams = {
        sort:
          (sortBy && typeof sortBy === "string" && `${nodeAttributeMap[sortBy]},${order.toUpperCase() || "ASC"}`) || "",
        per_page,
        offset
      };

      if (search) {
        queryParams.filter = [`name||cont||${search}`];
      }

      // Récupération des dossiers
      let folders = {};

      if (trash) {
        folders = await axios.get(`${gedMiddlewareEndpoint}/trash/folders`, {
          params: queryParams
        });
      } else {
        folders = await axios.get(`${gedMiddlewareEndpoint}/folders/${nodeId || "root"}/folders`, {
          params: queryParams
        });
      }

      const { data: folderData } = folders;

      const formatedFolders =
        folderData?.data?.map(fo => {
          const folder = fo;
          folder.type = FOLDER;
          return formatNode(folder, accountId);
        }) || [];

      // Si (formatedFolders.length === pageSize) alors on a que les dossier sur cette page et donc on n'a pas besoin de récupérer les fichiers

      if (formatedFolders.length === pageSize || folderOnly) {
        resolve({ data: formatedFolders });
      } else {
        // Récupération des fichiers

        // Calcul des première et deuxième pages des fichiers
        const firstPage = pageIndex - Math.floor(folderData?.total / per_page);
        const missingNodesCount = (pageSize - (folderData?.total % pageSize)) % pageSize;
        const secondPage = missingNodesCount !== 0 && firstPage > 0 ? firstPage + 1 : false;

        let filesResult = [];

        if (firstPage >= 0) {
          queryParams.offset = firstPage * per_page;

          let files = {};

          if (trash) {
            files = await axios.get(
              `${gedMiddlewareEndpoint}/trash/files`,

              {
                params: queryParams
              }
            );
          } else {
            files = await axios.get(
              `${gedMiddlewareEndpoint}/folders/${nodeId || "root"}/files`,

              {
                params: queryParams
              }
            );
          }

          filesResult = files?.data?.data || [];
        }
        if (secondPage !== false) {
          queryParams.offset = secondPage * per_page;

          let files = {};

          if (trash) {
            files = await axios.get(`${gedMiddlewareEndpoint}/trash/files`, {
              params: queryParams
            });
          } else {
            files = await axios.get(`${gedMiddlewareEndpoint}/folders/${nodeId || "root"}/files`, {
              params: queryParams
            });
          }

          filesResult = [...filesResult, ...(files?.data?.data || [])];

          filesResult.splice(0, missingNodesCount);
          filesResult = filesResult.slice(0, pageSize);
        }

        if (firstPage === 0 && missingNodesCount) {
          filesResult = filesResult.slice(0, missingNodesCount);
        }

        const formatedFiles = filesResult.map(f => {
          const file = f;
          file.type = FILE;
          return formatNode(file, accountId);
        });
        resolve({ data: [...formatedFolders, ...formatedFiles] });
      }
    });
  };

  static downloadFilesAndFolders = elements => {
    const arrayFilesId = [];
    const arrayFoldersId = [];
    elements.forEach(elt => {
      if (elt.type === FILE) {
        arrayFilesId.push(elt.id);
      } else if (elt.type === FOLDER) {
        arrayFoldersId.push(elt.id);
      }
    });

    return Promise.all([arrayFilesId, arrayFoldersId]).then(promise => {
      const [filesId, foldersId] = promise;
      return axios
        .post(`${gedMiddlewareEndpoint}/nodes/archive`, {
          filesId,
          foldersId
        })
        .then(archiveUrl => {
          return axios
            .get(`${gedMiddlewareEndpoint}${archiveUrl.data.downloadUrl}`, {
              responseType: "blob"
            })
            .then(response => {
              return response.data;
            })
            .catch(error => axiosCatchError(error));
        })
        .catch(error => axiosCatchError(error));
    });
  };

  static moveFilesAndFolder = (elements, parent) => {
    return Promise.all(
      elements.map(elt => {
        if (elt.type === "FOLDER") {
          return axios
            .patch(`${gedMiddlewareEndpoint}/folders/${elt.id}`, { parentId: parent?.id || null })
            .then(res => {
              return res;
            })
            .catch(err => {
              return axiosCatchError(err);
            });
        }
        if (elt.type === "FILE") {
          return axios
            .patch(`${gedMiddlewareEndpoint}/files/${elt.id}`, { parentId: parent.id || null })
            .then(res => {
              return res;
            })
            .catch(err => {
              return axiosCatchError(err);
            });
        }
        return elt;
      })
    );
  };
}

import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Slider } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { FormLabelTooltip } from "cms/components/back-office/contentForms/ContentFormStyled";

const SliderForm = props => {
  const { content, onContentChange, tooltip, ...others } = props;

  const { id, label, value, min, max, step } = content;

  const handleChange = (event, newValue) => {
    onContentChange({
      ...content,
      value: String(newValue)
    });
  };

  return (
    <FormControl key={id}>
      <InputLabel sx={{ pointerEvents: "auto" }}>
        {label}
        {tooltip && (
          <FormLabelTooltip title={tooltip}>
            <HelpIcon />
          </FormLabelTooltip>
        )}
      </InputLabel>

      <Slider
        value={Number(value)}
        valueLabelDisplay="auto"
        step={Number(step)}
        marks
        min={Number(min)}
        max={Number(max)}
        onChange={handleChange}
        sx={{
          mt: 2,
          mb: 0,
          mx: "auto",
          width: "90%"
        }}
        {...others}
      />
    </FormControl>
  );
};

SliderForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string
};

SliderForm.defaultProps = {
  tooltip: null
};

export default SliderForm;

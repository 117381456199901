import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

const SubtitleBlock = ({ subtitle }) => (
  <Grid container alignItems="center" columnSpacing={2} sx={{ mb: 2 }}>
    <Grid item>
      <Typography variant="h6" sx={{ color: "primary.light" }}>
        {subtitle}
      </Typography>
    </Grid>
    <Grid item xs>
      <Divider sx={{ bgcolor: "primary.light" }} />
    </Grid>
  </Grid>
);

export default SubtitleBlock;

import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "enums/contentsCustomTypes.enum";

// eslint-disable-next-line react/prop-types
const getDynamicAdminContentsTypes = ({ type }) => {
  switch (type) {
    case contentsTypes.EXPANSION_PANEL_DETAILS:
    case contentsTypes.DYNAMIC:
      return [
        customTypes.GEOGRAPHIC_MAP,
        customTypes.DIVIDER,
        customTypes.LAST_ARTICLES_BOX,
        customTypes.NEWSLETTER,
        customTypes.PARTNERS,
        customTypes.PRESENTATION_ROW,
        customTypes.AGENDA,
        customTypes.SLIDER2,
        customTypes.IFRAME
      ];
    case contentsTypes.GRID:
      return [customTypes.CARD_BOX_1, customTypes.CARD_BOX_2, customTypes.PRESENTATION_BOX, customTypes.TEAM_UNIT];
    case contentsTypes.CONTAINER:
      return [customTypes.NEWSLETTER, customTypes.PRESENTATION_ROW, customTypes.AGENDA, customTypes.SLIDER2];
    default:
      return [];
  }
};

export default getDynamicAdminContentsTypes;

import types from "cms/enums/contentsTypes.enum";
import severitiesTypes from "cms/enums/severitiesTypes";
export var warningBanner = {
    type: types.WARNING_BANNER,
    label: "Bandeau d'information",
    children: [
        {
            label: "enable",
            key: "enable",
            type: types.CHECKBOX,
            value: "false"
        },
        {
            label: "message",
            key: "message",
            type: types.TEXT,
            value: ""
        },
        {
            key: "severity",
            type: types.SELECT,
            value: severitiesTypes.ERROR.key,
            label: "Type d'information",
            options: Object.keys(severitiesTypes).map(function (severity) { return ({
                label: severitiesTypes[severity].label,
                value: severitiesTypes[severity].key
            }); })
        }
    ]
};

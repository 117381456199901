import unorm from "unorm";

export const getQueryParams = (url = window.location.href) => {
  const params = {};
  const search = decodeURI(url)
    .split("?")
    .slice(1)
    .join("?");

  search.split("&").forEach(param => {
    if (param) {
      const [key, value = ""] = param.split("=");
      if (!params[key]) {
        if (value && value.indexOf(",") !== -1) {
          params[key] = value.split(",");
        } else {
          params[key] = value;
        }
      }
    }
  });

  return params;
};

export const createQueryParams = (params = {}) => {
  if (typeof params !== "object") {
    return "";
  }
  return Object.keys(params)
    .map(key => {
      if (Array.isArray(params[key])) {
        return `${key}=${params[key].join(",")}`;
      }
      return params[key] !== undefined ? `${key}=${params[key]}` : "";
    })
    .join("&");
};

export const stringToPath = (s = "") =>
  `/${unorm
    .nfd(s.toLowerCase())
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[\s']/g, "-")
    .replace(/[^a-z0-9-]/g, "")
    .replace(/-{2,}/g, "-")
    .replace(/-$/g, "")}`;

export const createUrlWithoutQuery = (url = "") => url.split("?")[0];

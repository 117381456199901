import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
// Components. -----------------------------------------------------------------
import Image from "cms/components/front-office/contents/Image";
import PresentationRow from "./PresentationRow";
// =============================================================================
// PresentationRowPreview...
// =============================================================================
var PresentationRowPreview = function () {
    // Render. -------------------------------------------------------------------
    return (_jsx(Box, { sx: { width: "100%" }, children: _jsx(PresentationRow, { title: "Titre de la presentation", description: "Zone de texte libre", image: _jsx(Image, { file: { url: "/default-image.jpg" }, disableLazy: true }), dynamicPartAfterDescription: null }) }));
};
export default PresentationRowPreview;

import React from "react";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import AdminWebsiteContext from "cms/components/back-office/contexts/AdminWebsiteContext";
import RenderPage from "cms/components/back-office/Layout/RenderPage";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import AdminPageVersionContext from "cms/components/back-office/contexts/AdminPageVersionContext";
const NoLayout = () => {
  const projectTheme = CmsHooks.getHook(HOOKS.THEME);
  const getTheme = CmsHooks.getHook(HOOKS.GET_THEME);

  const { currentSite } = React.useContext(AdminWebsiteContext);
  const { currentPageVersion } = React.useContext(AdminPageVersionContext);

  const theme = (typeof getTheme === "function" && getTheme({ site: currentSite })) || projectTheme || createTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RenderPage page={currentPageVersion} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default NoLayout;

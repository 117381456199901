import contentsTypes from "cms/enums/contentsTypes.enum";
import IframePreview from "../components/Iframe/IframePreview";
import customTypes from "../enums/contentsCustomTypes.enum";
export var iframeDescriptor = {
    label: "Iframe",
    type: customTypes.IFRAME,
    icon: "address-card",
    editionModal: true,
    editionModalPosition: "top",
    Preview: IframePreview,
    children: [
        {
            label: "URL",
            key: "url",
            type: contentsTypes.URL,
            value: ""
        },
        {
            label: "Title",
            key: "title",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Height (px)",
            key: "height",
            type: contentsTypes.INPUT_NUMBER,
            value: ""
        }
    ]
};

import PropTypes from "prop-types";
import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "enums/contentsCustomTypes.enum";
import SubpageDynamicForm from "components/adminForms/SubpageDynamicForm";
import SubpageSearchForm from "components/adminForms/SubpageSearchForm";
import SimpleLinkForm from "projects/EVIDENCEB/components/adminForms/SimpleLinkForm";

const getFormComponent = ({ type }) => {
  switch (type) {
    case customTypes.SUBPAGE_SEARCH:
      return SubpageSearchForm;
    case contentsTypes.SUBPAGE:
      return SubpageDynamicForm;
    case customTypes.SIMPLE_LINK:
      return SimpleLinkForm;
    default:
      return null;
  }
};

getFormComponent.propTypes = {
  type: PropTypes.string.isRequired
};

export default getFormComponent;

import React from "react";
import Box from "@mui/material/Box";
import Container from "cms/components/front-office/contents/Container";

const ContainerTitle = ({ title, justifyContent, width }) => (
  <Box mb={3}>
    <Container justifyContent={justifyContent} width={width}>
      <Box sx={{ border: "1px solid #999", p: 2, textAlign: "center" }}>{title}</Box>
    </Container>
  </Box>
);

const ContainerPreview = () => {
  return (
    <Box>
      <ContainerTitle title="Conteneur 50% aligné à gauche" justifyContent="flex-start" width="50" />
      <ContainerTitle title="Conteneur 30% aligné au centre" justifyContent="center" width="30" />
      <ContainerTitle title="Conteneur 60% aligné à droite" justifyContent="flex-end" width="60" />
    </Box>
  );
};

export default ContainerPreview;

import React from "react";
var AdminPageVersionContext = React.createContext({
    currentPageVersion: { id: null },
    setCurrentPageVersion: function () { },
    pageVersions: [],
    refreshPageVersions: function () { },
    versionIsCanceled: false,
    setVersionIsCanceled: function () { },
    versionHasChanged: false,
    setVersionHasChanged: function () { }
});
export default AdminPageVersionContext;

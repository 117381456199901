import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
// Components. -----------------------------------------------------------------
import Agenda from "./Agenda";
// =============================================================================
// AgendaPreview...
// =============================================================================
var AgendaPreview = function () {
    // Render. -------------------------------------------------------------------
    return (_jsx(Box, { sx: { width: "100%" }, children: _jsx(Agenda, { title: "\u00C9v\u00E9nements \u00E0 venir", icsUrl: "Lien vers le fichier ICS" }) }));
};
export default AgendaPreview;

import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputForm from "cms/components/back-office/contentForms/InputForm";
import SelectForm from "cms/components/back-office/contentForms/SelectForm";
import SubpageForm from "cms/components/back-office/contentForms/SubpageForm";

import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";

const SubpageSearchForm = props => {
  const { content, onContentChange } = props;
  const { label, children } = content;
  const { id } = content;

  const titleChild = children.find(c => c.key === "title");
  const perPageChild = children.find(c => c.key === "perPage");
  const templateChild = children.find(c => c.key === "template");
  const pageParentChild = children.find(c => c.key === "pageParent");

  const templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
  templateChild.options = [{ label: "Aucun", value: "" }, ...templates.map(t => ({ label: t.label, value: t.key }))];

  const handleContentChange = oldChild => newChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === oldChild) {
          return newChild;
        }
        return child;
      })
    });
  };

  return (
    <div key={id}>
      <h2 style={{ textAlign: "center" }}>{label}</h2>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <InputForm content={titleChild} onContentChange={handleContentChange(titleChild)} />
        </Grid>
        <Grid item xs={6}>
          <InputForm content={perPageChild} onContentChange={handleContentChange(perPageChild)} type="number" />
        </Grid>
        <Grid item xs={6}>
          <SelectForm content={templateChild} onContentChange={handleContentChange(templateChild)} />
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <SubpageForm
              content={{
                ...pageParentChild,
                value:
                  typeof pageParentChild.value === "object"
                    ? pageParentChild.value
                    : pageParentChild.value && { page: { id: pageParentChild.value } }
              }}
              onContentChange={handleContentChange(pageParentChild)}
              withLabel={false}
              canBeEmpty
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

SubpageSearchForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default SubpageSearchForm;

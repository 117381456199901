var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
var LogoMENJ = function (props) {
    var matches = props.matches;
    return (_jsx(Box, __assign({ sx: {
            height: "78px",
            bgcolor: "white",
            width: "92px",
            borderBottom: 2,
            borderBottomColor: "#E30613",
            borderTop: matches ? 2 : 0,
            borderTopColor: "#263375",
            position: {
                xs: "absolute"
            },
            left: matches ? "-92px" : "0px",
            top: matches ? "-2px" : "0px",
            zIndex: "20"
        } }, { children: _jsx(Box, { component: "img", alt: "Portail r\u00E9alis\u00E9 pour le Minist\u00E8re de l'\u00E9ducation nationale et de la jeunesse", src: "/Logo-header.jpeg", sx: { width: "100%", height: "100%" } }, void 0) }), void 0));
};
export default LogoMENJ;

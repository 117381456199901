import React, { useEffect, useRef, useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Share from "projects/EVIDENCEB/components/Share";
import { HashLink } from "react-router-hash-link";
import PageVersionContext from "cms/components/shared/PageVersionContext";
import SiteContext from "cms/components/shared/SiteContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { getDateFromTimestamp } from "cms/utils/commonUtils";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { useArticlesPageVersion } from "services/article.service";
import ArticleSubpage from "templates/articles/ArticleSubpage";
import ArticlesCarousel from "components/Articles/ArticlesCarousel";

const tagStyled = {
  color: "secondary.main",
  border: "1px solid",
  borderColor: "secondary.main",
  borderRadius: "5px",
  px: 1,
  py: 0.25,
  mr: 1,
  fontSize: "0.75rem"
};

const imageBoxStyled = {
  width: "100%",
  height: "auto",
  mt: 4,
  "& img": {
    filter: "drop-shadow(0px 4px 8px rgba(102, 109, 146, 0.08))",
    borderRadius: "5px",
    width: "100%",
    maxHeight: { xs: "300px", md: "none" },
    objectFit: "cover"
  }
};

const graphicElementStyle = {
  boxSizing: "border-box",
  position: "absolute",
  left: "35px",
  top: "1px",
  border: "1px solid #3B29AB",
  borderRadius: "3px",
  display: { xs: "none", md: "block" }
};

const ArticleDetailTemplate = props => {
  const { page } = props;
  const { image, title, shortDescription, created, contents, tags } = page;
  const { dynamicPart } = contents;
  const { currentPageVersion } = useContext(PageVersionContext);
  let { id: currentId, breadcrumb = [] } = currentPageVersion || {};
  if (!breadcrumb) {
    breadcrumb = [];
  }
  if (breadcrumb && !Array.isArray(breadcrumb)) {
    breadcrumb = [breadcrumb];
  }
  const { title: breadcrumbTitle, fullPath: breadcrumbFullPath } = breadcrumb[breadcrumb?.length - 2 || 0] || {};
  const site = useContext(SiteContext);
  const { id: siteId, settings: siteSettings } = site || {};
  let { socialNetworks = [] } = siteSettings || {};
  if (socialNetworks && !Array.isArray(socialNetworks)) {
    socialNetworks = [socialNetworks];
  }

  const { data: articles = [] } = useArticlesPageVersion(siteId);

  const recentArticles = React.useMemo(() => articles.filter(article => article.id !== currentId), [
    articles,
    currentId
  ]);

  const [heightBorder, setHeight] = useState(null);
  const [widthBorder, setWidth] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (ref && page) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
  }, [ref, page]);

  return (
    <main>
      <Box
        sx={{
          pt: { xs: 3, md: 6.75 },
          ml: -0.75,
          display: "flex",
          alignItems: "center",
          color: "primary.main",
          "& a": { color: "primary.main", textDecoration: "none" }
        }}
      >
        <ChevronLeftIcon />
        <HashLink title={breadcrumbTitle} to={breadcrumbFullPath || "/"}>
          Retour au blog
        </HashLink>
      </Box>
      <Grid container columnSpacing={3} sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}>
        <Grid item xs={12} md={7}>
          <Box sx={{ position: "relative" }}>
            {tags && tags.length > 0 && (
              <Box sx={{ mt: 3.25, display: "flex", flexFlow: "wrap" }}>
                {tags.map(tag => (
                  <Box component="span" key={tag} sx={tagStyled}>
                    {tag}
                  </Box>
                ))}
              </Box>
            )}
            {title && (
              <Typography variant="h0" component="h1" sx={{ "& > pre": { my: 1.75 } }}>
                {title}
              </Typography>
            )}
          </Box>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs container alignItems="center">
              <CobaltIcon fontSize="1,125rem" name={"calendar"} sx={{ mx: 1, color: "greyColor.main" }} />
              <Typography variant="text">{getDateFromTimestamp(created, "DD MMM YYYY")}</Typography>
              <Box sx={{ ml: 1, mr: 0.25, color: "greyColor.main" }}>-</Box>
              <CobaltIcon fontSize="1,125rem" name={"clock"} sx={{ mx: 1, color: "greyColor.main" }} />
              <Typography variant="text">
                {getDateFromTimestamp(created, "HH")}h{getDateFromTimestamp(created, "mm")}
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex", "& i": { cursor: "pointer", pl: 2.5, color: "primary.main" } }}>
              <Share />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2.5 }} />
          {shortDescription && (
            <Typography variant="textArticle" sx={{ mb: 3, lineHeight: "initial", letterSpacing: "initial" }}>
              {shortDescription}
            </Typography>
          )}
          {dynamicPart}
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Typography variant="h3" sx={{ mb: 2 }} className="sr-only">
              Articles similaires
            </Typography>
            <ArticlesCarousel articles={recentArticles} />
          </Box>
        </Grid>
        <Grid item sx={{ display: { xs: "none", md: "block" } }}>
          <Divider orientation="vertical" sx={{ mt: 4.25 }} />
        </Grid>

        <Grid item xs={12} md={4} sx={{ position: "relative", height: "fit-content" }}>
          {heightBorder && widthBorder && (
            <Box sx={{ ...graphicElementStyle, height: `${heightBorder + 11}px`, width: `${widthBorder + 18}px` }} />
          )}
          <Box sx={imageBoxStyled}>
            <img alt={image.props.alt} src={image.props.file.url} ref={ref} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h3" className="sr-only">
              Articles similaires
            </Typography>
            <ArticleSubpage articles={recentArticles} small />
          </Box>
        </Grid>
      </Grid>
    </main>
  );
};

ArticleDetailTemplate.propTypes = {
  page: PropTypes.shape(),
  subpageSearch: PropTypes.shape()
};

ArticleDetailTemplate.defaultProps = {
  page: {},
  subpageSearch: null
};

export default ArticleDetailTemplate;

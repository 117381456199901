var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import videosOrigins from "cms/enums/videosOrigins";
import { getQueryParams } from "cms/utils/urlUtils";
var validURL = function (str) {
    var pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", "i"); // fragment locator
    return !!pattern.test(str);
};
var Video = function (props) {
    var src = props.src, source = props.source, others = __rest(props, ["src", "source"]);
    var createSrcByOrigin = function () {
        if (!src)
            return src;
        var newSrc = src.split("?")[0];
        var queryParams = getQueryParams(src);
        switch (source) {
            case videosOrigins.YOUTUBE: {
                var video = queryParams.v, time = queryParams.t;
                if (newSrc.includes("youtu.be")) {
                    newSrc = newSrc.replace("youtu.be", "www.youtube.com/embed/");
                }
                else {
                    newSrc = newSrc.replace("watch", "embed/");
                    if (video) {
                        newSrc = newSrc.concat("", video);
                    }
                }
                newSrc = newSrc.concat("", "?start=" + (time || 0));
                return newSrc;
            }
            case videosOrigins.DAILYMOTION:
                return newSrc.replace("video", "embed/video").split("?")[0];
            case videosOrigins.URL:
                return src;
            default:
                break;
        }
        return src;
    };
    var iframeSrc = validURL(createSrcByOrigin()) ? createSrcByOrigin() : "about:blank";
    return (_jsx(_Fragment, { children: source !== videosOrigins.URL ? (_jsx("iframe", __assign({ height: "400", allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true, title: "video" }, others, { src: iframeSrc, style: { width: "100%", border: "0px" } }), void 0)) : (_jsx("video", { src: iframeSrc, style: { width: "100%", border: "0px" }, controls: true }, void 0)) }, void 0));
};
export default Video;

import { pageVersionImageKey } from "cms/services/admin/AdminPageVersionsService";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";

export const getTemplateByKey = key =>
  (CmsHooks.getHook(HOOKS.TEMPLATES) || []).find(template => template.key === key) || { Component: () => null };

export const getTemplateComponentByKey = key => getTemplateByKey(key).Component;

export const enhancePageVersionContents = pageVersion => {
  if (!pageVersion) {
    return null;
  }
  const { template: templateKey, contents } = pageVersion;
  const template = getTemplateByKey(templateKey);
  const { initialContents = [] } = template;

  const pageVersionImage = contents?.find(child => child.key === pageVersionImageKey);
  const pageVersionImageParsed = pageVersionImage?.value ? JSON.parse(pageVersionImage.value) : null;

  return {
    ...pageVersion,
    ...(pageVersionImageParsed ? { image: { ...pageVersionImageParsed, ...pageVersion.image } } : {}),
    contents: initialContents.map(content => {
      const pageVersionContent = contents.find(c => c.key === content.key) || {};
      return {
        ...content,
        ...pageVersionContent
      };
    })
  };
};

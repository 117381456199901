var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import Link from "cms/components/front-office/contents/Link";
import { getDateFromTimestamp } from "cms/utils/commonUtils";
import React from "react";
import { descriptionStyled, ellipsisStyled, tagStyled } from "templates/articles/ArticleCard";
import ZoomContext from "../../components/ZoomContext";
var imageParentStyled = function (small) { return ({
    height: "100%",
    ml: { xs: 1.5, md: 0 },
    "& img": {
        borderRadius: "6px",
        overflow: "hidden",
        display: "block",
        width: small ? "96px" : "100%",
        height: small ? "96px" : "100%",
        objectFit: "cover",
        objectPosition: "top center"
    }
}); };
var ArticleCardHorizontal = function (props) {
    var zoom = React.useContext(ZoomContext).zoom;
    var page = props.page, _a = props.small, small = _a === void 0 ? false : _a;
    var id = page.id, image = page.image, title = page.title, shortDescription = page.shortDescription, fullPath = page.fullPath, created = page.created, tags = page.tags;
    var date = getDateFromTimestamp(created, "DD MMM YYYY");
    var horizontalCardStyled = {
        maxHeight: 181 * zoom + "px",
        my: 1.5,
        pl: { xs: 0, md: 1.5 },
        py: 1.5,
        boxSizing: "content-box",
        bgcolor: { xs: "#fff", md: "transparent" },
        boxShadow: { xs: "0px 4px 8px rgba(102, 109, 146, 0.08)", md: "none" },
        borderRadius: "8px",
        border: "1px solid",
        textDecoration: "none!important",
        borderColor: "transparent",
        "&:hover": {
            borderColor: "primary.main"
        }
    };
    return (_jsxs(Grid, __assign({ item: true, xs: 12, container: true, alignItems: "center", component: Link, url: fullPath, sx: horizontalCardStyled, "aria-label": title }, { children: [image && (_jsx(Grid, __assign({ item: true, xs: 3, md: small ? "auto" : 4, container: true, sx: imageParentStyled(small) }, { children: _jsx("img", { src: image.url, alt: image.alt, title: image.title }, void 0) }), void 0)), _jsxs(Grid, __assign({ item: true, xs: 8, md: small ? 8 : 7, container: true, direction: "column", justifyContent: "space-around", sx: { py: 1, pl: 4 } }, { children: [_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center" } }, { children: [tags &&
                                tags.length > 0 &&
                                tags.map(function (tag) { return (_jsx(Box, __assign({ component: "span", sx: __assign(__assign({}, tagStyled), { px: 1, py: 0.25 }) }, { children: tag }), tag)); }), _jsx(Box, __assign({ sx: { color: "greyColor.main", fontSize: "0.75rem" } }, { children: date }), void 0)] }), void 0), title && (_jsx(Typography, __assign({ variant: small ? "subtitle1" : "h1", component: "h3", sx: __assign(__assign({}, ellipsisStyled), { WebkitLineClamp: "2", mt: 1.5 }) }, { children: title }), void 0)), shortDescription && (_jsx(Typography, { variant: small ? "smallText" : "text", sx: __assign(__assign(__assign({}, ellipsisStyled), descriptionStyled), { WebkitLineClamp: small ? "2" : "3", "& p": { my: small ? 1 : 2 } }), dangerouslySetInnerHTML: { __html: String(decodeURIComponent(shortDescription) || "") } }, void 0))] }), void 0)] }), id));
};
export default ArticleCardHorizontal;

import types from "cms/enums/contentsTypes.enum";
export var contentsGroupList = {
    label: "contents group",
    type: types.CONTENTS_GROUPS_LIST,
    value: "",
    children: [
        {
            label: "groups",
            type: types.CONTENTS_GROUP,
            key: "groups"
        }
    ]
};

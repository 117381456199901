import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import AdminPageChangeContext from "./AdminPageChangeContext";
export var AdminPageChangeContextProvider = function (props) {
    var children = props.children;
    var _a = useState(false), pageChanges = _a[0], setPageChanges = _a[1];
    var setPageChangesFunction = React.useCallback(function (reponse) {
        setPageChanges(reponse);
    }, []);
    return (_jsx(AdminPageChangeContext.Provider, { value: {
            pageChanges: pageChanges,
            setPageChanges: setPageChangesFunction
        }, children: children }));
};

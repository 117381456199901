import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import AdminEditorContext from "./AdminEditorContext";
export var AdminEditorContextProvider = function (props) {
    var children = props.children;
    var _a = useState(false), editorValueChange = _a[0], setEditorValueChange = _a[1];
    var setEditorValueChangeFunction = React.useCallback(function (reponse) {
        setEditorValueChange(reponse);
    }, []);
    return (_jsx(AdminEditorContext.Provider, { value: {
            editorValueChange: editorValueChange,
            setEditorValueChange: setEditorValueChangeFunction
        }, children: children }));
};

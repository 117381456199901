var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import update from "immutability-helper";
import { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableMenuItem from "./DraggableMenuItem";
var AdminMenuList = function (props) {
    var items = props.items, onItemsChange = props.onItemsChange, clickEditMenuItem = props.clickEditMenuItem, clickDeleteMenuItem = props.clickDeleteMenuItem, saveItem = props.saveItem, maxDepth = props.maxDepth, subItemKey = props.subItemKey, currentDepth = props.currentDepth;
    var _a = useState(items), menuItems = _a[0], setMenuItems = _a[1];
    useEffect(function () {
        setMenuItems(items);
    }, [items]);
    var moveMenuItem = useCallback(function (dragIndex, hoverIndex) {
        setMenuItems(function (prevItems) {
            return update(prevItems, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevItems[dragIndex]]
                ]
            });
        });
    }, [setMenuItems]);
    var handleSaveOrder = useCallback(function () {
        onItemsChange(menuItems);
    }, [onItemsChange, menuItems]);
    var renderDraggableItems = useCallback(function (menuItem, index) {
        if (menuItem === undefined)
            return;
        return (_jsx(DraggableMenuItem, { canUpdate: true, item: menuItem, subItemKey: subItemKey, maxDepth: maxDepth, index: index, id: menuItem.id, currentDepth: currentDepth, saveItem: saveItem, onItemsDrop: handleSaveOrder, moveMenuItem: moveMenuItem, onEditItem: clickEditMenuItem, onDeleteItem: clickDeleteMenuItem }, "drag-" + menuItem.id));
    }, [
        clickDeleteMenuItem,
        clickEditMenuItem,
        currentDepth,
        handleSaveOrder,
        maxDepth,
        moveMenuItem,
        saveItem,
        subItemKey
    ]);
    return _jsx(DndProvider, __assign({ backend: HTML5Backend }, { children: menuItems.map(function (card, i) { return renderDraggableItems(card, i); }) }), void 0);
};
export default AdminMenuList;

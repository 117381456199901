var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PartnerTabs from "projects/EVIDENCEB/components/Partenaires/PartnerTabs";
import PartenaireElementsGraphique from "./PartenaireElementsGraphique";
var descriptionStyled = {
    pt: "8px",
    minWidth: { xs: "100%", md: "600px" },
    "& p": {
        fontSize: { xs: "0.75rem", md: "1rem" },
        color: "primary.dark",
        lineHeight: "1.17rem",
        letterSpacing: "0.05rem",
        fontWeight: 600
    }
};
var imageContainerStyled = {
    borderBottom: 2,
    borderBottomColor: "#E30613",
    borderTop: 2,
    borderTopColor: "#263375",
    backgroundColor: "white",
    height: "96px",
    display: { xs: "none", lg: "inline" }
};
var Partenaires = function (props) {
    var id = props.id, title = props.title, description = props.description, tabs = props.tabs;
    return (_jsxs(_Fragment, { children: [_jsx(PartenaireElementsGraphique, {}, void 0), _jsxs(Box, __assign({ sx: { textAlign: { xs: "center", lg: "inherit" } } }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Box, __assign({ sx: { pr: { xs: 0, md: 3 } } }, { children: [_jsx(Typography, __assign({ variant: "h5", component: "div", sx: { "& > pre": { margin: 0 } } }, { children: title }), void 0), _jsx(Box, __assign({ sx: descriptionStyled }, { children: description }), void 0)] }), void 0), _jsx(Box, __assign({ sx: imageContainerStyled }, { children: _jsx(Box, { component: "img", alt: "Minist\u00E8re de l'\u00E9ducation national et de la jeunesse", src: "/Logo-header.png", sx: { height: "92px" } }, void 0) }), void 0)] }), void 0), _jsx(PartnerTabs, __assign({}, tabs), void 0)] }), id)] }, void 0));
};
export default Partenaires;

import { jsx as _jsx } from "react/jsx-runtime";
import LoadingPage from "cms/components/back-office/Layout/LoadingPage";
import AdminPageVersionsService from "cms/services/admin/AdminPageVersionsService";
import { PageVersionStatus } from "cms/types/cms/page/version/page-version.status.enum";
import { queryParamPageKey, queryParamSiteKey, queryParamVersionKey } from "cms/utils/queryParamsKeys";
import { createQueryParams, getQueryParams } from "cms/utils/urlUtils";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AdminPageContext from "./AdminPageContext";
import AdminPageVersionContext from "./AdminPageVersionContext";
import AdminWebsiteContext from "./AdminWebsiteContext";
export var AdminPageVersionContextProvider = function (props) {
    var children = props.children;
    var _a = useState({ id: null }), currentPageVersion = _a[0], setCurrentPageVersion = _a[1];
    var _b = useState([]), pageVersions = _b[0], setPageVersions = _b[1];
    var _c = useState(false), versionIsCanceled = _c[0], setVersionIsCanceled = _c[1];
    var _d = useState(false), versionHasChanged = _d[0], setVersionHasChanged = _d[1];
    var currentSite = useContext(AdminWebsiteContext).currentSite;
    var currentPage = useContext(AdminPageContext).currentPage;
    var location = useLocation();
    var pathname = location.pathname;
    var history = useHistory();
    var historyPush = history.push;
    var currentPageVersionId = (currentPageVersion || { id: null }).id;
    var queryParams = getQueryParams();
    var _e = queryParams, _f = queryParamSiteKey, siteId = _e[_f], _g = queryParamPageKey, queryParamPage = _e[_g], _h = queryParamVersionKey, queryParamVersion = _e[_h];
    var handleChangePageVersion = React.useCallback(function (pageVersion) {
        var _a;
        if (pageVersion === void 0) { pageVersion = { id: null }; }
        var id = pageVersion.id;
        if (currentPageVersionId !== id) {
            historyPush("".concat(pathname, "?").concat(createQueryParams((_a = {},
                _a[queryParamSiteKey] = siteId,
                _a[queryParamPageKey] = queryParamPage,
                _a[queryParamVersionKey] = id,
                _a))));
        }
        else {
            setCurrentPageVersion(pageVersion);
        }
    }, [currentPageVersionId, historyPush, pathname, queryParamPage, siteId]);
    var refreshPageVersions = React.useCallback(function () {
        if (currentSite && currentPage) {
            return AdminPageVersionsService.getPageVersions(currentPage.id).then(function (versions) {
                versions.sort(function (_a, _b) {
                    var status1 = _a.status;
                    var status2 = _b.status;
                    if (status1 === status2 || ![status1, status2].includes(PageVersionStatus.ARCHIVED)) {
                        return 0;
                    }
                    return status1 === PageVersionStatus.ARCHIVED ? 1 : -1;
                });
                setPageVersions(versions);
                return versions;
            });
        }
        return Promise.resolve([]);
    }, [currentSite, currentPage]);
    // Refresh versions when current page change
    useEffect(function () {
        refreshPageVersions();
    }, [currentSite, refreshPageVersions]);
    // Set current version
    useEffect(function () {
        if (pageVersions.length) {
            var version = pageVersions.find(function (v) { return v.id === queryParamVersion; });
            if (version && (!currentPageVersion || version.id !== currentPageVersion.id)) {
                setCurrentPageVersion(version);
            }
            else if (!version) {
                handleChangePageVersion(pageVersions[0]);
            }
        }
    }, [currentPageVersion, handleChangePageVersion, pageVersions, queryParamVersion]);
    if (!currentSite) {
        return _jsx(LoadingPage, { title: "Chargement des sites" });
    }
    return (_jsx(AdminPageVersionContext.Provider, { value: {
            currentPageVersion: currentPageVersion,
            setCurrentPageVersion: handleChangePageVersion,
            pageVersions: pageVersions,
            refreshPageVersions: refreshPageVersions,
            versionIsCanceled: versionIsCanceled,
            setVersionIsCanceled: setVersionIsCanceled,
            versionHasChanged: versionHasChanged,
            setVersionHasChanged: setVersionHasChanged
        }, children: children }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Loader from "cms/components/shared/Loader";
import SiteContext from "cms/components/shared/SiteContext";
import React, { useState } from "react";
import { useArticlesTagsByParent, useLastArticlesByParent } from "services/article.service";
import ArticleCard, { studentStyledTag, tagStyled } from "templates/articles/ArticleCard";
import ArticleCardHorizontal from "templates/articles/ArticleCardHorizontal";
import ArticleHeader from "templates/articles/ArticleHeader";
var headerStyled = {
    py: 2.5,
    mb: 4.75,
    bgcolor: "#EEF3FF",
    position: "relative"
};
var backgroundStyled = {
    position: "absolute",
    bgcolor: "#EEF3FF",
    top: 0,
    left: -1000,
    right: -1000,
    bottom: 0,
    zIndex: -1
};
var ArticlesTemplate = function (props) {
    var _a = useState(""), selectedTag = _a[0], setSelectedTag = _a[1];
    var pageVersion = props.page;
    var title = pageVersion.title, _b = pageVersion.shortDescription, shortDescription = _b === void 0 ? "" : _b, contents = pageVersion.contents, page = pageVersion.page;
    var _c = contents.subtitle, subtitle = _c === void 0 ? "" : _c, displayHeader = contents.displayHeader;
    var parentId = page.id;
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    var site = React.useContext(SiteContext);
    var siteId = site.id;
    var filters = selectedTag && selectedTag.length !== 0 ? "tags||cont||" + selectedTag : "";
    var _d = useLastArticlesByParent(parentId, siteId, [filters]), data = _d.data, isFetching = _d.isFetching;
    var articles = (data || {}).data;
    var _e = useArticlesTagsByParent(parentId, siteId).data, tags = _e === void 0 ? [] : _e;
    return (_jsxs("main", { children: [_jsxs(Grid, __assign({ container: true, direction: "column", alignItems: "center", sx: { mb: 3, "& div": { minWidth: "300px", textAlign: "center" }, "& > *": { textAlign: "center" } } }, { children: [_jsx(Typography, __assign({ component: "h1", variant: isMobile ? "h3" : "h1", sx: { mb: { xs: 1, md: 1.25 }, mt: "1em" } }, { children: title }), void 0), _jsx(Typography, __assign({ variant: isMobile ? "h1" : "h0", component: "div", sx: { mb: { xs: 1, md: 1.25 } } }, { children: subtitle }), void 0), _jsx(Typography, __assign({ variant: "subtitle2", component: "div", sx: { "& > div": { color: "greyColor.main" } } }, { children: shortDescription }), void 0)] }), void 0), typeof displayHeader === "object" && (_jsx(Grid, __assign({ container: true, justifyContent: "center", sx: { py: 2 } }, { children: displayHeader }), void 0)), isFetching ? (_jsx(Box, __assign({ sx: { textAlign: "center" } }, { children: _jsx(Loader, {}, void 0) }), void 0)) : articles && articles.length > 0 ? (_jsxs(_Fragment, { children: [displayHeader && (_jsxs(Grid, __assign({ container: true, sx: headerStyled }, { children: [_jsx(Box, { sx: backgroundStyled }, void 0), _jsx(ArticleHeader, { articles: articles }, void 0)] }), void 0)), _jsx(Typography, __assign({ variant: "h2", sx: { mb: 1.25 } }, { children: "Tous les articles" }), void 0), _jsxs(Grid, __assign({ container: true, alignItems: "center", component: "ul", sx: { mb: 2.25, listStyleType: "none" } }, { children: [_jsx(Box, __assign({ component: "li", sx: theme.student
                                    ? __assign(__assign({}, studentStyledTag), { background: selectedTag === "" ? "#FFFFFF" : null, boxShadow: selectedTag === "" ? "2px 3px 0px #3B29AB" : null }) : __assign(__assign({}, tagStyled), { background: selectedTag === "" ? "#3B29AB" : null }) }, { children: _jsx(Button, __assign({ disableTouchRipple: true, sx: {
                                        px: 1,
                                        py: 0.5,
                                        fontSize: "0.75rem",
                                        lineHeight: "1",
                                        color: theme.professor ? (selectedTag === "" ? "#F4F7FE" : "#3B29AB") : "#3B29AB",
                                        zIndex: 1
                                    }, onClick: function () { return setSelectedTag(""); } }, (selectedTag === "" ? { "aria-current": true } : {}), { children: "Tous" }), void 0) }), void 0), tags &&
                                tags.length > 0 &&
                                tags.map(function (tag) { return (_jsx(Box, __assign({ component: "li", sx: theme.student
                                        ? __assign(__assign({}, studentStyledTag), { background: selectedTag === tag ? "#FFFFFF" : null, boxShadow: selectedTag === tag ? "2px 3px 0px #3B29AB" : null }) : __assign(__assign({}, tagStyled), { background: selectedTag === tag ? "#3B29AB" : null }) }, { children: _jsx(Button, __assign({ disableTouchRipple: true, sx: {
                                            px: 1,
                                            py: 0.5,
                                            fontSize: "0.75rem",
                                            lineHeight: "1",
                                            color: theme.professor ? (selectedTag === tag ? "#F4F7FE" : "#3B29AB") : "#3B29AB",
                                            zIndex: 1
                                        }, onClick: function () {
                                            setSelectedTag(tag);
                                        } }, (selectedTag === tag ? { "aria-current": true } : {}), { children: tag }), void 0) }), tag)); })] }), void 0), _jsx(Grid, __assign({ container: true, columnSpacing: { xs: 0, md: 6 } }, { children: articles === null || articles === void 0 ? void 0 : articles.map(function (article) {
                            return isMobile ? (_jsx(ArticleCardHorizontal, { page: article }, article.id)) : (_jsx(ArticleCard, { page: article }, article.id));
                        }) }), void 0)] }, void 0)) : (_jsx(Typography, __assign({ variant: "h3", sx: { textAlign: "center" } }, { children: "Aucun article" }), void 0))] }, void 0));
};
export default ArticlesTemplate;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
var headerStyled = {
    mb: 3,
    "& p": { minWidth: "300px", textAlign: "center" },
    "& > *": { textAlign: "center" }
};
var blockLinesStyled = {
    display: { xs: "none", md: "block" },
    position: "absolute",
    left: 0,
    borderBottom: "1px solid",
    borderColor: "#DEE1F2"
};
var imageStyled = {
    position: "absolute",
    zIndex: 1,
    top: -126,
    right: -82
};
var FAQTemplate = function (props) {
    var page = props.page;
    var title = page.title, _a = page.shortDescription, shortDescription = _a === void 0 ? "" : _a, contents = page.contents;
    var _b = contents.subtitle, subtitle = _b === void 0 ? "" : _b, dynamicPart = contents.dynamicPart;
    var theme = useTheme();
    var isMobile = !useMediaQuery(theme.breakpoints.up("md"));
    return (_jsxs("main", { children: [_jsx(Box, { sx: __assign(__assign({}, blockLinesStyled), { right: { md: "59%", lg: "56%" }, top: 110 }) }, void 0), _jsx(Box, { sx: __assign(__assign({}, blockLinesStyled), { right: { md: "59%", lg: "56%" }, top: 115 }) }, void 0), _jsx(Box, { sx: __assign(__assign({}, blockLinesStyled), { right: { md: "59%", lg: "56%" }, top: 120 }) }, void 0), _jsx(Box, { sx: __assign(__assign({}, blockLinesStyled), { right: { md: "76%", lg: "70%" }, top: 158 }) }, void 0), _jsx(Box, { sx: __assign(__assign({}, blockLinesStyled), { right: { md: "76%", lg: "70%" }, top: 163 }) }, void 0), _jsx(Box, { sx: __assign(__assign({}, blockLinesStyled), { right: { md: "76%", lg: "70%" }, top: 168 }) }, void 0), _jsxs(Grid, __assign({ container: true, direction: "column", alignItems: "center", sx: headerStyled }, { children: [_jsx(Typography, __assign({ component: "h1", variant: isMobile ? "h3" : "h1", sx: { mb: { xs: 1, md: 1.25 }, mt: "1em", zIndex: 9, bgcolor: theme.palette.primary.light, pr: 2, pl: 2 } }, { children: title }), void 0), _jsx(Typography, __assign({ variant: isMobile ? "h1" : "h0", component: "h2", sx: { mb: { xs: 1, md: 1.25 }, zIndex: 9, bgcolor: theme.palette.primary.light, pr: 2, pl: 2 } }, { children: subtitle }), void 0), _jsx(Typography, __assign({ variant: "subtitle2", component: "div", sx: { "& > div": { color: "greyColor.main" }, zIndex: 9, bgcolor: theme.palette.primary.light, pr: 2, pl: 2 } }, { children: shortDescription }), void 0)] }), void 0), _jsx(Box, __assign({ sx: { position: "relative", display: { xs: "none", md: "block" } } }, { children: _jsx(Box, __assign({ id: "question_Flatline_box", sx: imageStyled }, { children: _jsx("img", { alt: "", src: "/question_Flatline.svg" }, void 0) }), void 0) }), void 0), dynamicPart] }, void 0));
};
export default FAQTemplate;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
var Container = function (props) {
    var _a = props.children, children = _a === void 0 ? null : _a, justifyContent = props.justifyContent, _b = props.width, width = _b === void 0 ? null : _b, _c = props.mobileWidth, mobileWidth = _c === void 0 ? null : _c;
    return (_jsx(Box, __assign({ display: "flex", flexDirection: "row", justifyContent: justifyContent }, { children: _jsx(Box, __assign({ sx: { width: { xs: mobileWidth + "%", lg: width + "%" } } }, { children: children }), void 0) }), void 0));
};
export default Container;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ImageContainer from "cms/components/front-office/contents/ImageContainer";

const TeamUnit = props => {
  const { id, title, subtitle, image, description, ...others } = props;

  return (
    <div {...others} key={id}>
      {image && (
        <Box
          sx={{
            borderRadius: "60px 0",
            overflow: "hidden",
            mb: 8
          }}
        >
          <ImageContainer ratio={64}>{image}</ImageContainer>
        </Box>
      )}
      {title && (
        <Typography variant="h5" component="h4" sx={{ mt: 1 }}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant="subtitle1" color="secondary" sx={{ mt: 1 }}>
          {subtitle}
        </Typography>
      )}
      {description && (
        <Typography variant="body1" sx={{ mt: 1 }}>
          {description}
        </Typography>
      )}
    </div>
  );
};

TeamUnit.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.shape(),
  image: PropTypes.shape()
};

TeamUnit.defaultProps = {
  id: "",
  title: "",
  subtitle: "",
  description: "",
  icon: null,
  image: null
};

export default TeamUnit;

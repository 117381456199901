import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import Layout from "components/Layout";
import NotFound from "components/NotFound";
import templates from "templates/templates";
import routes from "routes/routes";
import theme from "theme";
import studentTheme from "./studentTheme";
import getDynamicComponent from "utils/getDynamicComponent";
import { ContentLabelType, setDataStaticConsentFromFront } from "cms/components/shared/cookies/config/definitions";

export default () => {
  CmsHooks.registerHook(HOOKS.PageSwitcher_404, NotFound);
  CmsHooks.registerHook(HOOKS.App_Layout, Layout);
  CmsHooks.registerHook(HOOKS.THEME, theme);
  CmsHooks.registerHook(HOOKS.GET_THEME, ({ site }) => {
    if (site.name === "M.I.A. Seconde") {
      return theme;
    }
    return studentTheme;
  });
  CmsHooks.registerHook(HOOKS.TEMPLATES, templates);
  CmsHooks.registerHook(HOOKS.App_routes, routes);
  CmsHooks.registerHook(HOOKS.templatePropsUtils_getDynamicComponent, getDynamicComponent);
  CmsHooks.registerHook(HOOKS.TRACKING_ACTIVE, true);
  CmsHooks.registerHook(
    HOOKS.CONSENT_CONFIG,
    setDataStaticConsentFromFront(false, ContentLabelType.MTM_CONSENT_REMOVED, ContentLabelType.MATOMO_SESSID)
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { decodeCodeBlockText } from "cms/utils/commonUtils";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia, prism } from "react-syntax-highlighter/dist/esm/styles/prism";
var CodeBlock = function (props) {
    var _a = props.code, code = _a === void 0 ? "" : _a, _b = props.language, language = _b === void 0 ? "" : _b, _c = props.isDark, isDark = _c === void 0 ? true : _c, others = __rest(props, ["code", "language", "isDark"]);
    return (_jsx("div", __assign({}, others, { children: _jsx(SyntaxHighlighter, __assign({ showLineNumbers: true, language: language, style: isDark ? okaidia : prism }, { children: decodeCodeBlockText(code) }), void 0) }), void 0));
};
export default CodeBlock;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from "axios";
import { useQuery } from "react-query";
var configs = {
    getMenu: function (siteId) { return ({
        url: "/menus",
        params: { siteId: siteId }
    }); },
    getFooter: function (siteId) { return ({
        url: "/footer",
        params: { siteId: siteId }
    }); },
    getHeader: function (siteId) { return ({
        url: "/header",
        params: { siteId: siteId }
    }); }
};
var SectionService = /** @class */ (function () {
    function SectionService() {
    }
    SectionService.getConfig = function (methodName, options) {
        var getter = configs[methodName];
        if (typeof getter === "function") {
            return getter(options);
        }
        return {};
    };
    SectionService.getMenu = function (siteId) {
        return axios(configs.getMenu(siteId)).then(function (response) { return response.data; });
    };
    SectionService.getFooter = function (siteId) {
        return axios(configs.getFooter(siteId)).then(function (response) { return response.data; });
    };
    SectionService.getHeader = function (siteId) {
        return axios(configs.getHeader(siteId)).then(function (response) { return response.data; });
    };
    return SectionService;
}());
export var useMenu = function (siteId, config) {
    if (config === void 0) { config = {}; }
    var _a = useQuery(["menu", { siteId: siteId }], function () { return SectionService.getMenu(siteId); }, __assign({}, config)), menu = _a.data, rest = __rest(_a, ["data"]);
    return __assign({ menu: menu }, rest);
};
export var useFooter = function (siteId, config) {
    if (config === void 0) { config = {}; }
    var _a = useQuery(["footer", { siteId: siteId }], function () { return SectionService.getFooter(siteId); }, __assign({}, config)), footer = _a.data, rest = __rest(_a, ["data"]);
    return __assign({ footer: footer }, rest);
};
export default SectionService;

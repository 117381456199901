import categories from "cms/enums/categoriesContents";
import types from "cms/enums/contentsTypes.enum";
export var margin = {
    label: "Espace vertical",
    type: types.MARGIN,
    category: categories.STRUCTURE,
    value: "",
    icon: "arrows-alt-v",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Hauteur (en px)",
            key: "height",
            type: types.SLIDER,
            value: "10",
            min: "0",
            max: "100",
            step: "5"
        },
        {
            label: "Hauteur (en px) sur mobile",
            key: "mobileHeight",
            type: types.SLIDER,
            value: "10",
            min: "0",
            max: "100",
            step: "5"
        }
    ]
};

import GridPreview from "cms/components/back-office/contentsPreview/GridPreview";
import categories from "cms/enums/categoriesContents";
import types from "cms/enums/contentsTypes.enum";
export var grid = {
    label: "Grille",
    type: types.GRID,
    category: categories.STRUCTURE,
    icon: "border-none",
    editionModal: true,
    editionModalPosition: "top",
    Preview: GridPreview,
    dynamicChildKey: "items",
    children: [
        {
            key: "size",
            label: "Nombre de colonnes",
            type: types.SELECT_GRID,
            options: [
                { label: "2", value: "6" },
                { label: "3", value: "4" },
                { label: "4", value: "3" },
                { label: "6", value: "2" }
            ],
            value: "6"
        },
        {
            key: "sizeXs",
            label: "Nombre de colonnes en mobile",
            type: types.SELECT_GRID,
            options: [
                { label: "1", value: "12" },
                { label: "2", value: "6" },
                { label: "3", value: "4" },
                { label: "4", value: "3" }
            ],
            value: "12"
        },
        {
            key: "justify",
            label: "Alignement des colonnes",
            type: types.SELECT,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" }
            ],
            value: "flex-start"
        },
        {
            key: "alignItems",
            label: "Position",
            type: types.SELECT,
            options: [
                { label: "En haut", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "En bas", value: "flex-end" }
            ],
            value: "flex-start"
        }
    ]
};

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "cms/components/front-office/contents/Link";

const NotFound = () => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid item xs={12} md={5}>
        <Box
          sx={{
            color: "primary.dark",
            fontWeight: "bold",
            fontSize: { xs: "8.75rem", lg: "15.75rem" },
            textAlign: "center"
          }}
        >
          404
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        container="column"
        justifyContent="flex-end"
        sx={{
          "& > a": {
            textDecoration: "underline",
            color: "secondary.main",
            mt: 2.5
          }
        }}
      >
        <Typography variant="h2">La page que vous demandez n&apos;existe pas</Typography>
        <Link url="/">
          <Typography variant="subtitle1">Revenir à la page d&apos;accueil</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default NotFound;

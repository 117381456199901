var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid } from "@mui/material";
// =============================================================================
// GridBox...
// =============================================================================
var containerStyled = function (_a) {
    var selected = _a.selected, option = _a.option;
    return ({
        height: "55px",
        bgcolor: "secondary.main",
        py: 0.25,
        m: 0.25,
        border: "2px solid",
        borderColor: selected === option.value ? "primary.main" : "secondary.main"
    });
};
var itemStyled = {
    bgcolor: "form.text",
    height: "100%",
    mx: 0.25
};
export var GridBox = function (_a) {
    var option = _a.option, selected = _a.selected, onClickBox = _a.onClickBox;
    //Render. --------------------------------------------------------------------
    return (_jsx(Grid, { container: true, direction: "row", justifyContent: "flex-start", alignItems: "center", sx: containerStyled({ selected: selected, option: option }), onClick: function () { return onClickBox(option.value); }, children: __spreadArray([], Array(+option.label), true).map(function (_, idx) { return (_jsx(Grid, { item: true, xs: true, sx: itemStyled, children: "\u00A0" }, idx)); }) }));
};
export default GridBox;

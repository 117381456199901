import dayjs from "dayjs";
import "dayjs/locale/fr";

let timer;
// eslint-disable-next-line import/prefer-default-export
export const debounce = (callback, delay) => {
  // eslint-disable-next-line func-names
  return function() {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
};

// see momentjs.com to choose your format
export const getDateFromTimestamp = (date, format) =>
  dayjs(date)
    .locale("fr")
    .format(format);

export const frenchNameRegex = /[a-zA-Z0-9 àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]/g;
export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g;
export const urlRegex = "^(https?):\\/\\/[^\\s$.?#].[^\\s]*$";
export const latitudeRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/g;
export const longitudeRegex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g;

const encodingValue = "encoding_Left_Chevron";
const encodingRegex = new RegExp(encodingValue, "g");

export const encodeCodeBlockText = value => value.replace(/</g, encodingValue);
export const decodeCodeBlockText = value => {
  return value.replace(encodingRegex, "<");
};

export const removeProperties = (object, properties) => {
  const newObject = Object.keys(object)
    .filter(key => !properties.includes(key))
    .reduce(
      (filtered, key) => ({
        ...filtered,
        [key]: object[key]
      }),
      {}
    );
  if (newObject.children) {
    newObject.children = newObject.children.map(c => removeProperties(c, properties));
  }
  return newObject;
};

export const fullToolbarEditor = [
  {
    name: "paragraph",
    items: [
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "-",
      "Outdent",
      "Indent",
      "-",
      "NumberedList",
      "BulletedList"
    ]
  },
  { name: "links", items: ["Link", "Unlink"] },
  { name: "insert", items: ["Image", "-", "Table", "-", "HorizontalRule"] },
  "/",
  {
    name: "basicstyles",
    items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"]
  },
  { name: "colors", items: ["TextColor", "BGColor"] },
  { name: "styles", items: ["Format"] }
];

export const descriptionToolbarEditor = [
  {
    name: "basicstyles",
    items: ["Bold", "Italic", "Underline"]
  }
];

export const standardToolbarEditor = [
  {
    name: "basicstyles",
    items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript"]
  },
  {
    name: "paragraph",
    items: [
      "JustifyLeft",
      "JustifyCenter",
      "JustifyRight",
      "JustifyBlock",
      "-",
      "Outdent",
      "Indent",
      "-",
      "NumberedList",
      "BulletedList"
    ]
  },
  { name: "links", items: ["Link", "Unlink"] },
  { name: "styles", items: ["Format"] }
];

export const downloadFile = (fileData, fileName) => {
  const href = URL.createObjectURL(fileData);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import AdminContentModal from "cms/components/back-office/admin/AdminContentModal";
import ThemeBackOfficeProvider from "cms/theme/ThemeBackOfficeProvider";
import { adminProperties, replacePagesByIds } from "cms/utils/adminContentsUtils";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import { removeProperties } from "cms/utils/commonUtils";
import PropTypes from "prop-types";
var buttonsStyled = {
    mb: 2,
    ml: 2,
    "& button": {
        py: 1.5,
        "& span": {
            mr: 1.5
        }
    }
};
var AdminTemplateSettings = function (props) {
    var templateSettings = props.templateSettings, handleChangeTemplateSettings = props.handleChangeTemplateSettings, displayModal = props.displayModal, setDisplayModal = props.setDisplayModal, handleValidateTemplateSettingsChange = props.handleValidateTemplateSettingsChange, handleCancelTemplateSettingsChange = props.handleCancelTemplateSettingsChange, templateIsModify = props.templateIsModify, setTemplateIsModify = props.setTemplateIsModify;
    var templates = CmsHooks.getHook(HOOKS.TEMPLATES) || [];
    var template = (templateSettings && templates.find(function (t) { return t.key === templateSettings.key; })) || {};
    var templateLabel = template.label;
    var handleContentChange = function (updatedContent) {
        setDisplayModal(false);
        setTemplateIsModify(true);
        handleChangeTemplateSettings(updatedContent);
    };
    var handleValidate = function () {
        setTemplateIsModify(false);
        templateSettings.children = templateSettings.children.map(function (c) { return removeProperties(c, adminProperties); });
        templateSettings.children = templateSettings.children.map(function (content) { return replacePagesByIds(content); });
        handleValidateTemplateSettingsChange(templateSettings);
    };
    var handleCancel = function () {
        setTemplateIsModify(false);
        handleCancelTemplateSettingsChange();
    };
    if (!templateSettings || !templateSettings.children) {
        return null;
    }
    return (_jsxs(ThemeBackOfficeProvider, { children: [_jsxs(Box, __assign({ sx: buttonsStyled }, { children: [_jsxs(Button, __assign({ color: "secondary", variant: "contained", onClick: function () { return setDisplayModal(true); } }, { children: [_jsx(Icon, { className: "fas fa-cog" }, void 0), " Modifier le gabarit"] }), void 0), templateIsModify && (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ color: "primary", variant: "contained", onClick: handleCancel }, { children: "Annuler" }), void 0), _jsx(Button, __assign({ color: "primary", variant: "contained", onClick: handleValidate }, { children: "Valider pour toutes les pages" }), void 0)] }, void 0))] }), void 0), _jsx(AdminContentModal, { title: "Param\u00E8tres du gabarit: " + templateLabel, open: displayModal, content: templateSettings, onValidate: handleContentChange, onClose: function () { return setDisplayModal(false); } }, void 0)] }, void 0));
};
AdminTemplateSettings.propTypes = {
    templateSettings: PropTypes.func.isRequired,
    handleChangeTemplateSettings: PropTypes.func.isRequired,
    displayModal: PropTypes.bool.isRequired,
    setDisplayModal: PropTypes.func.isRequired,
    handleValidateTemplateSettingsChange: PropTypes.func.isRequired,
    handleCancelTemplateSettingsChange: PropTypes.func.isRequired,
    templateIsModify: PropTypes.bool.isRequired,
    setTemplateIsModify: PropTypes.func.isRequired
};
AdminTemplateSettings.defaultProps = {
    templateSettings: null
};
export default AdminTemplateSettings;

import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "../enums/contentsCustomTypes.enum";
export var subPageSearch = {
    label: "Dernières pages publiées",
    type: customTypes.SUBPAGE_SEARCH,
    children: [
        {
            label: "Titre du contenu",
            key: "title",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Nombre de pages à afficher (limité à 10 pages)",
            key: "perPage",
            type: contentsTypes.INPUT_NUMBER,
            options: [
                {
                    label: "max",
                    value: "10"
                },
                {
                    label: "min",
                    value: "0"
                }
            ],
            value: ""
        },
        {
            label: "Pages provenant du gabarit",
            key: "template",
            type: contentsTypes.SELECT,
            options: [],
            value: ""
        },
        {
            label: "Pages provenant de la page parente",
            key: "pageParent",
            type: contentsTypes.PAGE,
            value: ""
        }
    ]
};

import { accordion } from "./accordion.descriptor";
import { accordionPanel } from "./accordionPanel.descriptor";
import { attachment } from "./attachment.descriptor";
import { blockTitle } from "./blockTitle.descriptor";
import { button } from "./button.descriptor";
import { checkbox } from "./checkbox.descriptor";
import { codeBlock } from "./codeBlock.descriptor";
import { codeBlockText } from "./codeBlockText.descriptor";
import { color } from "./color.descriptor";
import { container } from "./container.descriptor";
import { contentsGroup } from "./contentsGroup.descriptor";
import { contentsGroupList } from "./contentsGroupsList.descriptor";
import { dynamic } from "./dynamic.descriptor";
import { expansionPanel } from "./expansionPanel.descriptor";
import { expansionPanelDetails } from "./expansionPanelDetails.descriptor";
import { expansionText } from "./expansionText.descriptor";
import { file } from "./file.descriptor";
import { fileImage } from "./fileImage.descriptor";
import { galerieImage } from "./galerieImage.descriptor";
import { glossary } from "./glossary.descriptor";
import { glossaryItem } from "./glossaryItem.descriptor";
import { grid } from "./grid.descriptor";
import { icon } from "./icon.descriptor";
import { image } from "./image.descriptor";
import { imageBlock } from "./imageBlock.descriptor";
import { imageFile } from "./imageFile.descriptor";
import { imageFilters } from "./imageFilters.descriptor";
import { infoBlock } from "./infoBlock.descriptor";
import { infoBlockList } from "./infoBlockList.descriptor";
import { input } from "./input.descriptor";
import { link } from "./link.descriptor";
import { margin } from "./margin.descriptor";
import { menuGroup } from "./menuGroup.descriptor";
import { menuItem } from "./menuItem.descriptor";
import { page } from "./page.descriptor";
import { select } from "./select.descriptor";
import { siteSettings } from "./siteSettings.descriptor";
import { slider } from "./slider.descriptor";
import { socialNetwork } from "./socialNetwork.descriptor";
import { step } from "./step.descriptor";
import { stepV2 } from "./stepV2.descriptor";
import { stepV2Content } from "./stepV2Content.descriptor";
import { subPage } from "./subPage.descriptor";
import { subPageList } from "./subPageList.descriptor";
import { tab } from "./tab.descriptor";
import { tabs } from "./tabs.descriptor";
import { templateSettings } from "./templateSettings.descriptor";
import { templatesSettings } from "./templatesSettings.descriptor";
import { text } from "./text.descriptor";
import { timeline } from "./timeline.descriptor";
import { url } from "./url.descriptor";
import { video } from "./video.descriptor";
import { warningBanner } from "./warningBanner.descriptor";
export var adminContents = [
    text,
    button,
    imageBlock,
    galerieImage,
    imageFile,
    image,
    imageFilters,
    video,
    accordionPanel,
    expansionPanel,
    expansionPanelDetails,
    accordion,
    expansionText,
    attachment,
    checkbox,
    color,
    contentsGroupList,
    contentsGroup,
    file,
    fileImage,
    glossary,
    glossaryItem,
    grid,
    container,
    margin,
    icon,
    slider,
    infoBlock,
    infoBlockList,
    input,
    link,
    menuGroup,
    menuItem,
    page,
    select,
    siteSettings,
    templatesSettings,
    templateSettings,
    socialNetwork,
    step,
    stepV2,
    stepV2Content,
    subPage,
    subPageList,
    timeline,
    tabs,
    tab,
    url,
    warningBanner,
    codeBlock,
    codeBlockText,
    blockTitle,
    dynamic
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Icon from "cms/components/front-office/contents/Icon";
import SearchBar from "cms/components/front-office/contents/SearchBar";
var SearchBarHeader = function (props) {
    var isOpen = props.isOpen, toggle = props.toggle, isMobile = props.isMobile;
    var closeIcon = (_jsx(IconButton, __assign({ "aria-label": "Fermez la recherche", onClick: toggle, size: "large" }, { children: _jsx(Icon, { icon: "times", type: "fal" }, void 0) }), void 0));
    return (_jsx(Slide, __assign({ direction: isMobile ? "up" : "left", in: isOpen, mountOnEnter: true, unmountOnExit: true }, { children: _jsx(Box, __assign({ sx: { width: "100%", mt: { xs: 3.25, lg: 0 } }, role: "search" }, { children: _jsx(SearchBar, { closeIcon: closeIcon }, void 0) }), void 0) }), void 0));
};
export default SearchBarHeader;

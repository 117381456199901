var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { DraggableSectionItem } from "cms/types/front/DraggableSectionItem.enum";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import MenuItem from "./MenuItem";
var style = {
    transform: "translate(0, 0)"
};
var DraggableMenuItem = function (_a) {
    var id = _a.id, maxDepth = _a.maxDepth, currentDepth = _a.currentDepth, item = _a.item, canUpdate = _a.canUpdate, index = _a.index, onItemsDrop = _a.onItemsDrop, onDeleteItem = _a.onDeleteItem, onEditItem = _a.onEditItem, saveItem = _a.saveItem, subItemKey = _a.subItemKey, moveMenuItem = _a.moveMenuItem;
    var dragRef = useRef(null);
    var previewRef = useRef(null);
    var _b = useDrag({
        type: currentDepth === 0 ? DraggableSectionItem.MenuItem : DraggableSectionItem.SubMenuItem + currentDepth,
        item: function () {
            return { id: id, index: index };
        },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging()
        }); }
    }), isDragging = _b[0].isDragging, drag = _b[1], preview = _b[2];
    var _c = useDrop({
        accept: currentDepth === 0 ? DraggableSectionItem.MenuItem : DraggableSectionItem.SubMenuItem + currentDepth,
        collect: function (monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        drop: function (item, monitor) {
            onItemsDrop();
        },
        hover: function (item, monitor) {
            var _a;
            if (!previewRef.current) {
                return;
            }
            var dragIndex = item.index;
            var hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            var hoverBoundingRect = (_a = previewRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            // Get vertical middle
            var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            var clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            var hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveMenuItem(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    }), handlerId = _c[0].handlerId, drop = _c[1];
    var opacity = isDragging ? 0 : 1;
    drag(dragRef);
    drop(preview(previewRef));
    return (_jsx("div", __assign({ ref: previewRef, style: __assign(__assign({}, style), { opacity: opacity }), "data-handler-id": handlerId }, { children: _jsx(MenuItem, { currentDepth: currentDepth, subItemKey: subItemKey, maxDepth: maxDepth, saveItem: saveItem, canUpdate: canUpdate, menuItem: item, isDragging: isDragging, refButtonDrag: dragRef, onDeleteItem: onDeleteItem, onEditItem: onEditItem }, "menu-item-" + id) }), void 0));
};
export default DraggableMenuItem;

import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import HelpIcon from "@mui/icons-material/Help";
import MenuItem from "@mui/material/MenuItem";
import Select from "cms/components/shared/Select";
import { FormLabelTooltip } from "cms/components/back-office/contentForms/ContentFormStyled";

const SelectForm = props => {
  const { content, onContentChange, tooltip } = props;

  const { id, label, value, options } = content;

  const handleSelectChange = e => {
    onContentChange({
      ...content,
      value: e.target.value
    });
  };

  return (
    <FormControl key={id}>
      <InputLabel sx={{ pointerEvents: "auto" }}>
        {label}
        {tooltip && (
          <FormLabelTooltip title={tooltip}>
            <HelpIcon />
          </FormLabelTooltip>
        )}
      </InputLabel>
      <Select
        value={value}
        // native
        onChange={handleSelectChange}
        displayEmpty
      >
        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string
};

SelectForm.defaultProps = {
  tooltip: null
};

export default SelectForm;

import types from "cms/enums/contentsTypes.enum";
export var subPageList = {
    label: "Liste de sous pages",
    type: types.SUBPAGES_LIST,
    value: "",
    dynamicChildKey: "pages",
    children: [
        {
            label: "Pages",
            key: "pages",
            type: types.SUBPAGE
        }
    ]
};

import contentsTypes from "cms/enums/contentsTypes.enum";
export var subPage = {
    label: "Sous-page avec bouton",
    type: contentsTypes.SUBPAGE,
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            key: "page",
            label: "page",
            type: contentsTypes.PAGE,
            value: ""
        },
        {
            key: "subtitle",
            label: "subtitle",
            type: contentsTypes.EDITABLE_INPUT,
            value: ""
        },
        {
            key: "dynamicPart",
            label: "dynamicPart",
            type: contentsTypes.DYNAMIC,
            value: ""
        }
    ]
};

import InfoBlockPreview from "cms/components/back-office/contentsPreview/InfoBlockPreview";
import InfoBlockIcon from "cms/components/shared/icons/InfoBlockIcon";
import types from "cms/enums/contentsTypes.enum";
export var infoBlock = {
    label: "Bloc d'information",
    type: types.INFO_BLOCK,
    IconComponent: InfoBlockIcon,
    editionModal: true,
    editionModalPosition: "top",
    Preview: InfoBlockPreview,
    children: [
        {
            label: "icon",
            key: "icon",
            type: types.ICON
        },
        {
            label: "Texte",
            key: "text",
            type: types.TEXT
        }
    ]
};

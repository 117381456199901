import React from "react";
import customTypes from "enums/contentsCustomTypes.enum";

import contentsTypes from "cms/enums/contentsTypes.enum";
import SubPage from "cms/components/front-office/contents/SubPage";
import { formatPageData } from "cms/utils/templatePropsUtils";

import Agenda from "components/Agenda/Agenda";
import CardBox1 from "components/CardBox1/CardBox1";
import CardBox2 from "components/CardBox2/CardBox2";
import Partenaires from "components/Partenaires/Partenaires";
import PresentationRow from "components/PresentationRow/PresentationRow";
import PresentationBox from "components/PresentationBox/PresentationBox";
import LastArticles from "projects/EVIDENCEB/components/Articles/LastArticles";
import TeamUnit from "components/TeamUnit";
import Iframe from "components/Iframe/Iframe";
import GeographicMap from "components/GeographicMap";
import SubpageSearch from "components/SubpageSearch";
import Newsletter from "components/Newsletter/Newsletter";
import Slider from "../components/Slider/Slider";
import Divider from "components/Divider";
import Link from "cms/components/front-office/contents/Link";

// eslint-disable-next-line react/prop-types
const getDynamicComponent = ({ type, value, id, index }) => {
  switch (type) {
    case customTypes.AGENDA:
      return <Agenda {...value} key={id} />;
    case customTypes.DIVIDER:
      return <Divider key={id} />;
    case customTypes.CARD_BOX_1:
      return <CardBox1 {...value} key={id} />;
    case customTypes.NEWSLETTER:
      return <Newsletter {...value} key={id} />;
    case customTypes.CARD_BOX_2:
      return <CardBox2 {...value} key={id} />;
    case customTypes.PARTNERS:
      return <Partenaires {...value} key={id} />;
    case customTypes.PRESENTATION_BOX:
      return <PresentationBox {...value} key={id} />;
    case customTypes.PRESENTATION_ROW:
      return <PresentationRow {...value} key={id} />;
    case customTypes.LAST_ARTICLES_BOX:
      return <LastArticles {...value} key={id} />;
    case customTypes.IFRAME:
      return <Iframe {...value} key={id} />;
    case customTypes.SIMPLE_LINK:
      if (typeof value === "object" && !Array.isArray(value)) {
        const { title: linkTitle, page, url, external, ...others } = value;
        return (
          <Link {...others} key={id} page={page} url={url} external={external} title={linkTitle}>
            {linkTitle}
          </Link>
        );
      }
      break;
    case customTypes.TEAM_UNIT:
      return <TeamUnit {...value} key={id} />;
    case contentsTypes.SUBPAGE: {
      const { page } = value || {};
      const { image } = page || {};
      return (
        <SubPage
          {...value}
          // eslint-disable-next-line react/prop-types
          page={{ ...formatPageData(page), image }}
          pageVersion={page}
          key={id}
          index={index}
        />
      );
    }
    case customTypes.GEOGRAPHIC_MAP:
      return <GeographicMap {...value} key={id} />;
    case customTypes.SUBPAGE_SEARCH:
      return <SubpageSearch {...value} key={id} />;
    case customTypes.SLIDER2:
      return <Slider {...value} key={id} />;
    default:
      return null;
  }
};

export default getDynamicComponent;

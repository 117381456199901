import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { getQueryParams } from "cms/utils/urlUtils";
import InfiniteScroll from "react-infinite-scroller";
import Block from "cms/components/shared/Block";
import Icon from "cms/components/front-office/contents/Icon";
import Button from "cms/components/front-office/contents/Button";
import Link from "cms/components/front-office/contents/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SiteContext from "cms/components/shared/SiteContext";
import Loader from "cms/components/shared/Loader";
import SearchService from "cms/services/SearchService";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import sanitizeHtml from "sanitize-html";

const sanitizerOptions = {
  ...sanitizeHtml.defaults,
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(["h2", "img", "u", "s", "sub", "address", "sup"]),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    "*": ["id", "class", "style", "title"],
    a: ["href", "name", "target"],
    img: ["src", "target", "alt"]
  },
  allowedSchemesByTag: {
    ...sanitizeHtml.defaults.allowedSchemesByTag,
    img: ["https", "data"]
  }
};

const buttonStyled = {
  bgcolor: "white",
  border: "2px solid",
  borderColor: "primary.light",
  position: "relative",
  borderRadius: "30px 0"
};

const buttonIconStyled = {
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "primary.light",
  fontSize: "2rem"
};

const ResultsSearch = props => {
  const { location } = props;
  const queryParams = getQueryParams(location.search);
  const site = useContext(SiteContext);

  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(null);
  const resultPerPage = 6;
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const loadResults = page => {
    if (hasMoreResults && page !== undefined) {
      SearchService.search({
        query: queryParams.q,
        from: resultPerPage * (page - 1),
        size: resultPerPage,
        siteId: site.id
      }).then(response => {
        setResults(prev => [...prev, ...(response.hits && response.hits.hits)]);
        setTotal(response.hits && response.hits.total && response.hits.total.value);
        setHasMoreResults(response.hits && response.hits.hits && response.hits.hits.length === resultPerPage);
      });
    }
  };

  useEffect(() => {
    setResults([]);
    setHasMoreResults(true);
  }, [queryParams.q]);

  useEffect(() => {
    loadResults(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMoreResults]);

  return (
    results && (
      <div>
        <Block>
          <Typography variant="h1">Résultats de la recherche</Typography>
          <Typography variant="h2" sx={{ pt: 2 }}>
            {total} résultat(s) pour : &quot;{queryParams.q}&quot;
          </Typography>
        </Block>
        {results.length > 0 && (
          <>
            <Divider sx={{ mt: 4, mb: 6 }} />
            <InfiniteScroll
              loadMore={loadResults}
              hasMore={hasMoreResults}
              loader={<Loader key={0} />}
              pageStart={1}
              initialLoad={false}
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
            >
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="column" spacing={2} role="list">
                    {results &&
                      results.map(
                        r =>
                          r._source && (
                            <Grid item role="listitem">
                              <Button
                                size="medium"
                                variant="outlined"
                                primary
                                fullWidth
                                page={r._source}
                                component={Link}
                                sx={buttonStyled}
                              >
                                <Grid container direction="column" sx={{ p: 3 }}>
                                  <Grid item>
                                    <Typography component="p" variant="h5">
                                      {r._source.title}
                                    </Typography>
                                  </Grid>
                                  <Grid item sx={{ mb: 2, maxHeight: 80, overflow: "hidden" }}>
                                    <Typography
                                      component="p"
                                      variant="subtitle2"
                                      dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                          decodeURIComponent(r._source.shortDescription),
                                          sanitizerOptions
                                        )
                                      }}
                                    ></Typography>
                                  </Grid>
                                </Grid>
                                <Box component="span" sx={buttonIconStyled}>
                                  <Icon icon="long-arrow-right" type="fal" />
                                </Box>
                              </Button>
                            </Grid>
                          )
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </InfiniteScroll>
          </>
        )}
      </div>
    )
  );
};

ResultsSearch.propTypes = {
  location: PropTypes.shape().isRequired
};

export default ResultsSearch;

// =============================================================================
// icsDateFormat: ics date format: "aaaaMMddTHHmmssZ"
// =============================================================================
export var icsDateFormat = function (icsDate, format) {
    var splitDate = icsDate.split("T");
    if (format === "date") {
        return splitDate[0].replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
    }
    else if (format === "time") {
        return splitDate[1].replace("Z", "").replace(/(\d{2})(\d{2})(\d{2})/g, "$1:$2");
    }
    else {
        var year = splitDate[0].slice(0, 4);
        var month = splitDate[0].slice(4, 6);
        return new Date(+year, +month).toLocaleDateString("fr", { month: "long", year: "numeric" });
    }
};

import ButtonPreview from "cms/components/back-office/contentsPreview/ButtonPreview";
import types from "cms/enums/contentsTypes.enum";
export var button = {
    label: "Bouton",
    type: types.BUTTON,
    icon: "hand-pointer",
    Preview: ButtonPreview,
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "text",
            key: "text",
            type: types.INPUT,
            value: "Texte"
        },
        {
            label: "Couleur",
            key: "color",
            type: types.RADIO_GROUP,
            width: "50%",
            options: [
                { label: "Primaire", value: "primary" },
                { label: "Secondaire", value: "secondary" }
            ],
            value: "primary"
        },
        {
            label: "Style",
            key: "variant",
            type: types.RADIO_GROUP,
            width: "50%",
            options: [
                { label: "Principal", value: "contained", title: "Boutons d'actions principales (contained)" },
                { label: "Secondaire", value: "outlined", title: "Boutons d'actions secondaires (outlined)" }
            ],
            value: "contained"
        },
        {
            label: "Taille du bouton",
            key: "size",
            type: types.RADIO_GROUP,
            width: "50%",
            options: [
                { label: "Petit", value: "small" },
                { label: "Moyen", value: "medium" },
                { label: "Grand", value: "large" }
            ],
            value: "medium"
        },
        {
            label: "Prendre toute la largeur",
            key: "fullWidth",
            type: types.CHECKBOX,
            width: "50%",
            value: "false"
        },
        {
            key: "justifyContent",
            label: "Alignement",
            type: types.RADIO_GROUP,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" }
            ],
            value: "flex-start"
        },
        {
            label: "Lien du bouton",
            key: "link",
            type: types.LINK,
            value: ""
        },
        {
            label: "Icone",
            key: "icon",
            type: types.ICON,
            value: ""
        }
    ]
};

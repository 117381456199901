import React, { useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import SelectPage from "cms/components/back-office/SelectPage";
import contentsTypes from "cms/enums/contentsTypes.enum";
import IconForm from "cms/components/back-office/contentForms/IconForm";
import ImageForm from "cms/components/back-office/contentForms/ImageForm";
import InputForm from "cms/components/back-office/contentForms/InputForm";
import LinkForm from "cms/components/back-office/contentForms/LinkForm";
import TextAreaForm from "cms/components/back-office/contentForms/TextAreaForm";
import { FormTitle } from "cms/components/back-office/contentForms/ContentFormStyled";
import Icon from "cms/components/front-office/contents/Icon";

const CardForm = props => {
  const { content, onContentChange } = props;

  const { id, label, children } = content;

  const pageChild = children.find(c => c.type === contentsTypes.PAGE);
  const titleChild = children.find(c => c.key === "title");
  const descriptionChild = children.find(c => c.key === "description");
  const linkChild = children.find(c => c.type === contentsTypes.LINK);
  const iconChild = children.find(c => c.type === contentsTypes.ICON);
  const imageChild = children.find(c => c.type === contentsTypes.IMAGE);

  const [pageCard, setPageCard] = useState(!!pageChild.value || !id);

  const handleCardTypeChange = e => {
    setPageCard(e.target.checked);
    if (!e.target.checked) {
      onContentChange({
        ...content,
        children: children.map(child => {
          if (child === pageChild) {
            return {
              ...child,
              value: ""
            };
          }
          return child;
        })
      });
    }
  };

  const handleSelectPage = p => {
    const { versions, ...others } = p;
    versions.sort((v1, v2) => new Date(v2.updated) - new Date(v1.updated));
    const [lastVersion] = versions;
    if (lastVersion) {
      const pageVersion = {
        ...lastVersion,
        page: { ...others }
      };
      onContentChange({
        ...content,
        children: children.map(child => {
          // Update page value
          if (child === pageChild) {
            return {
              ...child,
              value: pageVersion
            };
          }
          // Update title value
          if (child === titleChild) {
            return {
              ...child,
              value: lastVersion.title || ""
            };
          }
          // Update description value
          if (child === descriptionChild) {
            return {
              ...child,
              value: lastVersion.shortDescription || ""
            };
          }
          // Update link value
          if (child === linkChild) {
            return {
              ...child,
              children: linkChild.children.map(c => {
                if (c.type === contentsTypes.PAGE) {
                  return {
                    ...c,
                    value: pageVersion
                  };
                }
                if (c.type === contentsTypes.URL) {
                  return {
                    ...c,
                    value: ""
                  };
                }
                return c;
              })
            };
          }
          return child;
        })
      });
    }
  };

  const handleTitleChange = newTitleChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === titleChild) {
          return newTitleChild;
        }
        return child;
      })
    });
  };

  const handleDescriptionChange = newDescriptionChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === descriptionChild) {
          return newDescriptionChild;
        }
        return child;
      })
    });
  };

  const handleLinkChange = newLinkChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === linkChild) {
          return newLinkChild;
        }
        return child;
      })
    });
  };

  const handleIconChange = newIconChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === iconChild) {
          return newIconChild;
        }
        return child;
      })
    });
  };

  const handleImageChange = newImageChild => {
    onContentChange({
      ...content,
      children: children.map(child => {
        if (child === imageChild) {
          return newImageChild;
        }
        return child;
      })
    });
  };

  return (
    <div key={id}>
      <FormTitle>{label}</FormTitle>
      <SelectPage
        currentPage={pageChild.value && pageChild.value.page}
        onSelectPage={handleSelectPage}
        disabled={!pageCard}
      />
      <FormControlLabel
        control={<Checkbox checked={pageCard} onChange={handleCardTypeChange} disableRipple />}
        label="Renseigner automatiquement"
      />
      <InputForm content={titleChild} onContentChange={handleTitleChange} disabled={pageCard} />
      <TextAreaForm content={descriptionChild} onContentChange={handleDescriptionChange} disabled={pageCard} />
      <ImageForm content={imageChild} onContentChange={handleImageChange} disabled={pageCard} />
      <LinkForm content={linkChild} onContentChange={handleLinkChange} disabled={pageCard} />

      <IconForm content={iconChild} onContentChange={handleIconChange} />
      <Grid
        item
        sx={{
          display: "inline-block",
          verticalAlign: "middle",
          mr: 1
        }}
      >
        <Typography>
          <Icon icon="exclamation-circle" title="Information" />
          &nbsp;L&apos;icône ne s&apos;affiche pas sur toutes les cards
        </Typography>
      </Grid>
    </div>
  );
};

CardForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default CardForm;

import types from "cms/enums/contentsTypes.enum";
export var accordionPanel = {
    label: "Panneau dépliant",
    type: types.ACCORDION_PANEL,
    value: "",
    editionModal: true,
    editionModalPosition: "top",
    requiredByKey: ["summary"],
    children: [
        {
            label: "Titre du paneau",
            key: "summary",
            type: types.INPUT,
            value: "Titre"
        },
        {
            label: "details",
            key: "details",
            type: types.EXPANSION_PANEL_DETAILS,
            value: ""
        }
    ]
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Link from "cms/components/front-office/contents/Link";
var ImageBlock = function (props) {
    var _a = props.width, width = _a === void 0 ? null : _a, _b = props.mobileWidth, mobileWidth = _b === void 0 ? null : _b, justifyContent = props.justifyContent, _c = props.link, link = _c === void 0 ? null : _c, image = props.image;
    return (_jsx(Box, __assign({ display: "flex", justifyContent: justifyContent }, { children: _jsx(Box, __assign({ sx: {
                height: "100%",
                width: { xs: mobileWidth ? mobileWidth + "%" : "100%", lg: width ? width + "%" : "100%" }
            } }, { children: link && (link.page || link.url) ? _jsx(Link, __assign({}, link, { children: image }), void 0) : image }), void 0) }), void 0));
};
export default ImageBlock;

import types from "cms/enums/contentsTypes.enum";
export var imageBlock = {
    label: "Image",
    type: types.IMAGE_BLOCK,
    icon: "image",
    editionModal: true,
    editionModalPosition: "top",
    value: "",
    children: [
        {
            label: "image",
            key: "image",
            type: types.IMAGE,
            requiredByKey: ["file"]
        },
        {
            label: "Largeur de l'image (en %)",
            key: "width",
            type: types.SLIDER
        },
        {
            label: "Largeur de l'image (en %) sur mobile",
            key: "mobileWidth",
            type: types.SLIDER
        },
        {
            key: "justifyContent",
            label: "Alignement",
            type: types.SELECT,
            options: [
                { label: "À gauche", value: "flex-start" },
                { label: "Centré", value: "center" },
                { label: "À droite", value: "flex-end" }
            ],
            value: "flex-start"
        },
        {
            label: "Lien de l'image",
            key: "link",
            type: types.LINK
        }
    ]
};

import contentsTypes from "cms/enums/contentsTypes.enum";
import HomeTemplate from "templates/home/HomeTemplate";

export default {
  key: "HOME",
  label: "Accueil",
  templateImage: "/template-home.png",
  Component: HomeTemplate,
  initialContents: [
    {
      key: "dynamicPart",
      type: contentsTypes.DYNAMIC,
      value: ""
    }
  ]
};

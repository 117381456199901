import React, { Fragment } from "react";
import PropTypes from "prop-types";
import InputForm from "cms/components/back-office/contentForms/InputForm";
import { FormTitle } from "cms/components/back-office/contentForms/ContentFormStyled";

const TabForm = props => {
  const { content, onContentChange } = props;
  const { label, children } = content;

  const titleChild = children.find(c => c.key === "title");

  const handleChange = newChild => {
    onContentChange({
      ...content,
      children: children.map(c => (c === titleChild ? newChild : c))
    });
  };

  return (
    <Fragment>
      <FormTitle>{label}</FormTitle>
      <InputForm content={titleChild} onContentChange={handleChange} />
    </Fragment>
  );
};

TabForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired
};

export default TabForm;

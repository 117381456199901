var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiSelect from "@mui/material/Select";
import wrappable from "cms/utils/wrappable";
var Select = wrappable(function (props) {
    return _jsx(MuiSelect, __assign({ IconComponent: KeyboardArrowDownIcon }, props));
});
export default Select;

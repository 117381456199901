import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import AdminWebsiteContext from "cms/components/back-office/contexts/AdminWebsiteContext";
import EnhancedTable from "cms/components/back-office/EnhancedTable";
import AdminPagesService from "cms/services/admin/AdminPagesService";
import Grid from "@mui/material/Grid";

let searchTimeout = null;

const PagesTable = props => {
  const { onPageClick } = props;

  const [pages, setPages] = useState({
    data: [],
    total: 0
  });
  const [sorting, setSorting] = useState({
    order: "DESC",
    orderBy: "updated"
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState();

  const { currentSite } = useContext(AdminWebsiteContext);

  const { order, orderBy } = sorting;

  const columns = [
    {
      id: "name",
      label: "Nom de la page",
      sortable: true,
      render: p => p.name,
      sx: () => ({ fontWeight: "bold" })
    },
    {
      id: "status",
      label: "Statut",
      render: p => (p.isPublished ? "Publiée" : "Non publiée"),
      sx: p => (p.isPublished ? { color: "success.main" } : null)
    },
    {
      id: "updated",
      label: "Dernière modification",
      sortable: true,
      render: p => new Date(p.updated).toLocaleDateString()
    }
  ];

  useEffect(() => {
    const filter = [`siteId||eq||${currentSite.id}`];
    if (search) {
      filter.push(`name||cont||${search}`);
    }
    const options = {
      params: {
        filter,
        sort: [`${orderBy},${order}`],
        per_page: rowsPerPage,
        offset: (page - 1) * rowsPerPage
      }
    };
    AdminPagesService.find(options).then(pagesResult => {
      const { data, total } = pagesResult;
      setPages({ data, total });
    });
  }, [currentSite.id, order, orderBy, page, rowsPerPage, search]);

  const handleChangeSort = (event, key) => {
    if (orderBy !== key) {
      setSorting({
        order: "ASC",
        orderBy: key
      });
    } else {
      setSorting({
        order: order === "ASC" ? "DESC" : "ASC",
        orderBy: key
      });
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSearchChange = event => {
    const { value } = event.target;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      setSearch(value);
    }, 350);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }}>
      <Grid container>
        <Grid item xs={5}>
          <Input
            placeholder="Filtrer les pages"
            disableUnderline
            onChange={handleSearchChange}
            sx={{ width: "100%", mb: 2 }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton size="large">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <div style={{ flex: "1 0 auto" }}>
        <EnhancedTable
          columns={columns}
          data={pages.data}
          order={order}
          orderBy={orderBy}
          page={page}
          totalRows={pages.total}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onRequestSort={handleChangeSort}
          onChangePage={setPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowClick={onPageClick}
        />
      </div>
    </Grid>
  );
};

PagesTable.propTypes = {
  onPageClick: PropTypes.func.isRequired
};

export default PagesTable;

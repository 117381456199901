var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { FormTitle } from "cms/components/back-office/contentForms/ContentFormStyled";
import Icon from "cms/components/front-office/contents/Icon";
import { getCategoryLabel } from "cms/enums/categoriesContents";
import { ThemeContext } from "cms/theme/ThemeBackOfficeProvider";
import { getAllDefaultContents } from "cms/utils/adminContentsUtils";
import React, { useContext } from "react";
var AvailableContentsPreview = function () {
    var theme = useContext(ThemeContext).themeBackOffice;
    var groupedContents = {};
    getAllDefaultContents()
        .filter(function (c) { return c.Preview; })
        .sort(function (a, b) { return (a.label < b.label ? -1 : 1); })
        .forEach(function (content) {
        var category = content.category;
        groupedContents[category] = groupedContents[category] || [];
        groupedContents[category].push(content);
    });
    return Object.keys(groupedContents).map(function (category) { return (_jsxs(React.Fragment, { children: [_jsx("h2", __assign({ style: { textAlign: "center" } }, { children: getCategoryLabel(category) }), void 0), groupedContents[category].map(function (content) {
                var label = content.label, Preview = content.Preview, icon = content.icon, IconComponent = content.IconComponent, type = content.type;
                var contentIcon = null;
                if (icon) {
                    contentIcon = _jsx(Icon, { icon: icon }, void 0);
                }
                else if (IconComponent) {
                    contentIcon = (_jsx(IconComponent, { color: theme === null || theme === void 0 ? void 0 : theme.palette["layout"].contentBtnBackground, backgroundColor: theme === null || theme === void 0 ? void 0 : theme.palette["layout"].contentBtnColor }, void 0));
                }
                return (_jsxs(Box, __assign({ mb: 8 }, { children: [_jsxs(FormTitle, { children: [contentIcon, contentIcon && _jsx("span", { children: "\u00A0\u00A0" }, void 0), label] }, void 0), _jsx(Preview, {}, void 0)] }), type));
            })] }, category)); });
};
export default AvailableContentsPreview;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/system/Box";
import { isNull, isString } from "cms/utils/guards";
import { CustomSelect } from "../CustomSelect";
import { useIconAutocomplete } from "./hook/useIconAutocomplete.hook";
var IconAutocomplete = function (_a) {
    var type = _a.type, _b = _a.width, width = _b === void 0 ? "100%" : _b, onContentChange = _a.onContentChange, content = _a.content;
    var _c = useIconAutocomplete(), data = _c.data, isLoading = _c.isLoading;
    if (isLoading || !data)
        return null;
    return (_jsx(Box, __assign({ sx: { width: width } }, { children: _jsx(CustomSelect, { labelIcon: "name", label: "Nom", withIcon: true, type: type, options: data, onChange: function (e, value) {
                e.preventDefault();
                if (!isNull(value)) {
                    onContentChange(__assign(__assign({}, content), { value: isString(value) ? value : value.name }));
                }
            } }, void 0) }), void 0));
};
export default IconAutocomplete;

import React from "react";
import PropTypes from "prop-types";
import LinkForm from "cms/components/back-office/contentForms/LinkForm";

const SimpleLinkForm = props => {
  const { content } = props;
  const { label } = content;
  return (
    <>
      <h2 style={{ textAlign: "center" }}>{label}</h2>
      <LinkForm {...props} />
    </>
  );
};

SimpleLinkForm.propTypes = {
  content: PropTypes.shape().isRequired
};

export default SimpleLinkForm;

import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, Tooltip, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

const RadioGroupForm = props => {
  const { content, classes, onContentChange, tooltip } = props;

  const { id, label, value, options } = content;

  const handleRadioChange = e => {
    onContentChange({
      ...content,
      value: e.target.value
    });
  };

  return (
    <FormControl className={classes && classes.formControl} key={id}>
      <InputLabel sx={{ pointerEvents: "auto" }} shrink className={tooltip ? classes.labelWithTooltip : null}>
        {label}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpIcon />
          </Tooltip>
        )}
      </InputLabel>
      <RadioGroup row sx={{ mt: 2.5 }} value={value} onChange={handleRadioChange}>
        {options.map(option => (
          <FormControlLabel
            label={option.label}
            value={option.value}
            key={option.value}
            title={option.title}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string
};

RadioGroupForm.defaultProps = {
  tooltip: null
};

export default RadioGroupForm;

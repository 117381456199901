import React, { Fragment } from "react";
import PropTypes from "prop-types";
import contentsTypes from "cms/enums/contentsTypes.enum";
import CardForm from "cms/components/back-office/contentForms/CardForm";
import CheckboxForm from "cms/components/back-office/contentForms/CheckboxForm";
import RadioGroupForm from "cms/components/back-office/contentForms/RadioGroupForm";
import ColorForm from "cms/components/back-office/contentForms/ColorForm";
import FileForm from "cms/components/back-office/contentForms/FileForm";
import IconForm from "cms/components/back-office/contentForms/IconForm";
import ImageForm from "cms/components/back-office/contentForms/ImageForm";
import ImagesForm from "cms/components/back-office/contentForms/ImagesForm";
import SliderForm from "cms/components/back-office/contentForms/SliderForm";
import InputForm from "cms/components/back-office/contentForms/InputForm";
import LinkForm from "cms/components/back-office/contentForms/LinkForm";
import SubpageForm from "cms/components/back-office/contentForms/SubpageForm";
import SelectForm from "cms/components/back-office/contentForms/SelectForm";
import SelectGridForm from "cms/components/back-office/contentForms/GridForm/SelectGridForm";
import TextAreaForm from "cms/components/back-office/contentForms/TextAreaForm";
import CodeBlockForm from "cms/components/back-office/contentForms/CodeBlockForm";
import TabForm from "cms/components/back-office/contentForms/TabForm";
import TabsForm from "cms/components/back-office/contentForms/TabsForm";
import SiteSettingsForm from "cms/components/back-office/contentForms/SiteSettingsForm";
import { FormTitle, FormFieldset } from "cms/components/back-office/contentForms/ContentFormStyled";
import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";

const ContentForm = props => {
  const { content, topLevel, onContentChange, formSubmitted, required, regexValidation } = props;

  const { type, id, key, label, children, editionModal, tooltip } = content;

  if (editionModal && !topLevel) {
    return null;
  }

  let Form = null;
  let formProps = {};

  const getFormComponentFromProject = CmsHooks.getHook(HOOKS.ContentForm_getFormComponent);
  if (typeof getFormComponentFromProject === "function") {
    Form = getFormComponentFromProject({ type });
  }
  if (!Form) {
    switch (type) {
      case contentsTypes.EDITABLE_INPUT:
      case contentsTypes.INPUT:
      case contentsTypes.URL:
      case contentsTypes.NUMERIC:
        Form = InputForm;
        break;
      case contentsTypes.COLOR:
        Form = ColorForm;
        break;
      case contentsTypes.INPUT_NUMBER:
        formProps = { type: "number" };
        Form = InputForm;
        break;
      case contentsTypes.SHORT_TEXT:
        Form = TextAreaForm;
        break;
      case contentsTypes.CHECKBOX:
        Form = CheckboxForm;
        break;
      case contentsTypes.SELECT:
        Form = SelectForm;
        break;
      case contentsTypes.SELECT_GRID:
        Form = SelectGridForm;
        break;
      case contentsTypes.IMAGE:
      case contentsTypes.IMAGE_AS_FILE:
        Form = ImageForm;
        break;
      case contentsTypes.IMAGES:
        Form = ImagesForm;
        break;
      case contentsTypes.SLIDER:
        Form = SliderForm;
        break;
      case contentsTypes.RADIO_GROUP:
        Form = RadioGroupForm;
        break;
      case contentsTypes.FILE_IMAGE:
        formProps = { image: true };
        Form = FileForm;
        break;
      case contentsTypes.FILE:
        Form = FileForm;
        break;
      case contentsTypes.LINK:
        Form = LinkForm;
        break;
      case contentsTypes.CARD:
        Form = CardForm;
        break;
      case contentsTypes.SUBPAGE:
      case contentsTypes.PAGE:
        Form = SubpageForm;
        break;
      case contentsTypes.ICON:
        Form = IconForm;
        break;
      case contentsTypes.TAB_CARDS:
        Form = TabForm;
        break;
      case contentsTypes.TABS:
      case contentsTypes.TABS_CARDS:
        Form = TabsForm;
        break;
      case contentsTypes.SITE_SETTINGS:
        Form = SiteSettingsForm;
        break;
      case contentsTypes.CODE_BLOCK_TEXT:
        Form = CodeBlockForm;
        break;
      default:
        break;
    }
  }

  if (Form) {
    return (
      <Form
        key={id || key}
        content={content}
        onContentChange={onContentChange}
        formSubmitted={formSubmitted}
        required={required}
        regexValidation={regexValidation || content.regexValidation}
        tooltip={tooltip}
        {...formProps}
      />
    );
  }
  if (children) {
    const childrenForm = children
      .map(child => {
        const onChildChange = newChild => {
          onContentChange({
            ...content,
            children: children.map(c => (c === child ? newChild : c))
          });
        };

        return ContentForm({
          content: child,
          onContentChange: onChildChange,
          topLevel: false,
          formSubmitted,
          required: content.requiredByKey && content.requiredByKey.includes(child.key),
          regexValidation: child.regexValidation
        });
      })
      .filter(c => !!c);
    if (childrenForm.length) {
      if (topLevel) {
        return (
          <Fragment key={id}>
            <FormTitle>{label}</FormTitle>
            <div>{childrenForm}</div>
          </Fragment>
        );
      }
      return (
        <FormFieldset key={id}>
          <legend>{label}</legend>
          {childrenForm}
        </FormFieldset>
      );
    }
  }
  return null;
};

ContentForm.propTypes = {
  content: PropTypes.shape().isRequired,
  topLevel: PropTypes.bool,
  editionModal: PropTypes.bool,
  formSubmitted: PropTypes.bool,
  required: PropTypes.bool,
  onContentChange: PropTypes.func,
  regexValidation: PropTypes.string,
  tooltip: PropTypes.string
};

ContentForm.defaultProps = {
  topLevel: false,
  editionModal: false,
  formSubmitted: false,
  required: false,
  onContentChange: null,
  regexValidation: null,
  tooltip: null
};

export default ContentForm;

import CmsHooks, { HOOKS } from "cms/utils/CmsHooks";
import getEditorComponent from "utils/getEditorComponent";
import getDynamicAdminContentsTypes from "utils/getDynamicAdminContentsTypes";
import getFormComponent from "utils/getFormComponent";
import customContents from "contents/customContents";

export default () => {
  CmsHooks.registerHook(HOOKS.templateAdminUtils_getEditorComponent, getEditorComponent);
  CmsHooks.registerHook(HOOKS.templateAdminUtils_getDynamicAdminContentsTypes, getDynamicAdminContentsTypes);
  CmsHooks.registerHook(HOOKS.ContentForm_getFormComponent, getFormComponent);
  CmsHooks.registerHook(HOOKS.adminContentsUtils_contents, customContents);
};

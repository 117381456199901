import { createTheme } from "@mui/material/styles";
import themeTypes from "cms/theme/themeTypes";
var BACK_OFFICE_THEME = "backOfficeTheme";
var darkTheme = {
    primary: {
        light: "#4b93ff",
        main: "#3787ff",
        dark: "#237bff",
        disabled: "#888888",
        textDisabled: "#FFFFFF"
    },
    secondary: {
        light: "#505050",
        main: "#5F5F5F",
        dark: "#363636",
        text: "#F3F3F3",
        disabled: "#333333",
        textDisabled: "#575757"
    },
    unpublished: {
        light: "#FFEBAD",
        main: "#ffe596",
        dark: "#FFDB70",
        text: "#282828",
        textStatus: "#FFEBAD"
    },
    warning: { light: "#ff495e", main: "#FF2F48", dark: "#ff1632" },
    error: { light: "#ff495e", main: "#FF2F48", dark: "#ff1632" },
    success: { light: "#2cdc95", main: "#22cc88", dark: "#1eb679" },
    layout: {
        border: "#505050",
        background: "#282828",
        tooltip: "#505050",
        backgroundSidebar: "#444444",
        textSidebar: "#F3F3F3",
        draft: "#999999",
        icon: "#999999",
        siteSelect: "#424242",
        snackbarIconColor: "#EEEEEE",
        scrollbar: "#F3F3F3",
        template: "#444444",
        templateBorder: "transparent",
        divider: "#5F5F5F",
        secondaryBtnBackground: "rgba(95, 95, 95, 0.2)",
        contentBtnBackground: "#4e4e4e",
        contentBtnBorder: "transparent",
        contentBtnColor: "#CACACA"
    },
    form: {
        text: "#CACACA",
        border: "#5F5F5F",
        inputBackgroundColor: "#5F5F5F",
        tabBackgroundColor: "#555555",
        textFieldButtonBackground: "#666666"
    },
    typography: {
        default: "#EEEEEE",
        head: "#999999"
    }
};
var lightTheme = {
    primary: { light: "#4b93ff", main: "#3787ff", dark: "#237bff", disabled: "#C8C8C8", textDisabled: "#FFFFFF" },
    secondary: {
        light: "#fcfdff",
        main: "#e8f1ff",
        dark: "#d4e5ff",
        text: "#2A4161",
        disabled: "#eff5fd",
        textDisabled: "#8f9dae"
    },
    unpublished: {
        light: "#FFF5D6",
        main: "#ffedb9",
        dark: "#FFE499",
        text: "#282828",
        textStatus: "#f1ca58"
    },
    warning: { light: "#f26780", main: "#f05571", dark: "#ee4362" },
    error: { light: "#f26780", main: "#f05571", dark: "#ee4362" },
    success: { light: "#15b497", main: "#129d84", dark: "#0f8671" },
    layout: {
        border: "#DAE5FB",
        background: "#F7F9FD",
        tooltip: "#627699",
        backgroundSidebar: "#FDFDFF",
        textSidebar: "#2A4161",
        draft: "#627699",
        icon: "#627699",
        siteSelect: "#F3F7FE",
        snackbarIconColor: "white",
        scrollbar: "#627699",
        template: "#F2F7FF",
        templateBorder: "#DAE5FB",
        divider: "#DAE5FB",
        secondaryBtnBackground: "#e8f1ff",
        contentBtnBackground: "#F9FCFF",
        contentBtnBorder: "#DAE5FB",
        contentBtnColor: "#546781"
    },
    form: {
        text: "#627699",
        border: "#DAE5FB",
        inputBackgroundColor: "#F2F7FF",
        tabBackgroundColor: "#F2F7FF",
        textFieldButtonBackground: "#627699"
    },
    typography: {
        default: "#2A4161",
        head: "#546781"
    }
};
var getBackOfficeTheme = function (themeType) {
    var fontFamily = "\"aileron\",sans-serif";
    var isLight = themeType === themeTypes.LIGHT_THEME;
    var palette = isLight ? lightTheme : darkTheme;
    return {
        mode: isLight ? "light" : "dark",
        palette: palette,
        typography: {
            fontSize: 14,
            color: palette.typography.default,
            fontFamily: fontFamily,
            h1: {
                color: palette.typography.default,
                fontSize: "2rem"
            },
            h2: {
                color: palette.typography.default,
                fontSize: "1.75rem"
            },
            h3: {
                color: palette.typography.default,
                fontSize: "1.2rem"
            },
            h4: {
                color: palette.typography.default,
                fontSize: "0.875rem"
            },
            h5: {
                color: palette.typography.default,
                fontSize: "0.76rem"
            },
            subtitle1: {
                fontSize: "1rem"
            },
            subtitle2: {
                fontSize: "0.76rem"
            },
            body1: {
                fontSize: "0.875rem"
            },
            body2: {
                fontSize: "0.76rem"
            },
            caption: {
                fontSize: "0.66rem"
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    // html: { fontSize: "16px" },
                    body: { margin: 0 },
                    "*": { boxSizing: "border-box" }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        width: "100%",
                        color: palette.form.text,
                        marginBottom: "0.875rem",
                        "& .cke_editable": {
                            borderRadius: 7,
                            backgroundColor: palette.form.inputBackgroundColor,
                            padding: "0 8px"
                        },
                        "& label + .cke_editable": {
                            marginTop: 20
                        }
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                            color: palette.primary.disabled
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: palette.typography.default,
                        fontWeight: 600,
                        fontSize: "1rem",
                        "&.Mui-disabled": {
                            color: palette.typography.default
                        }
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        color: palette.typography.default,
                        background: palette.form.inputBackgroundColor,
                        "&.MuiOutlinedInput-root": {
                            marginTop: "20px"
                        }
                    },
                    input: {
                        padding: "10px 8px",
                        "&.MuiOutlinedInput-input": {
                            borderRadius: 7
                        }
                    },
                    inputMultiline: {
                        padding: "10px 8px",
                        resize: "vertical"
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 7,
                        "label + &": {
                            marginTop: "20px"
                        }
                    },
                    underline: {
                        "&.Mui-disabled:before": {
                            border: "none"
                        },
                        "&::before": {
                            border: "none"
                        },
                        "&::after": {
                            border: "none"
                        },
                        "&:hover:not(.Mui-disabled):before": {
                            border: "none"
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        marginTop: "20px",
                        "&.Mui-disabled": {
                            color: palette.primary.disabled
                        }
                    },
                    input: {
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        backgroundColor: palette.form.inputBackgroundColor,
                        "&.Mui-disabled": {
                            WebkitTextFillColor: "currentColor",
                            cursor: "not-allowed"
                        }
                    },
                    notchedOutline: {
                        border: "none !important"
                    }
                }
            },
            MuiInputLabel: {
                defaultProps: {
                    shrink: true
                },
                styleOverrides: {
                    root: {
                        transform: "translate(0, 1.5px) scale(0.75)"
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: palette.typography.default,
                        "&.Mui-disabled": {
                            color: palette.primary.disabled
                        }
                    },
                    colorSecondary: {
                        "&.Mui-checked": {
                            color: palette.typography.default
                        }
                    }
                }
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        color: palette.typography.default
                    },
                    colorSecondary: {
                        "&.Mui-checked": {
                            color: palette.typography.default
                        }
                    }
                }
            },
            MuiSwitch: {
                styleOverrides: {
                    track: {
                        backgroundColor: palette.form.border,
                        opacity: 1
                    },
                    switchBase: {
                        color: palette.form.text,
                        "&.Mui-checked + .MuiSwitch-track": {
                            opacity: 1
                        }
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    variant: "contained",
                    size: "large"
                },
                styleOverrides: {
                    root: {
                        borderRadius: 200,
                        fontWeight: 600,
                        fontSize: "0.76rem",
                        padding: "8px 12px"
                    },
                    sizeLarge: {
                        padding: "16px 24px"
                    },
                    contained: {
                        color: "#FFFFFF",
                        boxShadow: "none",
                        "&:hover": {
                            boxShadow: "none"
                        }
                    },
                    containedPrimary: {
                        "&.Mui-disabled": {
                            backgroundColor: palette.primary.disabled
                        }
                    },
                    outlinedPrimary: {
                        color: palette.secondary.text
                    },
                    containedSecondary: {
                        background: palette.layout.secondaryBtnBackground,
                        color: palette.secondary.text,
                        "&.Mui-disabled": {
                            backgroundColor: palette.secondary.disabled,
                            color: palette.secondary.textDisabled
                        }
                    },
                    textSecondary: {
                        color: palette.secondary.text,
                        "&:hover": {
                            backgroundColor: palette.secondary.main
                        }
                    }
                }
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: palette.layout.icon,
                        "&.Mui-disabled": {
                            color: palette.secondary.textDisabled
                        }
                    }
                }
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        color: palette.secondary.text,
                        borderRadius: 7,
                        paddingTop: "10px",
                        "&:focus": {
                            backgroundColor: palette.secondary.main
                        },
                        "&.Mui-disabled": {
                            color: palette.primary.disabled
                        }
                    },
                    icon: {
                        color: palette.layout.icon
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        minHeight: "40px"
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: palette.typography.default,
                        padding: "8px 20px",
                        textTransform: "none",
                        fontWeight: "bold",
                        fontSize: "0.875rem",
                        minHeight: "40px",
                        borderRight: "1px solid ".concat(palette.form.border),
                        backgroundColor: palette.form.tabBackgroundColor,
                        "&:first-of-type": {
                            borderRadius: "2px 0 0 2px"
                        },
                        "&:last-of-type": {
                            border: 0,
                            borderRadius: "0 2px 2px 0"
                        },
                        "&.Mui-selected": {
                            background: "rgba(55, 135, 255, 0.1)"
                        }
                    }
                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        "& .MuiOutlinedInput-root": {
                            padding: 0
                        }
                    },
                    input: {
                        padding: "10px 8px !important"
                    },
                    noOptions: {
                        color: palette.layout.icon
                    },
                    clearIndicator: {
                        color: palette.layout.icon
                    },
                    popupIndicator: {
                        color: palette.layout.icon
                    },
                    groupLabel: {
                        backgroundColor: palette.layout.backgroundSidebar,
                        color: palette.form.text,
                        paddingTop: "8px",
                        lineHeight: "20px",
                        fontSize: "0.7rem"
                    },
                    groupUl: {
                        padding: "4px"
                    }
                }
            },
            MuiCircularProgress: {
                styleOverrides: {
                    circle: {
                        strokeLinecap: "round"
                    }
                }
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        backgroundColor: palette.layout.backgroundSidebar,
                        borderRadius: 7,
                        color: palette.form.text
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.layout.backgroundSidebar,
                        borderRadius: 7,
                        color: palette.typography.default,
                        "& fieldset": {
                            border: "1px solid ".concat(palette.form.border),
                            borderRadius: 5,
                            fontWeight: "bold",
                            "& legend": {
                                fontSize: "0.875rem"
                            }
                        }
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        "&$selected, &$selected:hover, &$selected:focus": {
                            backgroundColor: palette.secondary.main
                        }
                    },
                    button: {
                        "&:hover": {
                            backgroundColor: palette.secondary.main
                        }
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover": {
                            backgroundColor: palette.secondary.main
                        },
                        "&.MuiTableRow-hover:hover": {
                            backgroundColor: palette.secondary.main
                        }
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: "1px solid ".concat(palette.form.border),
                        color: palette.typography.default
                    },
                    head: {
                        color: palette.typography.head
                    },
                    stickyHeader: {
                        backgroundColor: palette.layout.backgroundSidebar
                        // backgroundColor: palette.layout.borderColor
                    },
                    body: {
                        color: palette.typography.default
                    }
                }
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    root: {
                        color: palette.typography.head,
                        "&:hover, &:focus": {
                            color: palette.typography.head
                        },
                        "&.Mui-active": {
                            color: palette.typography.head
                        },
                        "&.MuiTableSortLabel-active": {
                            color: palette.typography.head
                        }
                    },
                    icon: {
                        color: "".concat(palette.typography.head, " !important")
                    }
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        color: palette.typography.head
                    },
                    select: {
                        color: palette.typography.head
                    },
                    caption: {
                        backgroundColor: palette.layout.backgroundSidebar,
                        color: palette.typography.head
                    }
                }
            },
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.layout.backgroundSidebar,
                        padding: 0,
                        borderRadius: 10
                    },
                    message: {
                        padding: 0,
                        width: "100%"
                    }
                }
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        backgroundColor: palette.form.border,
                        border: "none",
                        height: "1px"
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "0.875rem",
                        backgroundColor: palette.layout.tooltip
                    },
                    arrow: {
                        color: palette.layout.tooltip
                    }
                }
            }
        }
    };
};
export var getCurrentThemeType = function () { return typeof localStorage !== "undefined" && localStorage.getItem(BACK_OFFICE_THEME); };
export var saveBackOfficeTheme = function (themeType) {
    localStorage.setItem(BACK_OFFICE_THEME, themeType);
};
export var createBackOfficeTheme = function (themeType) { return createTheme(getBackOfficeTheme(themeType)); };
export default createBackOfficeTheme(getCurrentThemeType());

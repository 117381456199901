var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import PropTypes from "prop-types";
var BlockTitle = function (props) {
    var id = props.id, text = props.text, variant = props.variant, component = props.component, textAlign = props.textAlign, textTransform = props.textTransform, fontWeight = props.fontWeight, others = __rest(props, ["id", "text", "variant", "component", "textAlign", "textTransform", "fontWeight"]);
    return (_jsx(Box, __assign({}, others, { children: _jsx(Box, __assign({ sx: { display: "flex", justifyContent: textAlign } }, { children: text && (_jsx(Typography, __assign({ variant: variant, component: component, sx: {
                    display: "flex",
                    alignItems: "flex-end",
                    zIndex: 1,
                    fontWeight: fontWeight,
                    "&:after": {
                        content: "''",
                        ml: -0.75,
                        bgcolor: "#FFC617",
                        zIndex: -1,
                        width: 12,
                        height: 12,
                        borderRadius: "50%"
                    }
                } }, { children: text }), void 0)) }), void 0) }), id));
};
BlockTitle.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string
};
BlockTitle.defaultProps = {
    id: "",
    text: ""
};
export default BlockTitle;

import { useQueryFetcher } from "../utils/react-query/hooks/useQueryFetcher";
export default function useEnvironment() {
    var environment = useQueryFetcher({
        queryKeys: ["environment"],
        variables: {
            url: "/environment.json"
        }
    }).data;
    /* const { data: environment = {} } = useQuery<{[key: string]: string | number}>("environment", () =>
      axios.get("/environment.json").then(response => response.data)
    );*/
    return environment;
}

import axios from "axios";

class NewsletterService {
  static inscription = (mail, id_list) => {
    return axios({
      method: "post",
      url: "/signin_newsletter",
      params: { email: mail, list: id_list }
    });
  };

  static sendMail = props => {
    return axios({
      method: "post",
      url: "/sendMail",
      params: props
    });
  };
}

export default NewsletterService;

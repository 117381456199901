import React, { Fragment, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { Box, Button } from "@mui/material";
import Icon from "cms/components/front-office/contents/Icon";
import { getDefaultContentByType, generateContentId } from "cms/utils/adminContentsUtils";
import { swapArrayItems } from "cms/utils/ArrayUtils";
import ContentFormContext from "cms/components/back-office/contentForms/ContentFormContext";

const AdminListForm = props => {
  const { contents, elementsType, elementsKey, onContentsChange, inline, children } = props;
  const { displayVertical } = useContext(ContentFormContext);

  const handleMove = (childId, up = false) => {
    const contentToMove = contents.find(c => c.id === childId);
    const contentIndex = contents.indexOf(contentToMove);
    const targetIndex = up ? contentIndex - 1 : contentIndex + 1;
    onContentsChange(swapArrayItems(contents, contentIndex, targetIndex));
  };

  const handleClickDelete = childId => {
    onContentsChange([...contents.filter(c => c.id !== childId)]);
  };

  const handleClickAdd = () => {
    const defaultContent = getDefaultContentByType(elementsType);
    onContentsChange([...contents, { key: elementsKey, ...generateContentId(defaultContent) }]);
  };

  const displayInline = useMemo(() => inline && !displayVertical, [inline, displayVertical]);

  return (
    <Fragment>
      {React.Children.map(
        children,
        (child, index) =>
          child && (
            <Box
              sx={{
                display: "flex",
                flexDirection: displayInline ? "row" : "column-reverse",
                // justifyContent: displayInline ? "space-between" : "flex-start",
                alignItems: "center",
                mt: index === 0 || displayInline ? 0 : 2
              }}
              key={child.key}
            >
              <div style={{ flex: 1 }}>{child}</div>
              <Box>
                <IconButton
                  onClick={() => handleMove(child.key, true)}
                  edge="end"
                  aria-label="monter"
                  disabled={index === 0}
                  size="large"
                >
                  <Icon icon="chevron-up" title="Monter" />
                </IconButton>
                <IconButton
                  onClick={() => handleMove(child.key)}
                  edge="end"
                  aria-label="descendre"
                  disabled={index === children.length - 1}
                  size="large"
                >
                  <Icon icon="chevron-down" title="Descendre" />
                </IconButton>
                <IconButton onClick={() => handleClickDelete(child.key)} edge="end" aria-label="supprimer" size="large">
                  <Icon icon="trash" title="Supprimer" />
                </IconButton>
              </Box>
            </Box>
          )
      )}
      {elementsType && <Button onClick={handleClickAdd}>Ajouter</Button>}
    </Fragment>
  );
};

AdminListForm.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  elementsType: PropTypes.string,
  elementsKey: PropTypes.string,
  onContentsChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  inline: PropTypes.bool
};

AdminListForm.defaultProps = {
  inline: false,
  elementsType: null,
  elementsKey: null
};

export default AdminListForm;

import React, { useContext, useState, useEffect } from "react";
import Link from "cms/components/front-office/contents/Link";
import Box from "@mui/material/Box";
import Icon from "cms/components/front-office/contents/Icon";
import PageVersionContext from "cms/components/shared/PageVersionContext";
import { useLocation } from "react-router-dom";
import SearchBarHeader from "./SearchBarHeader";
import { useTheme } from "@mui/material";
import { styled } from "@mui/system";

const styleMenuItemHover = {
  top: "120%",
  right: "50%",
  transform: "translateX(50%) rotate(-45deg)",
  width: "8px",
  height: "8px",
  content: "''",
  position: "absolute",
  border: "1px solid",
  borderColor: "primary.main",
  borderRadius: "2px"
};

const styleMenuItemActive = {
  ...styleMenuItemHover,
  bgcolor: "primary.main"
};

const studentItemHover = {
  ...styleMenuItemHover,
  right: "calc(50% + 2px)",
  bgcolor: "#EEF3FF"
};

const studentItemHoverBefore = {
  ...styleMenuItemHover,
  right: "calc(50% - 2px)",
  bgcolor: "transparent"
};

const menuItemsStyled = {
  display: "flex",
  alignItems: "center",
  position: "relative",
  overflow: "inherit",
  overflowY: "inherit",
  flexDirection: "row",
  zIndex: 51,
  bgcolor: "primary.light",
  bottom: 0,
  top: 0,
  right: 0,
  left: 0,
  textAlign: "center",
  px: 0,
  py: 0,
  transition: "none",
  boxShadow: "none",
  listStyleType: "none"
};

const Navigation = props => {
  const theme = useTheme();
  const { menuItems } = props;
  const { pathname } = useLocation();
  const { currentPageVersion } = useContext(PageVersionContext);
  const [showSearchBar, setShowSearchBar] = useState(false);

  const isActive = menuItem => {
    const { link } = menuItem || {};
    const { page } = link || {};
    const { path } = page || {};
    if (currentPageVersion && path) {
      if (path === "/") {
        return currentPageVersion.path === path;
      }
      if (currentPageVersion.fullPath) {
        return currentPageVersion.fullPath.startsWith(path);
      }
    }
    return false;
  };

  const handleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  const handlePressSearchIcon = event => {
    if (event.key === "Enter") {
      setShowSearchBar(!showSearchBar);
    }
  };

  useEffect(() => {
    if (pathname !== "/rechercher") setShowSearchBar(false);
  }, [pathname]);

  const menuItemActiveStyled = ({ menuItem, isActive }) => ({
    "&::after":
      isActive(menuItem) && (theme.professor ? styleMenuItemActive : { ...studentItemHover, bgcolor: "white" }),
    "&::before": isActive(menuItem) && !theme.professor && { ...studentItemHoverBefore, bgcolor: "primary.main" }
  });

  const menuItemStyled = {
    textTransform: "none",
    fontSize: "1rem",
    ml: 6.25,
    fontWeight: "500",
    color: "primary.dark",
    bgcolor: "primary.light",
    position: "relative",
    letterSpacing: "0.05rem",
    textDecoration: "none!important",
    "&:hover::after": !theme.professor ? studentItemHover : styleMenuItemHover,
    "&:hover::before": !theme.professor ? studentItemHoverBefore : null
  };

  return (
    <nav>
      <Box component="ul" sx={menuItemsStyled}>
        {!showSearchBar && (
          <>
            {menuItems.map(menuItem => {
              return (
                <Box
                  component="li"
                  key={"box-menu-item-key-" + menuItem.id}
                  sx={{
                    my: { xs: 3.75, lg: 0 },
                    "& > a": { ...menuItemStyled, ...menuItemActiveStyled({ menuItem, isActive }) }
                  }}
                >
                  <Link
                    {...menuItem.link}
                    variant="subtitle2"
                    sx={{ textDecoration: "none" }}
                    aria-current={isActive(menuItem) ? "page" : undefined}
                  >
                    {menuItem.title}
                  </Link>
                </Box>
              );
            })}
            <Box
              component="li"
              sx={{
                "& > i": {
                  ...menuItemStyled,
                  cursor: "pointer"
                }
              }}
            >
              <Icon
                icon="search"
                type="fal"
                onClick={handleSearchBar}
                tabIndex={0}
                onKeyPress={handlePressSearchIcon}
                role="button"
              />
            </Box>
          </>
        )}
        <SearchBarHeader isOpen={showSearchBar} toggle={handleSearchBar} isMobile={false} />
      </Box>
    </nav>
  );
};

export default Navigation;

import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@mui/material";
// Components. -----------------------------------------------------------------
import Image from "cms/components/front-office/contents/Image";
import PresentationBox from "./PresentationBox";
// =============================================================================
// PresentationBoxPreview...
// =============================================================================
var PresentationBoxPreview = function () {
    // Render. -------------------------------------------------------------------
    return (_jsx(Box, { sx: { width: "500px" }, children: _jsx(PresentationBox, { title: "Titre de la Bo\u00EEte", subTitle1: "Sous titre 1", subTitle2: "Sous titre 2", description: "Zone de texte libre", position: "row", image: _jsx(Image, { file: { url: "/default-image.jpg" }, disableLazy: true }) }) }));
};
export default PresentationBoxPreview;

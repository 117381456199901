import contentsTypes from "cms/enums/contentsTypes.enum";
import customTypes from "enums/contentsCustomTypes.enum";
export var simpleLink = {
    label: "Lien",
    type: customTypes.SIMPLE_LINK,
    icon: "link",
    editionModal: true,
    editionModalPosition: "top",
    children: [
        {
            label: "Url",
            key: "url",
            type: contentsTypes.URL,
            value: ""
        },
        {
            label: "Page",
            key: "page",
            type: contentsTypes.PAGE,
            value: ""
        },
        {
            key: "title",
            label: "Titre du lien",
            type: contentsTypes.INPUT,
            value: ""
        },
        {
            label: "Ouvrir dans un nouvel onglet",
            key: "external",
            type: contentsTypes.CHECKBOX,
            value: "false"
        }
    ]
};
